import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";

const AnalysisGraphs = ({ examData, mainColors = ["#71C481", "#789BFF"] }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [uniqueSubjectsArray, setUniqueSubjectsArray] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    //case for Board Exams
    if (examData.result) {
      setContent(examData.results);
    } else {
      // case for Competitive exam
      let uniqueSubjects = new Set();
      examData?.forEach(exam => {
        if (exam.subjectAnalysis && typeof exam.subjectAnalysis === "object") {
          Object.keys(exam.subjectAnalysis).forEach(subject => {
            if (subject !== "undefined") uniqueSubjects.add(subject);
          });
        }
      });

      const subjectsArray = Array.from(uniqueSubjects);
      setUniqueSubjectsArray(subjectsArray);

      const contentObject = subjectsArray
        .map(subject => {
          let totalQuestion = 0;
          let totalCorrectAnswers = 0;

          examData.forEach(exam => {
            const subjectData = exam?.subjectAnalysis[subject];
            if (subjectData) {
              totalQuestion += Number(subjectData.total);
              totalCorrectAnswers += Number(subjectData.correct);
            }
          });

          return totalQuestion > 0
            ? {
                classAvgMarks: (totalCorrectAnswers / totalQuestion) * 100,
                Marks: (totalCorrectAnswers / totalQuestion) * 100,
                Subject: subject
              }
            : null;
        })
        .filter(Boolean);

      setContent(contentObject);
    }
  }, [examData]);

  const numberSection = [0, 20, 40, 60, 80, 100];

  const VerticalLine = ({ left }) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: `${left}`,
        width: "1px",
        borderLeft: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const HorizontalLine = ({ top }) => (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: `${top}`,
        height: "1px",
        borderTop: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const GraphScaleDisplay = () => (
    <div className="bg-red-500 h-[100%]">
      {numberSection
        .slice()
        .reverse()
        .map((item, index) => (
          <div
            className="text-end"
            key={index}
            style={{
              position: "absolute",
              top: `${100 - item}%`,
              transform: "translateY(-50%)",
              left: -30
            }}
          >
            {item}
          </div>
        ))}
    </div>
  );

  const BackgroundBoxOfGraph = ({ color }) => (
    <div
      style={{
        flex: 1,
        backgroundColor: color,
        width: "50%",
        opacity: 0.3
      }}
    />
  );

  const AvgMarksLine = ({ avgMarks }) => {
    return (
      <div
        style={{
          position: "absolute",
          width: "50%",
          borderTop: "1px dashed #ff0000",
          backgroundColor: "transparent",
          bottom: `${avgMarks - 0.7}%`,
          transform: "translateY(-50%)",
          zIndex: 1000
        }}
      >
        <div className="absolute rounded-xl w-2 h-2 bg-[#ff0000] -top-1 -left-1" />
        <div className="absolute rounded-xl w-2 h-2 bg-[#ff0000] -top-1 -right-1" />
      </div>
    );
  };

  const MainBar = ({ color, StudentMarks }) => {
    const [height, setHeight] = useState(0); // Start with 0 height

    useEffect(() => {
      const timer = setTimeout(() => {
        setHeight(StudentMarks); // Set to actual percentage after a short delay
      }, 1000); // Delay for triggering animation
      return () => clearTimeout(timer);
    }, [StudentMarks]);

    return (
      <div
        className={`absolute flex w-1/2 bottom-0 justify-center font-semibold 
          ${color === "#000000" || color === "#000" ? "text-white" : "text-black"}`}
        style={{
          height: `${height}%`, // This will animate the bar height
          backgroundColor: color,
          borderTopRightRadius: "0.75rem",
          borderTopLeftRadius: "0.5rem",
          transition: "height 0.7s ease-in-out, box-shadow 0.3s ease-in-out", // Added transition for box-shadow
          boxShadow: "inset 0 0 0 0 rgba(0, 0, 0, 0)" // Default no shadow
        }}
        // Adding hover effect for shadow and text scaling
        onMouseEnter={e => {
          e.currentTarget.style.boxShadow = "inset 0 4px 8px rgba(0, 0, 0, 0.2)"; // Add shadow
          const textElement = e.currentTarget.querySelector(".text-to-scale"); // Target the text
          if (textElement) {
            textElement.style.transform = "scale(1.05)"; // Scale text to 105%
          }
        }}
        onMouseLeave={e => {
          e.currentTarget.style.boxShadow = "inset 0 0 0 0 rgba(0, 0, 0, 0)"; // Remove shadow
          const textElement = e.currentTarget.querySelector(".text-to-scale"); // Target the text
          if (textElement) {
            textElement.style.transform = "scale(1)"; // Reset text scale to 100%
          }
        }}
      >
        <Box
          className={`transition-transform duration-300 ease-in-out text-to-scale ${
            StudentMarks <= 10 ? "absolute top-[-1rem]" : "static"
          }`}
          style={{
            transform: "scale(1)", // Default text scale
            transition: "transform 0.3s ease-in-out" // Text scale transition
          }}
        >
          {StudentMarks}%
        </Box>
      </div>
    );
  };

  return (
    <Box p="1rem 2rem" position="relative">
      <Box
        position="relative"
        minHeight="40vh"
        width="100%"
        bgcolor="#fff"
        border="1px dashed #e3e3e3"
        borderBottom="1px solid #000"
      >
        {!examData.result &&
          content.map((item, index) => (
            <VerticalLine key={index} left={`${(index + 1) * (100 / (content.length + 1))}%`} />
          ))}

        <HorizontalLine top="20%" />
        <HorizontalLine top="40%" />
        <HorizontalLine top="60%" />
        <HorizontalLine top="80%" />

        <GraphScaleDisplay />

        <Box display="flex" justifyContent="space-evenly" position="absolute" top={0} left={0} right={0} bottom={0}>
          {/**Competitive Exams */}
          {!examData.result &&
            content.map((item, index) => (
              <Box key={index} alignItems="center" display="flex" flexDirection="column" flex={1} position="relative">
                <BackgroundBoxOfGraph color={mainColors[index % mainColors.length]} />
                {!examData.results && <AvgMarksLine avgMarks={item.classAvgMarks} />}
                <MainBar color={mainColors[index % mainColors.length]} StudentMarks={item.Marks} />
                <Box position="absolute" bottom="-2rem">
                  {item.Subject}
                </Box>
              </Box>
            ))}
          {/**Boards part */}
          {examData.result &&
            examData.result.results.map((item, index) => (
              <Box key={index} alignItems="center" display="flex" flexDirection="column" flex={1} position="relative">
                <BackgroundBoxOfGraph color={mainColors[index % mainColors.length]} />
                <MainBar color={mainColors[index % mainColors.length]} StudentMarks={item.score} />
                <Box position="absolute" bottom="-2rem">
                  {item.subject}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="center" gap="2rem" alignItems="center" mt="5rem">
        <Box display="flex" flexDirection="row" justifyContent="center" gap="8px" alignItems="center">
          {mainColors.map((color, index) => (
            <Box key={index} width="0.5rem" height="0.5rem" bgcolor={color} />
          ))}
          <Box>OverAll Percentage</Box>
        </Box>
        {!examData?.result && (
          <Box display="flex" flexDirection="row" justifyContent="center" gap="8px" alignItems="center">
            <Box height="0.5rem" width="0.5rem" bgcolor="#ff0000" />
            <Box>Average Marks</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AnalysisGraphs;
