import React, { useEffect, useState } from "react";

const AnalysisGraphs = ({ examData, mainColors = ["#71C481", "#789BFF"] }) => {
  const [uniqueSubjectsArray, setUniqueSubjectsArray] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (!examData) return;

    // Case for Board Exams
    if (examData.result) {
      setContent(examData.result.results || []);
      return;
    }

    // Case for Competitive exam
    // Ensure exams is an array and has items
    if (!Array.isArray(examData.exams) || examData.exams.length === 0) {
      setContent([]);
      return;
    }

    let uniqueSubjects = new Set();
    examData.exams.forEach(exam => {
      if (exam?.subjectAnalysis && typeof exam.subjectAnalysis === "object") {
        Object.keys(exam.subjectAnalysis).forEach(subject => {
          if (subject !== "undefined") uniqueSubjects.add(subject);
        });
      }
    });

    const subjectsArray = Array.from(uniqueSubjects);
    setUniqueSubjectsArray(subjectsArray);

    const contentObject = subjectsArray
      .map(subject => {
        let totalQuestion = 0;
        let totalCorrectAnswers = 0;

        examData.exams.forEach(exam => {
          const subjectData = exam?.subjectAnalysis?.[subject];
          if (subjectData) {
            totalQuestion += Number(subjectData.total) || 0;
            totalCorrectAnswers += Number(subjectData.correct) || 0;
          }
        });

        return totalQuestion > 0
          ? {
              classAvgMarks: (totalCorrectAnswers / totalQuestion) * 100,
              Marks: totalCorrectAnswers,
              Subject: subject
            }
          : null;
      })
      .filter(Boolean);

    setContent(contentObject);
  }, [examData]);

  const numberSection = [0, 20, 40, 60, 80, 100];

  const VerticalLine = ({ left }) => (
    <div className="absolute inset-y-0 w-px bg-gray-200 border-l border-dashed" style={{ left: `${left}` }} />
  );

  const HorizontalLine = ({ top }) => (
    <div className="absolute inset-x-0 h-px bg-gray-200 border-t border-dashed" style={{ top: `${top}` }} />
  );

  const GraphScaleDisplay = () => (
    <div className="h-full">
      {numberSection
        .slice()
        .reverse()
        .map((item, index) => (
          <div
            key={index}
            className="absolute -left-8 text-right transform -translate-y-1/2"
            style={{ top: `${100 - item}%` }}
          >
            {item}
          </div>
        ))}
    </div>
  );

  const BackgroundBoxOfGraph = ({ color }) => (
    <div
      className="flex-1 w-1/2"
      style={{
        backgroundColor: color,
        opacity: 0.3
      }}
    />
  );

  const AvgMarksLine = ({ avgMarks }) => (
    <div
      className="absolute w-1/2 border-t border-dashed border-red-500 z-10"
      style={{
        bottom: `${avgMarks - 0.7}%`,
        transform: "translateY(-50%)"
      }}
    >
      <div className="absolute -top-1 -left-1 w-2 h-2 rounded-full bg-red-500" />
      <div className="absolute -top-1 -right-1 w-2 h-2 rounded-full bg-red-500" />
    </div>
  );

  const MainBar = ({ color, StudentMarks }) => {
    const [height, setHeight] = useState(0);

    useEffect(() => {
      const timer = setTimeout(() => {
        setHeight(StudentMarks);
      }, 1000);
      return () => clearTimeout(timer);
    }, [StudentMarks]);

    return (
      <div
        className={`absolute flex w-1/2 justify-center font-semibold transition-all duration-700 ease-in-out
          hover:shadow-inner ${color === "#000000" || color === "#000" ? "text-white" : "text-black"}`}
        style={{
          height: `${height}%`,
          backgroundColor: color,
          bottom: 0,
          borderTopRightRadius: "0.75rem",
          borderTopLeftRadius: "0.5rem"
        }}
      >
        <div className={`transition-transform duration-300 ${StudentMarks <= 10 ? "absolute -top-4" : ""}`}>
          {StudentMarks}%
        </div>
      </div>
    );
  };

  if (!examData || (!examData.exams && !examData.result)) {
    return <div className="h-full w-full flex items-center justify-center">No Exams Found</div>;
  }

  return (
    <div className="p-8 relative">
      <div className="relative min-h-[40vh] w-full bg-white border border-dashed border-gray-200 border-b-black">
        {!examData.result &&
          content.map((_, index) => (
            <VerticalLine key={index} left={`${(index + 1) * (100 / (content.length + 1))}%`} />
          ))}

        {numberSection.slice(1).map((_, index) => (
          <HorizontalLine key={index} top={`${(index + 1) * 20}%`} />
        ))}

        <GraphScaleDisplay />

        <div className="absolute inset-0 flex justify-evenly">
          {!examData.result
            ? content.map((item, index) => (
              <div key={index} className="flex flex-col items-center flex-1 relative">
                <BackgroundBoxOfGraph color={mainColors[index % mainColors.length]} />
                <AvgMarksLine avgMarks={item.classAvgMarks} />
                <MainBar color={mainColors[index % mainColors.length]} StudentMarks={item.Marks} />
                <div className="absolute -bottom-8">{item.Subject}</div>
              </div>
              ))
            : examData.result.results?.map((item, index) => (
              <div key={index} className="flex flex-col items-center flex-1 relative">
                <BackgroundBoxOfGraph color={mainColors[index % mainColors.length]} />
                <MainBar color={mainColors[index % mainColors.length]} StudentMarks={item.score} />
                <div className="absolute -bottom-8">{item.subject}</div>
              </div>
              ))}
        </div>
      </div>

      <div className="flex justify-center items-center gap-8 mt-20">
        <div className="flex items-center gap-2">
          {mainColors.map((color, index) => (
            <div key={index} className="w-2 h-2" style={{ backgroundColor: color }} />
          ))}
          <div>Overall Percentage</div>
        </div>
        {!examData?.result && (
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-red-500" />
            <div>Average Marks</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalysisGraphs;
