// inbuilt modules
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// external modules
import { Box, Divider, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery } from "@mui/material";
import { useGetEntireSyllabusQuery } from "../../../redux/api/syllabusSlice";
import DifficultyAnalysis from "../Difficulty/diff";
import IndividualSubjectAnalysis from "./Subjects/IndividualSubjectAnalysis";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "width": "100%",
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontWeight: 600
  }
}));

const SubjectAnalysis = ({ analysis, syllabusAnalysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [alignment, setAlignment] = useState("");

  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);
  useEffect(() => {
    if (analysis && analysis.subjectAnalysis) {
      if (Object.keys(analysis.subjectAnalysis).length) {
        setAlignment(Object.keys(analysis.subjectAnalysis)[0]);
      }
    }
  }, [syllabusAnalysis]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap="2rem">
      <DifficultyAnalysis analysis={analysis} syllabusAnalysis={syllabusAnalysis} />
      <Divider style={{ backgroundColor: "rgba(0,0,0,0.2)", width: "100%", height: "6px", borderRadius: "1rem" }} />
      <Box display="flex" width="100%" justifyContent="center" flexDirection="column" gap="2rem">
        <Box display="flex" width="100%" gap="1rem" flexDirection="column">
          <Typography variant="h4" fontWeight={500}>
            Individual Subject Analysis
          </Typography>
          <Divider style={{ backgroundColor: "grey", width: "10%" }} />
          <Typography variant="h5" gutterBottom style={{ color: "grey" }}>
            Select subject for detailed subject analysis
          </Typography>
        </Box>
        <Box display="flex" width="100%" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
          <ToggleButtonGroup
            sx={{
              bgcolor: "#fff",
              width: isNonMobile ? "80%" : "100%"
            }}
            value={alignment}
            exclusive
            onChange={handleToggle}
          >
            {analysis && analysis.subjectAnalysis ? (
              Object.keys(analysis.subjectAnalysis).length ? (
                Object.keys(analysis.subjectAnalysis).map(subject => (
                  <StyledToggleButton key={subject} value={subject}>
                    {subject}
                  </StyledToggleButton>
                ))
              ) : (
                <StyledToggleButton key={"LOADING"} value={""}>
                  Loading...
                </StyledToggleButton>
              )
            ) : (
              <>No sections present</>
            )}
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box width="100%">
        <IndividualSubjectAnalysis analysis={analysis} alignment={alignment} />
      </Box>
    </Box>
  );
};

export default SubjectAnalysis;
