import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Button as MUIButton,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTestGeneratorMutation } from "../../../redux/api/examSlice";
import { useInitiateSamlLoginMutation } from "../../../redux/api/samlSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

import clipboardIcon from "../../../Assets/clipboard.svg";
import keyIcon from "../../../Assets/key.svg";

const TestGenerator = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const userId = useSelector(state => state.auth.user._id);
  const apiKey = useSelector(state => state.auth.user.api_key);
  const schoolId = useSelector(state => state.auth.user.school_id);
  const [initiateSamlLogin] = useInitiateSamlLoginMutation();
  const [testGenerator] = useTestGeneratorMutation();
  const [testpaperId, setTestpaperId] = useState("");
  const [loading, setLoading] = useState(false);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test Generator", link: "" }
  ];

  const handleSamlLogin = async () => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    try {
      const response = await initiateSamlLogin(userId).unwrap();
      const newWindow = window.open();
      newWindow.document.open();
      newWindow.document.write(response);
      newWindow.document.close();
    } catch (error) {
      console.error("Error initiating SAML login:", error);
    }
  };

  const handleFetchTestPaperSolution = async () => {
    if (!testpaperId) {
      console.error("Test Paper ID is required.");
      return;
    }
    if (!apiKey) {
      console.error("API key is required.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(`https://app.examin8.com/v1/test-generator/testpaper/${testpaperId}/solution/`, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${apiKey}`
        }
      });

      const testData = {
        school_id: schoolId,
        testpaper_id: response.data.testpaper_id,
        testpaper_name: response.data.testpaper_name,
        module: response.data.module,
        course: response.data.course,
        sections: response.data.sections
      };

      const generatorResponse = await testGenerator(testData);
      if (generatorResponse?.redirect) {
        window.location.href = `${window.location.origin}${generatorResponse.redirect}`;
      } else {
        console.log("Test generation complete:", generatorResponse);
      }
    } catch (error) {
      console.error("Error fetching test paper solution:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      padding="2rem"
      display="flex"
      flexDirection="column"
      gap="1.5rem"
      bgcolor={theme.palette.background.default}
      width="100%"
      height="100%"
    >
      <Box marginBottom="1rem">
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </Box>

      <div className="container mx-auto px-4 py-8">
        <Typography variant="h3" gutterBottom fontWeight="medium" color={theme.palette.primary.normal}>
          Test Generator
        </Typography>
        <Typography variant="body1" color={theme.palette.primary.light} gutterBottom>
          Generate test papers or retrieve existing test solutions.
        </Typography>

        <div className="flex flex-col md:flex-row items-center justify-center gap-8 relative mt-8 w-full">
          <Card className="w-full max-w-xl h-[230px] rounded-xl ">
            <CardContent className="flex flex-col items-center justify-between h-full p-6 border-2 border-solid border-[#EAECF0]">
              <div className="flex flex-col items-center">
                <img src={keyIcon} alt="Key Icon" className="w-12 h-12 text-blue-500 mb-4" />
                <Typography variant="h4" gutterBottom fontWeight="medium">
                  Generate Test
                </Typography>
                <Typography variant="body2" align="center" gutterBottom>
                  Initiate the SAML login process to authenticate and automatically generate a new test.
                </Typography>
              </div>
              <MUIButton
                onClick={handleSamlLogin}
                className="w-full max-w-[40%] rounded border-2 border-[#EAECF0] border-solid h-[52.5px] px-4"
              >
                Generate Test
              </MUIButton>
            </CardContent>
          </Card>

          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10  rounded-full p-2">
            <div className="flex items-center justify-center w-12 h-12 bg-white rounded-full border-solid border-[#E6E6E6] border-4">
              <span className="text-[#969696] font-semibold text-sm">Or</span>
            </div>
          </div>

          <Card className="w-full max-w-xl h-[230px] rounded-xl">
            <CardContent className="w-full max-w-xl h-[230px] rounded-xl border-2 border-solid border-[#EAECF0]">
              <div className="flex flex-col items-center">
                <img src={clipboardIcon} alt="Clipboard Icon" className="w-12 h-12 text-blue-500 mb-4" />
                <Typography variant="h4" gutterBottom fontWeight="medium">
                  Fetch Test Solution
                </Typography>
                <Typography variant="body2" align="center" gutterBottom>
                  Enter the test paper ID to retrieve the paper by initiating the process below.
                </Typography>
              </div>
              <div className="w-full flex flex-row gap-2 justify-center items-center mt-4">
                <TextField
                  type="text"
                  placeholder="Enter Test Paper ID"
                  value={testpaperId}
                  onChange={e => setTestpaperId(e.target.value)}
                  className="max-w-[40%]"
                />
                <MUIButton
                  onClick={handleFetchTestPaperSolution}
                  disabled={loading}
                  className="max-w-[40%] rounded border-2 border-[#EAECF0] border-solid h-[52.5px] px-4"
                >
                  {loading ? <CircularProgress size={24} /> : "Fetch Test Paper"}
                </MUIButton>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Box>
  );
};

export default TestGenerator;
