import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { CaretDown, CheckCircle, CircleNotch, Warning, WarningCircle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllAnalysisQuery } from "../../../redux/api/analysisSlice";
import { useGetAllExamsQuery } from "../../../redux/api/examSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const TestHistory = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { data: examsData, isSuccess: isGetExamsSuccess } = useGetAllExamsQuery(user.school_id);
  const { data: examsAnalysis, isSuccess: isGetExamAnalysisSuccess } = useGetAllAnalysisQuery(user.school_id);

  let [testDetails, setTestDetails] = useState([]);
  useEffect(() => {
    setTestDetails(
      isGetExamsSuccess
        ? examsData?.exams
            ?.map((exam, index) => {
              const analysisFound = examsAnalysis?.analysisArray?.find(examA => examA.exam_id === exam._id);
              return {
                serial: index + 1,
                examName: exam.exam_name,
                pattern: exam.type_of_exam,
                examCode: exam.unique_code,
                status: exam.status,
                examFor: exam.exam_for,
                totalStudents: analysisFound ? analysisFound.total_students : "NAN",
                highestAndAverageScore: analysisFound
                  ? `${analysisFound.highest_score}/${analysisFound.average_score.toFixed(2)}`
                  : "NAN"
              };
            })
            .sort((a, b) => a?.createdAt < b?.createdAt)
        : []
    );
  }, [examsData]);

  const tableStyle = {
    width: "100%",
    minWidth: "900px", // Set a minimum width for the table
    textAlign: "center",
    borderCollapse: "separate",
    borderSpacing: "0 1rem"
  };

  const thStyle = {
    padding: "0.5rem",
    whiteSpace: "nowrap" // Prevent text wrapping in header
  };

  const tdStyle = {
    height: "4rem",
    whiteSpace: "nowrap" // Prevent text wrapping in cells
  };

  const StatusIndicator = ({ color, text }) => (
    <Box display="flex" gap="5px" alignItems="center">
      <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: color }} />
      <Typography variant="body2">{text}</Typography>
    </Box>
  );

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "" }
  ];

  return (
    <Box width="100%" p="2rem" bgcolor="rgb(250,250,251,1)" height="100%">
      <div style={{ padding: "1rem 0", marginBottom: "1rem", marginTop: "-2rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box width="100%" display="flex" flexDirection="column" gap="2rem">
        <Box>
          <Box
            width="100%"
            display="flex"
            flexDirection={isNonMobile ? "row" : "column"}
            justifyContent="space-between"
            alignItems={isNonMobile ? "center" : "start"}
            gap="1rem"
          >
            <Box width="100%">
              <Typography variant="h2" fontWeight={600}>
                Test History
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.light}>
                View all the tests conducted
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
              <Grid item xs={6} sm={3}>
                <StatusIndicator color="#3A974C" text="Completed" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <StatusIndicator color="#F48C06" text="Incomplete" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <StatusIndicator color="#3267FF" text="Analysing" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Box display="flex" gap="5px" alignItems="center">
                  <span style={{ fontWeight: 500 }}>NAN</span>: Not analysed
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: isNonMobile ? "80vh" : "75vh",
            overflowY: "auto",
            overflowX: "auto",
            width: "100%"
          }}
        >
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thStyle, width: "60px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Sl. No
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "150px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Exam Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "120px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Pattern
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "100px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Exam For
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "100px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Students Appeared
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "150px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Highest/Average Score
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th style={{ ...thStyle, width: "220px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Status
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {testDetails?.length ? (
                testDetails
                  .slice()
                  .reverse()
                  .map(test => (
                    <tr
                      key={test.serial}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        cursor: "pointer",
                        transition: "box-shadow 0.3s ease"
                      }}
                      onClick={() => navigate(`/edit-test/${test.examCode}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td
                        style={{
                          ...tdStyle,
                          width: "60px",
                          borderLeft: `5px solid ${
                            test.status === "COMPLETED"
                              ? "#3A974C"
                              : test.status === "DRAFT"
                                ? "#F48C06"
                                : test.status === "ANALYSING"
                                  ? "#3267FF"
                                  : test.status === "ERROR"
                                    ? "red"
                                    : "transparent"
                          }`,
                          borderRadius: "0.5rem"
                        }}
                      >
                        {testDetails.length + 1 - test.serial}
                      </td>
                      <td style={{ ...tdStyle, width: "150px" }}>{test.examName}</td>
                      <td style={{ ...tdStyle, width: "120px" }}>{test.pattern}</td>
                      <td style={{ ...tdStyle, width: "100px" }}>{test.examFor}</td>
                      <td style={{ ...tdStyle, width: "100px" }}>{test.totalStudents}</td>
                      <td style={{ ...tdStyle, width: "150px" }}>{test.highestAndAverageScore}</td>
                      <td style={{ ...tdStyle, width: "220px", textAlign: "center" }}>
                        {test.status === "COMPLETED" ? (
                          <Box display="flex" gap="0.5rem" alignItems="center" justifyContent="center">
                            <CheckCircle size={24} weight="fill" color="#3A974C" />
                            Analysis Done
                          </Box>
                        ) : test.status === "DRAFT" ? (
                          <Box display="flex" gap="0.5rem" alignItems="center" justifyContent="center">
                            <WarningCircle size={24} weight="fill" color="#F48C06" />
                            Incomplete
                          </Box>
                        ) : test.status === "ANALYSING" ? (
                          <Box display="flex" gap="0.5rem" alignItems="center" justifyContent="center">
                            <CircleNotch size={24} color="#3267FF" style={{ animation: "spin 2s linear infinite" }} />
                            Analysing
                          </Box>
                        ) : test.status === "ERROR" ? (
                          <Box display="flex" gap="0.5rem" alignItems="center" justifyContent="center">
                            <Warning size={24} weight="fill" color="red" />
                            Error
                          </Box>
                        ) : (
                          <>Loading...</>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No tests found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default TestHistory;
