import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import DemoVideos from "./DemoVideos";

const DemoVideosPage = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0">
      <Header home={false} />
      {/* <Solutions /> */}
      <DemoVideos />
      <Footer />
    </div>
  );
};

export default DemoVideosPage;
