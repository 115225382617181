import React from "react";
import DownloadAppNow from "./DownloadAppNow";
import Footer from "./Footer";
import Header from "./Header";
import Solutions from "./Solutions";

const SolutionsPage = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0">
      <Header home={false} />
      <Solutions />
      <section className="bg-white">
        <DownloadAppNow />
      </section>
      <Footer />
    </div>
  );
};

export default SolutionsPage;
