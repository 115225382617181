import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, styled, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import ChapterAnalysisGraph from "./Graphs/ChapterAnalysisGraph";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.2rem",
    color: "#fff"
  }
}));

const ChapterAnalysis = ({ chapterAnalysisData, sectionAnalysisData, syllabusData, subjectAlignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [sectionAlignment, setSectionAlignment] = useState();
  const [sectionsToDisplay, setSectionsToDisplay] = useState([]);
  const [visibleSections, setVisibleSections] = useState([0, 1]); // Keep track of the visible section range

  const handleScrollLeft = () => {
    if (visibleSections[0] > 0) {
      const newVisibleSections = [visibleSections[0] - 1, visibleSections[1] - 1];
      setVisibleSections(newVisibleSections);

      // Use the sectionName array to set the new subjectAlignment
      setSectionAlignment(sectionsToDisplay[newVisibleSections[0]]);
    }
  };

  const handleScrollRight = () => {
    if (visibleSections[1] < sectionsToDisplay.length - 1) {
      const newVisibleSections = [visibleSections[0] + 1, visibleSections[1] + 1];
      setVisibleSections(newVisibleSections);

      // Use the sectionName array to set the new subjectAlignment
      setSectionAlignment(sectionsToDisplay[newVisibleSections[0]]);
    }
  };

  useEffect(() => {
    if (syllabusData && subjectAlignment) {
      const sections = Object.keys(syllabusData[subjectAlignment] || {});
      const filteredSections = sections.filter(section => section in sectionAnalysisData);
      setSectionsToDisplay(filteredSections);

      // Set initial section alignment if there are sections to display
      if (filteredSections.length > 0) {
        setSectionAlignment(filteredSections[0]);
      }
    }
  }, [syllabusData, subjectAlignment, sectionAnalysisData]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSectionAlignment(newAlignment);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#FAFAFB"
      border="1px solid #E6EDFF"
      width="100%"
      height="100%"
      p="1rem 1rem"
      borderRadius="0.5rem"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          gap="0.75rem"
          width="100%"
          justifyContent="center" // Ensure the wrapper is centered
        >
          {sectionsToDisplay.length > 2 && (
            <IconButton onClick={handleScrollLeft} disabled={visibleSections[0] === 0}>
              <ArrowBackIos />
            </IconButton>
          )}
          <Box
            display="flex"
            overflow="hidden"
            sx={{
              width: isNonMobile ? "50%" : "80%",
              maxWidth: "600px",
              justifyContent: "center" // Center the ToggleButtonGroup horizontally
            }}
          >
            <ToggleButtonGroup
              sx={{
                bgcolor: "#E9E5E5",
                width: "100%",
                height: "10%",
                borderRadius: "0.5rem",
                display: "flex",
                justifyContent: "center" // Center the buttons within the group
              }}
              value={sectionAlignment}
              exclusive
              onChange={handleToggle}
            >
              {sectionsToDisplay.length ? (
                sectionsToDisplay.slice(visibleSections[0], visibleSections[1] + 1).map((section, sectionIndex) => (
                  <StyledToggleButton value={section} key={sectionIndex} sx={{ width: "100%" }}>
                    <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
                      {section}
                    </Typography>
                  </StyledToggleButton>
                ))
              ) : (
                <Typography justifySelf="center" width="100%" bgcolor="#fff" textAlign="center">
                  No Sections To Display
                </Typography>
              )}
            </ToggleButtonGroup>
          </Box>
          {sectionsToDisplay.length > 2 && (
            <IconButton onClick={handleScrollRight} disabled={visibleSections[1] === sectionsToDisplay.length - 1}>
              <ArrowForwardIos />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box width="100%" textAlign="left" display="flex" flexDirection="column" gap="1.5rem">
        <Typography variant="h3" fontWeight="medium" fontSize="24px">
          Chapter Analysis
        </Typography>
        <ChapterAnalysisGraph
          chapterAnalysisData={chapterAnalysisData}
          sectionAlignment={sectionAlignment}
          syllabusData={syllabusData}
          subjectAlignment={subjectAlignment}
        />
      </Box>
    </Box>
  );
};

export default ChapterAnalysis;
