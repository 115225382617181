import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import { statesDistricts } from "../../../utils/IndiaStatesDistricts";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontWeight: "400"
}));

const PersonalDetails = ({
  user,
  personalDetails,
  setPersonalDetails,
  isEdit,
  setIsEdit,
  handleSubmitPersonalDetails
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  const handleChange = event => {
    const { name, value } = event.target;
    setPersonalDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
    >
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Student Details
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Father's Number *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Father's Number"
              value={personalDetails.fatherNumber}
              onChange={handleChange}
              name="fatherNumber"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Mother's Number *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Mother's Number"
              value={personalDetails.motherNumber}
              onChange={handleChange}
              name="motherNumber"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Emergency Contact Name *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Father's Name"
              value={personalDetails.emergencyName}
              onChange={handleChange}
              name="emergencyName"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
            <Typography variant="subtitle1">Emergency Contact Number</Typography>
            <FormControl>
              <StyledTextField
                placeholder="Emergency Contact Number"
                value={personalDetails.emergencyNumber}
                onChange={handleChange}
                name="emergencyNumber"
                type="text"
                InputProps={{
                  inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Emergency Contact Details</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Address Line 1"
              value={personalDetails.emergencyAddressLine1}
              onChange={handleChange}
              name="emergencyAddressLine1"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
            <Typography variant="subtitle1">Pincode</Typography>
            <FormControl>
              <StyledTextField
                placeholder="Pincode"
                value={personalDetails.emergencyPincode}
                onChange={handleChange}
                name="emergencyPincode"
                type="text"
                InputProps={{
                  inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Address Line 2</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Address Line 2"
              value={personalDetails.emergencyAddressLine2}
              onChange={handleChange}
              name="emergencyAddressLine2"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
            <Typography variant="subtitle1">State</Typography>
            <FormControl>
              <Select
                labelId="emergencyState"
                id="emergencyState"
                value={personalDetails.emergencyState}
                onChange={handleChange}
                name="emergencyState"
                sx={{
                  color: "rgba(0,0,0,0.6)",
                  fontSize: "15px",
                  height: "40px"
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {statesDistricts.states.map((stateObj, index) => {
                  return (
                    <MenuItem value={stateObj.state} key={index}>
                      {stateObj.state}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="1.5rem">
        {isEdit ? (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "lightgreen",
              padding: "5px 10px"
            }}
            onClick={handleSubmitPersonalDetails}
          >
            Save Details
          </Button>
        ) : (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "#3267FF",
              color: "#fff",
              padding: "5px 10px"
            }}
            onClick={() => setIsEdit(true)}
          >
            Edit Details
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PersonalDetails;
