import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React from "react";
import { Link } from "react-router-dom";
import { pricingIcon1 } from "../../../Assets/landing";
import DownloadAppNow from "../DownloadAppNow";

const Pricing = () => {
  return (
    <div className="mt-8 lg:mt-12 flex flex-col gap-8 lg:gap-20">
      <div className="container px-4 lg:px-20 flex flex-col justify-center items-center gap-8 lg:gap-12 w-full">
        <div className="flex flex-col justify-center items-center w-full gap-1">
          <h1 className="m-0 text-lg lg:text-xl font-semibold text-[#4A3AFF] uppercase">Pricing</h1>
          <h2 className="m-0 text-lg lg:text-xl font-semibold text-[#170F49]">Pricing plans for all use cases</h2>
          <h3 className="m-0 text-sm font-normal text-[#6F6C90] lg:w-1/2 text-center">
            Enhance your brand presence across online and offline platforms with a robust set of features and dedicated
            priority support.
          </h3>
        </div>

        <section className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-16 w-full border-4 border-[#EFF0F6] rounded-lg shadow-xl bg-white">
          <div className="flex flex-col gap-2 lg:gap-4 bg-white p-4 lg:px-8 lg:py-6 rounded-lg">
            <div className="flex justify-start items-start gap-2">
              <div className="w-10">
                <img src={pricingIcon1} alt="" className="object-contain w-full" />
              </div>
              <div>
                <h2 className="m-0 font-normal text-sm text-[#6F6C90]">For Institutes/College/Coaching</h2>
                <h2 className="m-0 font-semibold text-[#170F49]">Premium Plan</h2>
              </div>
            </div>

            <div>
              <h3 className="m-0 font-normal">
                All three roles supervisor, faculty, and students will be available.{" "}
                <Link to="" className="text-[#1264CE] no-underline">
                  Billed Annually
                </Link>
              </h3>
            </div>
            <div className="flex">
              <div>
                <CurrencyRupeeIcon className="text-2xl lg:text-4xl" />
              </div>
              <b className="m-0 text-[#170F49] text-2xl lg:text-4xl font-bold">
                999 <span className="text-[#6F6C90] text-sm font-normal">/User</span>
              </b>
            </div>

            <div>
              <button
                type="button"
                className="px-6 py-3 outline-none border-none rounded-lg w-full bg-[#1264CE] text-white"
              >
                Get started
              </button>
            </div>
          </div>

          <div className="col-span-1 lg:col-span-2 space-y-4 p-4 lg:px-8 lg:py-6 bg-[#F7F7FC]">
            <h1 className="m-0 text-[#170F49] text-lg lg:text-xl font-semibold">What’s included</h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  One stop solution from admission to graduation.
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Insights Advance Analytical Analysis
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Offline & Online Exam Analysis
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Effortless OMR scanning
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Publication of Board’s results
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Easy 4 step exam creation
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  More than 200k questions available
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Accessible on any device.
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Business Intelligence for rapid growth expansion.
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Insights all-new AI for all roles
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Online & Offline Test Series Available
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  24/7 support available
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Exam Reports in Seconds
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Deliver online classes with recordings.
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Parents tracking students performance
                </h4>
              </div>
              <div className="flex justify-start items-center gap-2">
                <CheckCircleIcon className="text-[#1264CE] h-[1.5rem] w-[1.5rem]" />
                <h4 className="m-0 text-[#170F49] lg:text-sm font-normal leading-tight max-w-xs">
                  Batch Announcements for vital updates.
                </h4>
              </div>
            </div>
          </div>
        </section>

        <div className="flex justify-center items-center w-full">
          <h4 className="m-0 font-normal text-center">
            To know exact features of each role Visit{" "}
            <Link to="/solutions" className="text-[#1264CE] no-underline">
              Solution Page
            </Link>
          </h4>
        </div>

        <section className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-16 w-full lg:w-3/4 border-4 border-[#E8E8E8] bg-white p-4 lg:px-8 lg:py-6 rounded-lg shadow">
          <div className="flex flex-col gap-2 lg:gap-4">
            <div className="flex justify-start items-start gap-2">
              <div className="w-10">
                <img src={pricingIcon1} alt="" className="object-contain w-full" />
              </div>
              <div>
                <h4 className="m-0 font-normal text-sm text-[#6F6C90]">For Enterprise</h4>
                <h4 className="m-0 font-semibold text-[#170F49]">Customized Plan </h4>
              </div>
            </div>

            <div>
              <h4 className="m-0 font-normal">For those requiring an enterprise-grade solution, please contact us.</h4>
            </div>

            <div>
              <button
                type="button"
                className="px-6 py-3 outline-none border-none rounded-lg w-full  bg-[#1264CE] text-white"
              >
                Contact Us
              </button>
            </div>
          </div>

          <div className="col-span-1 lg:col-span-2 space-y-4">
            <p className="m-0 text-[#6F6C90]">
              Looking for white labeling, additional features, or seamless integration with your existing platforms? We
              offer flexible options tailored to your needs. If you have specific requirements or customizations in
              mind, let us know—we're here to create the perfect solution for your organization.
            </p>
          </div>
        </section>

        <section>
          <DownloadAppNow />
        </section>
      </div>
    </div>
  );
};

export default Pricing;
