import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowSquareOut, CaretDown } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCreateMeetingMutation, useGetMeetingsQuery } from "../../../redux/api/meetManagerSlice";

const formatMeetingTime = meetingTime => {
  const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
  return new Date(meetingTime).toLocaleString(undefined, options);
};

const Meetings = ({ sectionId }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const { batchId } = useParams();

  const { data: facultyMeetings, isSuccess } = useGetMeetingsQuery(batchId);

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" gap="0.75rem" flexDirection={isNonMobile ? "row" : "column"} justifyContent="space-between">
        <Box>
          <Typography variant="h3" color="#fffff">
            View meetings here
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: "85vh", // Set a maximum height for the scrollable container
          overflowY: "auto", // Enable vertical scrolling
          position: "relative",
          overflowX: "auto", // Enable horizontal scrolling if needed
          maxWidth: "100%"
        }}
      >
        <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
          <thead>
            <tr>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Name
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Time
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Meeting Link
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
              <th
                style={{
                  width: "10%",
                  padding: isNonMobile ? "0.5rem" : "1rem",
                  gap: "8px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    Go
                  </Typography>
                  <CaretDown weight="fill" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isSuccess ? (
              facultyMeetings?.meetings
                ?.filter(meet => meet.section_id === sectionId)
                ?.map(meet => (
                  <tr
                    key={meet.meeting_id}
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "1rem",
                      borderSpacing: "1rem",
                      cursor: "pointer", // Ensure the cursor is always a pointer
                      transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                    }}
                    onClick={() => window.open(meet.meetingUri)}
                    onMouseEnter={e => {
                      e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                    }}
                  >
                    <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                      {meet.meeting_name}
                    </td>
                    <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                      {formatMeetingTime(meet.meeting_time)}
                    </td>
                    <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                      {meet.meetingUri}
                    </td>
                    <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                      <ArrowSquareOut
                        aria-label="redirect"
                        weight="fill"
                        size={20}
                        color="blue"
                        onClick={() => window.open(meet.meetingUri, "_blank")}
                      />
                    </td>
                  </tr>
                ))
            ) : (
              <>No meetings found</>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Meetings;
