import React from "react";
import { Box, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

const SuperInsightsForSubjectAnalysisBoard = ({ result, previousResult }) => {
  // Helper function to calculate percentage difference
  const calculatePercentageDifference = (current, previous) => {
    if (previous === 0) return 100; // Avoid division by zero
    return ((current - previous) / previous) * 100;
  };

  // Handle no tests conducted case
  if (!result || result.length === 0) {
    return (
      <Box display={"flex"} bgcolor="#ffffff" border={"1px solid #E6EDFF"} borderRadius={"0.5rem"} p="0.5rem 0.5rem">
        <Box color="#3267ff" display={"flex"} alignItems={"top"}>
          {/* Your SVG Icon */}
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box display="flex" flexDirection="column" marginLeft="12px">
          <Typography variant="h6" className="m-0 font-semibold">
            Super Insights
          </Typography>
          <Typography className="text-[#4F4E69] m-0">
            Hey! No specific insights right now, but remember: the topper always sticks to the basics.
          </Typography>
        </Box>
      </Box>
    );
  }

  // Filter for common subjects in result and previousResult
  const commonSubjects = result.filter(current => previousResult?.some(prev => prev.subject === current.subject));

  // Handle only one test conducted case or if no common subjects
  if (previousResult?.length === 0 || commonSubjects?.length === 0) {
    return (
      <Box display={"flex"} bgcolor="#ffffff" border={"1px solid #E6EDFF"} borderRadius={"0.5rem"} p="0.5rem 0.5rem">
        <Box color="#3267ff" display={"flex"} alignItems={"top"}>
          {/* Your SVG Icon */}
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box display="flex" flexDirection="column" marginLeft="12px">
          <Typography variant="h6" className="m-0 font-semibold">
            Super Insights
          </Typography>
          <Typography className="text-[#4F4E69] m-0">
            Hey! Your score is 'Z%' more/less compared to the class average.
          </Typography>
        </Box>
      </Box>
    );
  }

  // Carousel items
  const carouselItems = commonSubjects.map(subjectResult => {
    const previousSubjectResult = previousResult.find(prev => prev.subject === subjectResult.subject);

    const scoreDifference = calculatePercentageDifference(
      subjectResult.score,
      previousSubjectResult ? previousSubjectResult.score : 0
    );

    return (
      <Box display={"flex"} bgcolor="#ffffff" border={"1px solid #E6EDFF"} borderRadius={"0.5rem"} p="0.5rem 0.5rem">
        <Box color="#3267ff" display={"flex"} alignItems={"top"}>
          {/* Your SVG Icon */}
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box display="flex" color="#3267FF" fontWeight="medium" flexDirection="column" marginLeft="12px">
          <Typography variant="h6" fontWeight="medium" className="m-0">
            Super Insights
          </Typography>
          <Typography variant="body1" className="text-[#4F4E69] m-0">
            Hey! Your {subjectResult.subject} score has {scoreDifference >= 0 ? "gained" : "dipped"} by{" "}
            {Math.abs(scoreDifference).toFixed(2)}% from past exams.
          </Typography>
        </Box>
      </Box>
    );
  });

  return (
    <Carousel
      navButtonsAlwaysInvisible={true} // Hide arrow buttons
      indicatorIconButtonProps={{
        style: {
          color: "#3267FF" // Blue color for inactive dots
        }
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: "#3267FF" // Blue color for active dot
        }
      }}
      indicatorContainerProps={{
        style: {
          marginTop: "10px", // Adjust if needed
          textAlign: "center"
        }
      }}
    >
      {carouselItems}
    </Carousel>
  );
};

export default SuperInsightsForSubjectAnalysisBoard;
