import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  Bag,
  Books,
  CalendarDots,
  ChalkboardSimple,
  ChalkboardTeacher,
  ChartBar,
  ChartLineUp,
  ClockCounterClockwise,
  Gear,
  GearSix,
  GraduationCap,
  MicrosoftExcelLogo,
  SignOut,
  Sparkle,
  SpeakerSimpleHigh,
  Student,
  Ticket,
  ToteSimple,
  Video
} from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logo } from "../../Assets/index";
import { useLogoutMutation } from "../../redux/api/authSlice";
import { clearLocalStorage } from "../../redux/features/auth/authSlice";
import { Roles } from "../../utils/Helper";

const navItems = [
  {
    title: "Dashboard",
    navigateTo: "",
    icon: <ChartBar />
  },
  { title: "Analyser", navigateTo: "analyser", icon: <ChartLineUp /> },
  { title: "Test History", navigateTo: "test-history", icon: <ClockCounterClockwise /> },
  { title: "Faculty", navigateTo: "manage-faculty", icon: <ChalkboardSimple /> },
  { title: "Student", navigateTo: "manage-student", icon: <Student /> },
  {
    title: "Batches",
    submenu: [
      { title: "Manage Batches", navigateTo: "manage-batch" },
      { title: "Create Batch", navigateTo: "create-batch" }
    ],
    icon: <Bag />
  },
  { title: "Syllabus", navigateTo: "syllabus", icon: <Books /> },
  {
    title: "Results",
    submenu: [
      { title: "Create Result", navigateTo: "create-results" },
      { title: "View Results", navigateTo: "view-results" },
      { title: "Download Results", navigateTo: "download-results" }
    ],
    icon: <MicrosoftExcelLogo />
  },
  { title: "Test Generator", navigateTo: "test-generator", icon: <ChartLineUp /> },
  { title: "ScaleyouAi", navigateTo: "chat-with-scaleyou-ai", icon: <Sparkle /> },
  { title: "Raise Ticket", navigateTo: "raise-ticket", icon: <Ticket /> },
  { title: "Tutorials", navigateTo: "tutorial", icon: <Video /> }
];

const navItems2 = [
  {
    title: "Dashboard",
    navigateTo: "",
    icon: <ChartBar />
  },
  { title: "Test History", navigateTo: "test-history", icon: <ClockCounterClockwise /> },
  { title: "Batch", navigateTo: "view-batch", icon: <Bag /> },
  { title: "Announcements", navigateTo: "create-announcements", icon: <SpeakerSimpleHigh /> },
  { title: "Student", navigateTo: "manage-student", icon: <Student /> },
  { title: "Board Results", navigateTo: "download-results", icon: <CalendarDots /> },
  { title: "ScaleyouAi", navigateTo: "chat-with-scaleyou-ai", icon: <Sparkle /> },
  { title: "Profile", navigateTo: "profile", icon: <GearSix /> },
  { title: "Raise Ticket", navigateTo: "raise-ticket", icon: <Ticket /> },
  { title: "How to use portal", navigateTo: "tutorial", icon: <Video /> }
];

const navItems3 = [
  {
    title: "Dashboard",
    navigateTo: "",
    icon: <ChartBar />
  },
  { title: "Test History", navigateTo: "test-history", icon: <ClockCounterClockwise /> },
  { title: "Batches", navigateTo: "view-batch", icon: <ToteSimple /> },
  { title: "Results", navigateTo: "view-results", icon: <CalendarDots /> },
  { title: "ScaleyouAi", navigateTo: "chat-with-scaleyou-ai", icon: <Sparkle /> },
  { title: "Raise Ticket", navigateTo: "raise-ticket", icon: <Ticket /> },
  { title: "Profile", navigateTo: "profile", icon: <GearSix /> },
  { title: "How to use portal", navigateTo: "tutorial", icon: <Video /> }
];

const navItems4 = [
  {
    title: "Colleges",
    navigateTo: "",
    icon: <GraduationCap />
  },
  { title: "Supervisors", navigateTo: "supervisor-list", icon: <ChalkboardTeacher /> },
  { title: "Tickets", navigateTo: "view-tickets", icon: <Ticket /> },
  { title: "Settings", navigateTo: "tutorial", icon: <Gear /> }
];

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, drawerWidth }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const [logout] = useLogoutMutation();

  const [active, setActive] = useState("");
  const [expandedBatches, setExpandedBatches] = useState(false);
  const [expandedResults, setExpandedResults] = useState(false);

  useEffect(() => {
    setActive(pathname.split("/")[1]);
  }, [pathname]);

  const handleLogout = async () => {
    await logout();
    dispatch(clearLocalStorage());
    navigate("/");
  };

  const handleClick = item => {
    if (user?.role === Roles.SUPERVISOR && item.title === "Batches") {
      setExpandedBatches(!expandedBatches);
    } else if (user?.role === Roles.SUPERVISOR && item.title === "Results") {
      setExpandedResults(!expandedResults);
    } else {
      if (active !== item.title) {
        navigate(`/${item.navigateTo}`);
        setActive(item.title);
      }
      !isNonMobile && setIsSidebarOpen(false);
    }
  };

  const toggleDrawer = open => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setIsSidebarOpen(false);
  };

  let renderedNavItems;

  if (user?.role === Roles.FACULTY) {
    renderedNavItems = navItems2;
  } else if (user?.role === Roles.SUPERVISOR) {
    renderedNavItems = navItems;
  } else if (user?.role === Roles.STUDENT) {
    renderedNavItems = navItems3;
  } else {
    renderedNavItems = navItems4;
  }

  return (
    <>
      {isNonMobile && (
        <Box
          width={isSidebarOpen ? drawerWidth : 0}
          height={"100vh"}
          position="fixed"
          sx={{
            borderRight: "1px solid lightgrey",
            zIndex: 10,
            top: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.01)",
            overflowX: "hidden",
            transition: "0.5s"
          }}
        >
          <Box display="flex" mt="0.75rem" alignItems="center" justifyContent="center" width="100%">
            <img src={logo} alt="logo" />
          </Box>
          <List sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {renderedNavItems.map((item, index) => {
              const isActive = active === item.navigateTo;

              return (
                <ListItem
                  key={item.title}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%"
                  }}
                >
                  <ListItemButton
                    style={{
                      fontSize: "1.5rem",
                      width: "100%"
                    }}
                    onClick={() => handleClick(item)}
                    sx={{
                      "position": "relative",
                      "color": active === item?.navigateTo ? theme.palette.secondary.main : theme.palette.primary.light,
                      "&::after":
                        active === item?.navigateTo
                          ? {
                              content: '""',
                              position: "absolute",
                              right: 0,
                              top: "23%",
                              height: "56%",
                              width: "3px",
                              borderRadius: "0.25rem",
                              backgroundColor: theme.palette.secondary.main
                            }
                          : {}
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        ml: "2rem",
                        color: isActive ? theme.palette.secondary.main : theme.palette.primary.light
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="h6"
                        sx={{
                          textOverflow: "clip",
                          textWrap: "nowrap",
                          color: isActive ? theme.palette.secondary.main : theme.palette.primary.light
                        }}
                      >
                        {item.title}
                      </Typography>
                    </ListItemText>
                    {item.submenu && (
                      <Typography>
                        {item.title === "Batches"
                          ? expandedBatches
                            ? "▲"
                            : "▼"
                          : item.title === "Results"
                            ? expandedResults
                              ? "▲"
                              : "▼"
                            : ""}
                      </Typography>
                    )}
                  </ListItemButton>
                  {user?.role === Roles.SUPERVISOR && expandedBatches && item.title === "Batches" && (
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                      <Box ml="2rem" mt="0.5rem">
                        {navItems[5].submenu.map((subItem, subIndex) => (
                          <Typography
                            key={subIndex}
                            variant="h6"
                            sx={{
                              "textOverflow": "clip",
                              "textWrap": "nowrap",
                              "&:hover": { color: theme.palette.secondary.main, cursor: "pointer" },
                              "color":
                                active === subItem.navigateTo
                                  ? theme.palette.secondary.main
                                  : theme.palette.primary.light,
                              "height": "2rem"
                            }}
                            onClick={() => {
                              setActive(subItem.title);
                              navigate(`/${subItem.navigateTo}`);
                              !isNonMobile && setIsSidebarOpen(false);
                            }}
                          >
                            {subItem.title}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {user?.role === Roles.SUPERVISOR && expandedResults && item.title === "Results" && (
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                      <Box ml="2rem" mt="0.5rem">
                        {navItems[7].submenu.map((subItem, subIndex) => (
                          <Typography
                            key={subIndex}
                            variant="h6"
                            sx={{
                              "textOverflow": "clip",
                              "textWrap": "nowrap",
                              "&:hover": { color: theme.palette.secondary.main, cursor: "pointer" },
                              "color":
                                active === subItem.navigateTo
                                  ? theme.palette.secondary.main
                                  : theme.palette.primary.light,
                              "height": "2rem"
                            }}
                            onClick={() => {
                              if (isActive !== subItem.title) {
                                navigate(`/${subItem.navigateTo}`);
                                setActive(subItem.title);
                              }
                              !isNonMobile && setIsSidebarOpen(false);
                            }}
                          >
                            {subItem.title}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                </ListItem>
              );
            })}
            <ListItem disablePadding>
              <ListItemButton
                style={{
                  fontSize: "1.5rem"
                }}
                onClick={handleLogout}
                sx={{
                  color: "red"
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color: "red"
                  }}
                >
                  <SignOut />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "red",
                      textWrap: "nowrap",
                      textOverflow: "clip"
                    }}
                  >
                    Log Out
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      )}

      {!isNonMobile && (
        <Drawer
          anchor={"left"}
          open={isSidebarOpen}
          onClose={toggleDrawer(false)}
          variant="temporary"
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {/* <Box
            width={isSidebarOpen ? drawerWidth : 0}
            height={"100vh"}
            position="fixed"
            sx={{
              borderRight: "1px solid lightgrey",
              zIndex: 10,
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.01)",
              overflowX: "hidden",
              transition: "0.5s"
            }}
          > */}
          <Box display="flex" mt="0.75rem" alignItems="center" justifyContent="center" width="100%">
            <img src={logo} alt="logo" />
          </Box>
          <List sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {renderedNavItems.map((item, index) => {
              const isActive = active === item.navigateTo;

              return (
                <ListItem
                  key={item.title}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%"
                  }}
                >
                  <ListItemButton
                    style={{
                      fontSize: "1.5rem",
                      width: "100%"
                    }}
                    onClick={() => handleClick(item)}
                    sx={{
                      "position": "relative",
                      "color": active === item?.navigateTo ? theme.palette.secondary.main : theme.palette.primary.light,
                      "&::after":
                        active === item?.navigateTo
                          ? {
                              content: '""',
                              position: "absolute",
                              right: 0,
                              top: "23%",
                              height: "56%",
                              width: "3px",
                              borderRadius: "0.25rem",
                              backgroundColor: theme.palette.secondary.main
                            }
                          : {}
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        ml: "2rem",
                        color: isActive ? theme.palette.secondary.main : theme.palette.primary.light
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="h6"
                        sx={{
                          textOverflow: "clip",
                          textWrap: "nowrap",
                          color: isActive ? theme.palette.secondary.main : theme.palette.primary.light
                        }}
                      >
                        {item.title}
                      </Typography>
                    </ListItemText>
                    {item.submenu && (
                      <Typography>
                        {item.title === "Batches"
                          ? expandedBatches
                            ? "▲"
                            : "▼"
                          : item.title === "Results"
                            ? expandedResults
                              ? "▲"
                              : "▼"
                            : ""}
                      </Typography>
                    )}
                  </ListItemButton>
                  {user?.role === Roles.SUPERVISOR && expandedBatches && item.title === "Batches" && (
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                      <Box ml="2rem" mt="0.5rem">
                        {navItems[5].submenu.map((subItem, subIndex) => (
                          <Typography
                            key={subIndex}
                            variant="h6"
                            sx={{
                              "textOverflow": "clip",
                              "textWrap": "nowrap",
                              "&:hover": { color: theme.palette.secondary.main, cursor: "pointer" },
                              "color":
                                active === subItem.navigateTo
                                  ? theme.palette.secondary.main
                                  : theme.palette.primary.light,
                              "height": "2rem"
                            }}
                            onClick={() => {
                              navigate(`/${subItem.navigateTo}`);
                              setActive(subItem.title);
                              !isNonMobile && setIsSidebarOpen(false);
                            }}
                          >
                            {subItem.title}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {user?.role === Roles.SUPERVISOR && expandedResults && item.title === "Results" && (
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                      <Box ml="2rem" mt="0.5rem">
                        {navItems[7].submenu.map((subItem, subIndex) => (
                          <Typography
                            key={subIndex}
                            variant="h6"
                            sx={{
                              "textOverflow": "clip",
                              "textWrap": "nowrap",
                              "&:hover": { color: theme.palette.secondary.main, cursor: "pointer" },
                              "color":
                                active === subItem.navigateTo
                                  ? theme.palette.secondary.main
                                  : theme.palette.primary.light,
                              "height": "2rem"
                            }}
                            onClick={() => {
                              navigate(`/${subItem.navigateTo}`);
                              setActive(subItem.title);
                              !isNonMobile && setIsSidebarOpen(false);
                            }}
                          >
                            {subItem.title}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  )}
                </ListItem>
              );
            })}
            <ListItem disablePadding>
              <ListItemButton
                style={{
                  fontSize: "1.5rem"
                }}
                onClick={handleLogout}
                sx={{
                  color: "red"
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color: "red"
                  }}
                >
                  <SignOut />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "red",
                      textWrap: "nowrap",
                      textOverflow: "clip"
                    }}
                  >
                    Log Out
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
          {/* </Box> */}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
