import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  useGridApiRef
} from "@mui/x-data-grid";
import { Pencil, Trash } from "@phosphor-icons/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDeleteSupervisorMutation, useGetAllSupervisorsQuery } from "../../../redux/api/adminSlice";
import { useGetAllSchoolQuery } from "../../../redux/api/schoolSlice";

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }
  return gridFilteredSortedRowIdsSelector(apiRef);
};

const AddSupervisor = () => {
  const apiRef = useGridApiRef();
  const theme = useTheme();
  const navigate = useNavigate();
  const supervisors = useSelector(state => state.supervisor);
  const [deleteSupervisor] = useDeleteSupervisorMutation();
  const { data: supervisorData, isSuccess: isgetSupervisorSuccess } = useGetAllSupervisorsQuery();
  const { data: schoolsData, isSuccess } = useGetAllSchoolQuery();

  let count = 0;

  const generateRowId = () => {
    // Generate a unique ID using a UUID library or any other method
    return count++; // Example: using a UUID library
  };

  const getRowId = row => row._id || generateRowId();

  // Define a function to get the class for each row
  const getRowClassName = params => {
    return "row-color";
  };

  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    if (supervisorData && schoolsData) {
      if (supervisorData?.supervisorUsers?.length && schoolsData?.schools?.length) {
        const updated = supervisorData.supervisorUsers.map((supervisor, index) => {
          return {
            id: index,
            ...supervisor,
            school_name: schoolsData.schools.find(school => school._id === supervisor.school_id)?.name,
            created_at: new Date(supervisor.created_at).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata", // India Standard Time
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            })
          };
        });
        setUsers(updated);
      }
    }
  }, [supervisorData]);

  const [deleteUserId, setDeleteUserId] = React.useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpenDeleteDialog = userId => {
    setDeleteUserId(userId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteUserId(null);
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      deleteSupervisor(deleteUserId);
      handleCloseDeleteDialog();
    }
  };

  const logicalColumns = [
    { field: "_id", headerName: "ID", flex: 0.4, headerClassName: "datagrid-header-color" },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "school_name",
      headerName: "School Name",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "signup_key",
      headerName: "Signup Key",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    { field: "created_at", headerName: "Created At", flex: 0.5, headerClassName: "datagrid-header-color" },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "datagrid-header-color",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      renderCell: params => (
        <div>
          <Button onClick={() => navigate(`/manage-supervisor/${params.row._id}`)}>
            <Pencil fill="blue" size={20} />
          </Button>
          <Button onClick={() => handleOpenDeleteDialog(params.row._id)}>
            <Trash size={20} weight="bold" color={theme.palette.alerts.error} />
          </Button>
        </div>
      )
    }
  ];

  const visibleColumns = logicalColumns.slice(1);

  return (
    <div className="datagrid-container">
      <DataGrid
        className="custom-datagrid"
        autoHeight
        rows={users}
        disableRowSelectionOnClick
        apiRef={apiRef}
        columns={visibleColumns}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
        components={{
          Toolbar: props => (
            <Box display="flex" justifyContent="space-between" p="15px">
              <GridToolbar {...props} />
            </Box>
          )
        }}
        slotProps={{
          toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          },
          columns: {
            columnVisibilityModel: {
              _id: false
            }
          }
        }}
        pageSizeOptions={[10, 20, 50]}
      />

      {/* Delete confirmation dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddSupervisor;
