import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const resultsApiSlice = createApi({
  reducerPath: "resultsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/results`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Results"],
  endpoints: builder => ({
    createResults: builder.mutation({
      query: resultsData => ({
        url: "/create-results",
        method: "POST",
        body: resultsData
      }),
      invalidatesTags: ["Results"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "createResults" });
          await queryFulfilled;
          toast.success("Results created successfully", { id: "createResults" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "createResults" });
        }
      }
    }),
    editResults: builder.mutation({
      query: ({ result_id, resultsData }) => ({
        url: "/edit-results",
        method: "POST",
        body: { result_id, resultsData }
      }),
      invalidatesTags: ["Results"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "editResults" });
          await queryFulfilled;
          toast.success("Results updated successfully", { id: "editResults" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "editResults" });
        }
      }
    }),
    publishResults: builder.mutation({
      query: resultsData => ({
        url: "/publish-results",
        method: "POST",
        body: resultsData
      }),
      invalidatesTags: ["Results"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "publishResults" });
          await queryFulfilled;
          toast.success("Results published successfully", { id: "publishResults" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "publishResults" });
        }
      }
    }),
    getAllResults: builder.query({
      query: schoolId => ({
        url: `/get-all-results/${schoolId}`
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getAllResults" });
        }
      }
    }),
    getIndividualResults: builder.query({
      query: idObject => ({
        url: `/get-individual-results/${idObject.result_id}/${idObject.student_id}`
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getIndividualResults" });
        }
      }
    }),
    getAllStudentResults: builder.mutation({
      query: ({ school_id, studentId }) => ({
        url: "/get-student-results",
        method: "POST",
        body: { school_id, studentId }
      }),
      invalidatesTags: ["Results"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "getAllStudentResults" });
          await queryFulfilled;
          toast.success("Results fetched successfully", { id: "getAllStudentResults" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getAllStudentResults" });
        }
      }
    }),
    getStudentResults: builder.query({
      query: ({ school_id, studentId }) => ({
        url: `/get-student-results/${school_id}/${studentId}`
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getIndividualResults" });
        }
      }
    }),
    getResultById: builder.query({
      query: id => ({
        url: `/get-result/${id}`
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getResultById" });
        }
      }
    }),
    getLatestResultWithRankbyBatch: builder.query({
      query: ({ batchId, schoolId, studentId }) => ({
        url: `/get-result-rank-by-batch/${batchId}`,
        method: "POST",
        body: { school_id: schoolId, student_id: studentId }
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getResultById" });
        }
      }
    }),
    getLatestResultWithRankbyBatchForFaculty: builder.query({
      query: ({ batchId, schoolId }) => ({
        url: `/get-result-rank-by-batch-for-faculty/${batchId}`,
        method: "POST",
        body: { school_id: schoolId }
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getResultById" });
        }
      }
    }),
    getStudentResultByStudentId: builder.query({
      query: ({ studentId, schoolId }) => ({
        url: `/get-student-result-by-studentId/${schoolId}/${studentId}`
      }),
      providesTags: ["Results"],
      async onQueryFullled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "getStudentResultByStudentId" });
        }
      }
    })
  })
});

export const {
  useCreateResultsMutation,
  useEditResultsMutation,
  usePublishResultsMutation,
  useGetAllResultsQuery,
  useGetIndividualResultsQuery,
  useGetAllStudentResultsMutation,
  useGetResultByIdQuery,
  useGetStudentResultsQuery,
  useGetLatestResultWithRankbyBatchQuery,
  useGetLatestResultWithRankbyBatchForFacultyQuery,
  useGetStudentResultByStudentIdQuery
} = resultsApiSlice;
