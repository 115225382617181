import { Box } from "@mui/material";
import React from "react";
import Stats from "../StatsBar/Questionset";
import ScoreAnalysisGraph from "./GraphsComponent/ScoreAnalysisGraph";

const OverAllStats = ({ analysis }) => {
  return (
    <>
      <Stats analysis={analysis} />
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap="1rem">
        <ScoreAnalysisGraph />
      </Box>
    </>
  );
};

export default OverAllStats;
