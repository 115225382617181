// inbuilt modules
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// external modules
import { Box, Divider, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery } from "@mui/material";
import { useGetEntireSyllabusQuery } from "../../../redux/api/syllabusSlice";
import DifficultyAnalysis from "../Difficulty/diff";
import IndividualSubjectAnalysis from "./Subjects/IndividualSubjectAnalysis";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "width": "100%",
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontWeight: 600
  }
}));

const SubjectAnalysis = ({ analysis, syllabusAnalysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const [alignment, setAlignment] = useState("");
  const { user } = useSelector(state => state.auth);

  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user?.school_id);

  useEffect(() => {
    if (syllabusAnalysis?.subjectAnalysis && Object.keys(syllabusAnalysis.subjectAnalysis).length) {
      setAlignment(Object.keys(syllabusAnalysis.subjectAnalysis)[0]);
    }
  }, [syllabusAnalysis]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap="2rem">
      {/* Difficulty Analysis */}
      <DifficultyAnalysis analysis={analysis} syllabusAnalysis={syllabusAnalysis} />

      {/* Divider */}
      <Divider
        style={{
          backgroundColor: "rgba(0,0,0,0.2)",
          width: "100%",
          height: "6px",
          borderRadius: "1rem"
        }}
      />

      {/* Individual Subject Analysis Section */}
      <Box display="flex" width="100%" justifyContent="center" flexDirection="column" gap="2rem">
        <Box display="flex" width="100%" gap="1rem" flexDirection="column">
          <Typography variant="h4" fontWeight={500}>
            Individual Subject Analysis
          </Typography>
          <Divider style={{ backgroundColor: "grey", width: "10%" }} />
          <Typography variant="h5" gutterBottom style={{ color: "grey" }}>
            Select subject for detailed subject analysis
          </Typography>
        </Box>

        {/* Subject Toggle Buttons */}
        <Box display="flex" width="100%" alignItems="center" justifyContent="center" flexDirection="column" gap="2rem">
          {syllabusAnalysis?.subjectAnalysis && Object.keys(syllabusAnalysis.subjectAnalysis).length ? (
            <ToggleButtonGroup
              sx={{
                bgcolor: "#fff",
                width: isNonMobile ? "80%" : "100%"
              }}
              value={alignment}
              exclusive
              onChange={handleToggle}
            >
              {Object.keys(syllabusAnalysis.subjectAnalysis).map(subject => (
                <StyledToggleButton key={subject} value={subject}>
                  {subject}
                </StyledToggleButton>
              ))}
            </ToggleButtonGroup>
          ) : (
            <Typography>No subjects available for analysis</Typography>
          )}
        </Box>
      </Box>

      {/* Individual Subject Analysis Component */}
      <Box width="100%">
        <IndividualSubjectAnalysis analysis={analysis} syllabusAnalysis={syllabusAnalysis} alignment={alignment} />
      </Box>
    </Box>
  );
};

export default SubjectAnalysis;
