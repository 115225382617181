import React from "react";
import VideoPlayer from "../../../utils/VideoPlayer";

const DemoVideos = () => {
  return (
    <main className="relative -mt-[15%] md:-mt-[10%] lg:mt-8 flex flex-col gap-8 lg:gap-20 w-full">
      <div className="container mx-auto p-4 lg:px-20 flex flex-col justify-center items-center gap-8 lg:gap-12 w-full max-w-7xl">
        <div className=" flex flex-col justify-center items-center w-full gap-2">
          <h1 className="m-0 font-DMSans tracking-wide leading-[0px] text-[24px] font-bold md:font-semibold text-[#4A3AFF] uppercase">
            demo videos
          </h1>
          <h2 className="m-0 font-DMSans font-bold text-[35px] lg:text-[50px] text-wrap text-center text-[#170F49]">
            Explore Our Platform
          </h2>
          <h4 className="m-0 font-DMSans font-normal text-wrap text-[18px] leading-[30px] text-center text-[#6F6C90]">
            These three videos clearly explain our product and reinforce our “institute-first platform” commitment,
            showing how AI combined with traditional education enhances efficiency for students, faculty, and the entire
            institute.
          </h4>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-20">
        <div className="flex flex-col gap-2 w-full items-center">
          <div className="h-full w-[80%] md:w-[50%]">
            <VideoPlayer videoUrl={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} />
          </div>

          <p className="w-[90%] text-center">
            The "Rick Roll" song refers to "Never Gonna Give You Up," a 1987 hit by British singer Rick Astley. Written
            and produced by the legendary trio Stock Aitken Waterman, the song is a quintessential example of the 1980s
            pop sound, characterized by its upbeat tempo, catchy melody, and Astley's deep, smooth vocals.
          </p>
        </div>
        <div className="flex flex-col gap-2 w-full items-center">
          <div className="h-full w-[80%] md:w-[50%]">
            <VideoPlayer videoUrl={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} />
          </div>

          <p className="w-[90%] text-center">
            The "Rick Roll" song refers to "Never Gonna Give You Up," a 1987 hit by British singer Rick Astley. Written
            and produced by the legendary trio Stock Aitken Waterman, the song is a quintessential example of the 1980s
            pop sound, characterized by its upbeat tempo, catchy melody, and Astley's deep, smooth vocals.
          </p>
        </div>
        <div className="flex flex-col gap-2 w-full items-center">
          <div className="h-full w-[80%] md:w-[50%]">
            <VideoPlayer videoUrl={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} />
          </div>

          <p className="w-[90%] text-center">
            The "Rick Roll" song refers to "Never Gonna Give You Up," a 1987 hit by British singer Rick Astley. Written
            and produced by the legendary trio Stock Aitken Waterman, the song is a quintessential example of the 1980s
            pop sound, characterized by its upbeat tempo, catchy melody, and Astley's deep, smooth vocals.
          </p>
        </div>
      </div>
    </main>
  );
};

export default DemoVideos;
