import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ResultStepper from "../../../components/Supervisor/Results/CreateResults/ResultStepper";

const CreateResults = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // theme
  const theme = useTheme();

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 0.75rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Create Result</title>
        <meta name="description" content="Start your detailed Analysis with Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box display="flex" gap="0.75rem" flexDirection="column">
        <Typography variant="h2" fontWeight={600}>
          Create Result
        </Typography>
        <Typography variant="h6" color={theme.palette.primary.light}>
          Fill the options below to create a new result
        </Typography>
      </Box>
      <Box overflow="hidden" width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" p="0.4rem">
        <ResultStepper />
      </Box>
    </Box>
  );
};

export default CreateResults;
