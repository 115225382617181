import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { s3 } from "../../../utils/AWS_S3";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
// import OverAllStats from "../../../components/Teacher/ExamAnalysis/OverAllStats";
import Choice from "../../../components/Teacher/Tabs/Option";
import OverAllStats from "../../../components/Teacher/ExamAnalysis/NewOverAllStats";
import { useGetAllAnalysisQuery } from "../../../redux/api/analysisSlice";

const TestAnalysis = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const bucketName = "insights-omr";

  const { analysisId } = useParams();
  const [examId, setExamId] = useState("");
  const [selectedValue, setSelectedValue] = useState("Batch1");

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const theme = useTheme();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllAnalysisQuery(user.school_id);
  const [analysis, setAnalysis] = useState({});

  useEffect(() => {
    if (isGetAnalysisSuccess) {
      const selectedAnalysis = analysisArray?.analysisArray?.find(item => item._id === analysisId);
      setExamId(selectedAnalysis?.exam_id);
      setAnalysis(selectedAnalysis || {});
    }
  }, [analysisArray, analysisId]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "/test-history" },
    { name: `${analysis.exam_for || "..."}-${analysis.exam_unique_code || "..."}`, link: "#" }
  ];

  const onQuestionPaperDownload = async () => {
    const qpaAnswerKey = `${user.school_id}-${analysis.exam_id}/${user.school_id}-${analysis.exam_id}-questionPaper.pdf`;

    const params = {
      Bucket: bucketName,
      Key: qpaAnswerKey
    };

    const toastId = toast.loading("Downloading Question Paper...");

    try {
      const data = await s3.getObject(params).promise();
      const blob = new Blob([data.Body], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "questionPaper.pdf";
      document.body.appendChild(link);
      toast.dismiss(toastId);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toast.dismiss(toastId);
      console.log(err);
      toast.error("Error downloading file");
    }
  };

  const onAnswerKeyDownload = async () => {
    const qpaAnswerKey = `${user.school_id}-${analysis.exam_id}/${user.school_id}-${analysis.exam_id}-answerKey.pdf`;
    const params = {
      Bucket: bucketName,
      Key: qpaAnswerKey
    };

    const toastId = toast.loading("Downloading Answer Key...");

    try {
      const data = await s3.getObject(params).promise();
      const blob = new Blob([data.Body], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "answerKey.pdf";
      document.body.appendChild(link);
      toast.dismiss(toastId);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.dismiss(toastId);
      console.log(error);
      toast.error("Error downloading file");
    }
  };

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Test Analysis</title>
        <meta name="description" content="Have a detailed Analysis of your Test." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
        <meta property="og:title" content="Insights | Dashboard" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Start your detailed Analysis with Insights." />
        <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg" />
        <meta property="og:url" content="http://localhost:3000/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div style={{ padding: "1rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Exam Analysis
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.primary.light}>
            Know about the progress report of your class
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" bgcolor="inherit" gap="0.5rem">
          <Box
            display="flex"
            alignItems="center"
            gap="0.5rem"
            bgcolor="#154FEF"
            color="#ffffff"
            padding="0.5rem 1rem"
            borderRadius="0.25rem"
            onClick={onQuestionPaperDownload}
            sx={{ cursor: "pointer" }}
          >
            {/* Download symbol for 'Download Question' */}
            <MdOutlineFileDownload size={24} color="#ffffff" />
            <Typography variant="subtitle2" color="#ffffff">
              Question Paper
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="0.5rem"
            onClick={onAnswerKeyDownload}
            bgcolor="#dbe8ff"
            padding="0.5rem 1rem"
            borderRadius="0.25rem"
            color="#154FEF"
            sx={{ cursor: "pointer" }}
          >
            {/* Download symbol for 'Download Answer Sheet' */}
            <MdOutlineFileDownload size={24} color={theme.palette.secondary.main} />

            <Typography variant="subtitle2">Answer Paper</Typography>
          </Box>
        </Box>
      </Box>
      <OverAllStats analysis={analysis} />
    </Box>
  );
};

export default TestAnalysis;
