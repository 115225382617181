import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const analysisApiSlice = createApi({
  reducerPath: "analysisApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/analysis`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [],
  endpoints: build => ({
    getAllAnalysis: build.query({
      query: schoolId => ({
        url: `/getAllAnalysis/${schoolId}`
      }),
      providesTags: ["AllAnalysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getAllFacultyAnalysis: build.query({
      query: ({ school_id, faculty_id }) => ({
        url: `/getAllFacultyAnalysis/${school_id}/${faculty_id}`
      }),
      providesTags: ["AllFacultyAnalysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getAnalysisById: build.query({
      query: id => ({
        url: `/getAnalysisById/${id}`
      }),
      providesTags: ["Analysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getSyllabusAnalysis: build.query({
      query: ({ school_id, exam_id }) => ({
        url: `/getExamSyllabusAnalysis/${school_id}/${exam_id}`
      }),
      providesTags: ["SyllabusAnalysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getAllStudentAnalysis: build.query({
      query: id => ({
        url: `/getAllStudentAnalysis/${id}`
      }),
      providesTags: ["StudentAnalysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getStudentAnalysisOfAnExamWithRange: build.query({
      query: ({ exam_id, school_id, student_id }) => {
        return {
          url: `/getStudenAnalysisOfAnExam/${school_id}/${exam_id}/${student_id}`
        };
      },
      providesTags: ["StudentAnalysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getAnalysisOfAnExamWithRangeForFaculty: build.query({
      query: ({ analysis_id, school_id }) => {
        return {
          url: `/getStudenAnalysisOfAnExamForFaculty/${school_id}/${analysis_id}`
        };
      },
      providesTags: ["StudentAnalysis"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getStudentRanks: build.query({
      query: ({ exam_id, school_id }) => {
        return {
          url: `/getStudentRanks/${school_id}/${exam_id}`
        };
      },
      providesTags: ["StudentRanks"],
      async onQueryFullfilled(_, { queryFullfilled }) {
        try {
          await queryFullfilled;
        } catch (error) {
          console.log(error);
        }
      }
    })
  })
});

export const {
  useGetAllAnalysisQuery,
  useGetAllFacultyAnalysisQuery,
  useGetAllStudentAnalysisQuery,
  useGetSyllabusAnalysisQuery,
  useGetAnalysisByIdQuery,
  useGetStudentAnalysisOfAnExamWithRangeQuery,
  useGetAnalysisOfAnExamWithRangeForFacultyQuery,
  useGetStudentRanksQuery
} = analysisApiSlice;
