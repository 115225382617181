import React from "react";
import StudentIdentifier from "../../../../Assets/StudentAnalysis/IdentifierForStudentInExamBar.png";
// import RookieLightning from "../../../../Assets/StudentAnalysis/Rookie-Lightning.png";

export const StudentStandingGraphs = ({ data }) => {
  const staticHeight = [20, 40, 60, 80, 100, 90, 70, 50, 30, 10];
  const GraphBars = ({ height, selected }) => {
    return (
      <div
        className="flex items-end justify-center"
        style={{
          height: "100%",
          width: "8%",
          margin: "0 8px",
          position: "relative"
        }}
      >
        {/* Bar with dynamic height */}
        <div
          style={{
            position: "relative",
            height: `${height}%`,
            backgroundColor: selected ? "#F0C01B" : "#fafafb",
            width: "100%",
            borderRadius: "0.5rem",
            border: "1px solid #c7c7c7",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
          }}
        >
          {/* Student identifier image */}
          {selected && (
            <img
              src={StudentIdentifier}
              alt="Student Identifier"
              style={{
                position: "absolute",
                top: "-20px", // Adjust to position the image above the bar
                left: "50%",
                transform: "translateX(-50%)",
                width: "40px",
                height: "40px"
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[400px] p-4 gap-4">
      {/* Flex container for the bars */}
      <div className="flex flex-row h-full items-end justify-around">
        {data?.data.map((item, index) => {
          const value = Object.values(item)[0]; // Get the first (and only) value in each object
          const key = Object.keys(item)[0];
          return (
            <GraphBars key={index} height={staticHeight[index]} selected={key === String(data?.selectedInterval)} />
          );
        })}
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-[100%] h-[24px] flex justify-center align-middle bg-[#FFF2E4] rounded-lg place-content-center gap-1">
          {/* <img src={RookieLightning} alt={"Rookie Lightning"} height={"8px"} width={"8px"} className="self-center" /> */}
          <div className="text-[#EF6820] text-center font-semibold self-center">Rookie</div>
        </div>
        <div className="w-[100%] h-[24px] bg-[#e1fbea] rounded-lg place-content-center">
          <div className="text-[#16B364] text-center font-semibold">Intermediate</div>
        </div>
        <div className="w-[100%] h-[24px] bg-[#f3f1ff] rounded-lg place-content-center">
          <div className="text-[#875BF7] text-center font-semibold">Advanced</div>
        </div>
      </div>
    </div>
  );
};

export default StudentStandingGraphs;
