export const BoardList = {
  BIHAR_SCHOOL_EXAMINATION_BOARD: "Bihar School Examination Board",
  CBSE: "CBSE",
  ICSE: "ICSE",
  JHARKAND_ACADEMIC_COUNCIL: "Jharkand Academic Council",
  KARNATAKA_BOARD: "Karnataka Board",
  KERALA_BOARD_OF_PUBLIC_EXAMINATION: "Kerala Board of Public Examination",
  MAHARASHTRA_STATE_BOARD: "Maharashtra State Board",
  NATIONAL_OPEN_SCHOOL: "National Open School",
  OTHER: "Other",
  TAMIL_NADU_BOARD_OF_SECONDARY_EDUCATION: "Tamil Nadu Board of Secondary Education",
  WEST_BENGAL_BOARD_OF_SECONDARY_EDUCATION: "West Bengal Board of Secondary Education",
  BOARD_OF_IMEA: "Board of IMEA",
  DEPT_OF_PRE_UNIVERSITY_EDUCATION: "Dept. of Pre University Education"
};
