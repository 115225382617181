import { useTheme } from "@emotion/react";
import Send from "@mui/icons-material/Send";
import { Avatar, Box, TextField, Typography } from "@mui/material";
import { User } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useCreateCommentMutation,
  useFetchAnnouncementByIdQuery,
  useFetchCommentsByAnnouncementIdQuery
} from "../../../redux/api/announcementSlice";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";
import { fetchAnnouncementById } from "../../../redux/features/Announcement/announcementSlice";
import { fetchCommentsByAnnouncementId } from "../../../redux/features/AnnouncementComment/commentSlice";
import { formatTimeAMPM } from "../../../utils/FormatAMPM";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import CButton from "../../ui/Button";

const IndividualAnnouncement = () => {
  const { batchId, announcementId } = useParams();
  const [announcement, setAnnouncement] = useState();
  const [currentComments, setCurrentComments] = useState();

  const {
    batch: { batches },
    auth: { user },
    announcements: { announcements, individualAnnouncement, loading },
    comments: { comments }
  } = useSelector(state => state);
  const theme = useTheme();
  const [name, setName] = useState("");
  const { data: batchesData, isSuccess: isGetBatchesSuccess } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });
  useEffect(() => {
    if (isGetBatchesSuccess && batchesData.batches) {
      const batchName = batchesData.batches.find(batch => batch._id === batchId);
      setName(batchName?.batch_name || "");
    }
  }, [batchesData?.batches, batchId]);

  const { data: announcementData, isSuccess: isGetAnnouncementSuccess } = useFetchAnnouncementByIdQuery(announcementId);
  useEffect(() => {
    dispatch(fetchAnnouncementById(announcementId));
  }, []);
  useEffect(() => {
    if (isGetAnnouncementSuccess && announcementData.announcement) {
      setAnnouncement(announcementData.announcement);
    }
  }, [announcementData, announcementId]);

  const { data: commentsData, isSuccess: isGetCommentsSuccess } = useFetchCommentsByAnnouncementIdQuery(announcementId);
  useEffect(() => {
    dispatch(fetchCommentsByAnnouncementId(announcementId));
  }, [announcementId]);

  useEffect(() => {
    if (isGetCommentsSuccess && commentsData.comments) {
      setCurrentComments(commentsData.comments);
    }
  }, [commentsData, announcementId]);

  const [commentText, setCommentText] = useState("");
  const dispatch = useDispatch();
  const [createComment, { isLoading }] = useCreateCommentMutation();
  const handleSubmit = async e => {
    if (!commentText) {
      return toast.error("comment cannot be empty");
    }
    const commentObj = {
      text: commentText,
      userId: user._id,
      announcementId: announcementId
    };
    createComment(commentObj);
    setCommentText("");
    dispatch(fetchCommentsByAnnouncementId(announcement._id));
  };

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/manage-batch" },
    { name: `${name}`, link: `/view-batch/${batchId}` },
    { name: "announcements", link: `/view-batch/${batchId}` },
    { name: `${announcement?.topic.slice(0, 7)}...`, link: "#" }
  ];
  if (!announcement) {
    return <>...Loading</>;
  }
  return (
    <Box sx={{ height: "100%" }}>
      <div style={{ padding: "1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box sx={{ padding: "1rem" }}>
        <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>Announcements</Typography>
        <Typography>General news and announcements</Typography>
      </Box>
      <Box sx={{ padding: "2rem 1rem" }}>
        <Typography sx={{ fontWeight: "700", fontSize: "1.5rem" }}>{announcement?.topic}</Typography>
        <Box sx={{ border: "1px solid lightgrey", borderRadius: "0.5rem", minHeight: "300px" }}>
          <Box sx={{ display: "flex", padding: "1rem 0.5rem" }}>
            <Box sx={{ display: "flex", flexGrow: "1", gap: "1rem", alignItems: "center" }}>
              <Box sx={{ border: "0.5px solid lightgrey", borderRadius: "50%" }}>
                <Avatar>
                  {announcement?.author?.name &&
                    announcement.author.name.split(" ")[0][0] +
                      (announcement.author.name.split(" ")[1] ? announcement.author.name.split(" ")[1][0] : "")}
                </Avatar>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>{announcement?.topic}</Typography>
                <Typography>
                  by <span>{announcement?.author?.name}</span> {`${formatTimeAMPM(announcement.createdAt)}`}{" "}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: "1rem 0 1rem 2rem" }}>
            <Typography sx={{ fontWeight: "400", fontSize: "1rem" }}>{announcement?.description}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "1rem" }}>
        <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>Comments</Typography>

        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", padding: "1rem" }}>
            <Box sx={{ border: "0.5px solid lightgrey", borderRadius: "50%", maxHeight: "maxContent" }}>
              <Avatar />
            </Box>
            <Box sx={{ flexGrow: "1" }}>
              <TextField
                id="outlined-textarea"
                label="Comment"
                placeholder="write your Comment..."
                fullWidth
                value={commentText}
                onChange={e => setCommentText(e.target.value)}
              />
            </Box>
            <CButton
              buttonText="submit"
              variant="primary"
              sx={{ height: "2.5rem", position: "relative", right: "0px" }}
              icon={<Send weight="bold" color="#fff" size={18} />}
              onClickFun={handleSubmit}
            />
          </Box>
        </Box>
        <Box>
          {currentComments &&
            currentComments.map((comment, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "1rem",
                  borderBottom: "1px solid lightgray"
                }}
              >
                <Box sx={{ border: "0.5px solid lightgrey", borderRadius: "50%" }}>
                  <Avatar>
                    {comment?.author?.name &&
                      comment.author.name.split(" ")[0][0] +
                        (comment.author.name.split(" ")[1] ? comment.author.name.split(" ")[1][0] : "")}
                  </Avatar>
                </Box>
                <Box>
                  <Typography sx={{ color: theme.palette.secondary.main }}>
                    {comment.author.name}{" "}
                    <span style={{ color: "gray" }}>{`${formatTimeAMPM(comment.createdAt)}`}</span>
                  </Typography>
                  <Typography>{comment.text}</Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default IndividualAnnouncement;
