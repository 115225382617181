import { Box, Divider, FormControl, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useCreateAUserMutation } from "../../../redux/api/supervisorSlice";
import { Roles } from "../../../utils/Helper";
import CButton from "../../ui/Button";

const AddSingle = ({ type }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const role = type;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rollNo, setRollNo] = useState("");

  const { user } = useSelector(state => state.auth);

  const [createAUser] = useCreateAUserMutation();
  const data = {
    name,
    email,
    rollNo,
    role: type,
    school_id: user.school_id
  };

  const handleAddSingleUser = () => {
    createAUser(data);
    setEmail("");
    setName("");
    setRollNo("");
  };

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    if (name.trim() === "" || email.trim() === "") {
      return toast.error("Please fill all the fields");
    }
    if (!isValidEmail(email)) {
      return toast.error("Please enter a valid email address");
    }
    if (role === Roles.STUDENT && rollNo.trim() === "") {
      return toast.error("Please fill all the fields");
    }
    if (role === Roles.STUDENT && rollNo.length !== 6) {
      return toast.error("Roll Number should be 6 digits");
    }
    handleAddSingleUser();
  };

  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Box width={isNonMobile ? "30%" : "100%"} display="flex" gap="2rem" flexDirection="column">
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h4" mb="0.2rem">
            Name of the {role === Roles.FACULTY ? "Faculty" : "Student"}
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <FormControl>
            <TextField
              placeholder="Enter name here"
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
          <Typography variant="h4" mb="0.2rem">
            Email
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <FormControl>
            <TextField
              placeholder="Enter email here"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              sx={{
                width: "100%",
                bgcolor: "#F7F6F9"
              }}
              InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
            />
          </FormControl>
        </Box>
        {role === Roles.STUDENT && (
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Roll Number
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <FormControl>
              <TextField
                placeholder="Enter Roll number here"
                value={rollNo}
                onChange={e => setRollNo(e.target.value)}
                type="number"
                sx={{
                  width: "100%",
                  bgcolor: "#F7F6F9"
                }}
                InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
              />
            </FormControl>
          </Box>
        )}
        <Box width="100%" display="flex" justifyContent="center">
          <CButton
            buttonText={role === Roles.FACULTY ? "Add Faculty" : "Add Student"}
            variant="primary"
            onClickFun={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddSingle;
