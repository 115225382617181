import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import SectionAnalysis from "../../../../components/Teacher/ExamAnalysis/SectionAnalysis";
import ChapterAnalysis from "../../../../components/Teacher/ExamAnalysis/ChapterAnalysis";
import DifficultyAnalysis from "../../../../components/Teacher/ExamAnalysis/DifficultyAnalysis";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.2rem",
    color: "#fff"
  }
}));

const StyledToggleButtonForSubjects = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.5rem",
    color: "#fff"
  }
}));

const DetailedAnalysis = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px) and (min-height:600px) and (orientation: landscape)");
  const location = useLocation();
  const containerRef = useRef(null);
  const stickyRef = useRef(null);

  const { analysis, syllabus } = location.state || {};

  const [isSticky, setIsSticky] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [visibleSubjects, setVisibleSubjects] = useState([0, 1]);
  const [subjectAlignment, setSubjectAlignment] = useState("");

  const classAnalysisData = useMemo(
    () => [
      {
        name: "Total Questions",
        value: analysis?.examAnalysis?.total_questions ?? 0
      },
      {
        name: "Average Accuracy",
        value: analysis?.examAnalysis?.average_accuracy ?? 0
      },
      {
        name: "Average Inaccuracy",
        value: analysis?.examAnalysis?.average_inaccuracy ?? 0
      },
      {
        name: "Average Unanswered",
        value: analysis?.examAnalysis?.average_unanswered ?? 0
      },
      {
        name: "Highest Marks",
        value: analysis?.examAnalysis?.highest_score ?? 0
      },
      {
        name: "Average Marks",
        value: analysis?.examAnalysis?.average_score ?? 0
      }
    ],
    [analysis?.examAnalysis]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!syllabus || !analysis?.syllabusAnalysis?.subjectAnalysis) return;

    const syllabusSubjects = Object.keys(syllabus);
    const subjectOfStudents = Object.keys(analysis.syllabusAnalysis.subjectAnalysis);
    const validSubjects = subjectOfStudents.filter(subject => syllabusSubjects.includes(subject));

    setSubjects(validSubjects);
    setSubjectAlignment(validSubjects[0] || "");
  }, [analysis?.syllabusAnalysis?.subjectAnalysis, syllabus]);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current || !stickyRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const stickyRect = stickyRef.current.getBoundingClientRect();

      setIsSticky(containerRect.top <= -208 && containerRect.bottom >= stickyRect.height);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollLeft = () => {
    if (visibleSubjects[0] > 0) {
      const newVisibleSubjects = [visibleSubjects[0] - 1, visibleSubjects[1] - 1];
      setVisibleSubjects(newVisibleSubjects);

      // Use the subjects array to set the new subjectAlignment
      setSubjectAlignment(subjects[newVisibleSubjects[0]]);
    }
  };

  const handleScrollRight = () => {
    if (visibleSubjects[1] < subjects.length - 1) {
      const newVisibleSubjects = [visibleSubjects[0] + 1, visibleSubjects[1] + 1];
      setVisibleSubjects(newVisibleSubjects);

      // Use the subjects array to set the new subjectAlignment
      setSubjectAlignment(subjects[newVisibleSubjects[0]]);
    }
  };

  const handleSubjectToggle = (_, newSubjectAlignment) => {
    if (newSubjectAlignment) {
      setSubjectAlignment(newSubjectAlignment);
    }
  };

  const getDifficultyData = subjectData => {
    if (!subjectData) return { correct: 0, wrong: 0 };
    return {
      correct: subjectData.correct ?? 0,
      wrong: subjectData.wrong ?? 0
    };
  };

  const difficultyAnalysisData = useMemo(() => {
    const subjectData = analysis?.syllabusAnalysis?.subjectAnalysis[subjectAlignment];
    if (!subjectData) return [];

    return [
      {
        subject: "Analysing and Evaluating",
        A:
          getDifficultyData(subjectData.evaluating_and_analysing).correct +
          getDifficultyData(subjectData.evaluating_and_analysing).wrong
      },
      {
        subject: "Creating",
        A:
          getDifficultyData(subjectData.creating_question).correct +
          getDifficultyData(subjectData.creating_question).wrong
      },
      {
        subject: "Remembering",
        A: getDifficultyData(subjectData.remembering).correct + getDifficultyData(subjectData.remembering).wrong
      },
      {
        subject: "Understanding",
        A:
          getDifficultyData(subjectData.understanding_questions).correct +
          getDifficultyData(subjectData.understanding_questions).wrong
      },
      {
        subject: "Applying",
        A:
          getDifficultyData(subjectData.applying_questions).correct +
          getDifficultyData(subjectData.applying_questions).wrong
      }
    ];
  }, [analysis?.syllabusAnalysis?.subjectAnalysis, subjectAlignment]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        padding: isNonMobile ? "1rem 2rem" : "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.5rem",
        bgcolor: "rgb(250,250,251)",
        width: "100%",
        height: "100%"
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h4" fontWeight="medium" textAlign="center">
          Detailed Analysis
        </Typography>
        <Typography variant="body2" fontWeight="medium" textAlign="center" color="text.secondary" sx={{ mt: 1 }}>
          A comprehensive analysis of each subject's sections, including detailed{" "}
          <Box component="span" color="primary.main">
            insights
          </Box>{" "}
          and tailored recommendations for targeted{" "}
          <Box component="span" color="primary.main">
            improvement
          </Box>
          .
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          display: "flex",
          gap: "1.5rem",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          pt: "1rem",
          pb: "2rem"
        }}
      >
        <Box
          ref={stickyRef}
          sx={{
            position: isSticky ? "fixed" : "static",
            top: isSticky ? 0 : "auto",
            left: isSticky ? `${containerRef.current?.getBoundingClientRect().left}px` : "auto",
            width: isSticky ? `${containerRef.current?.offsetWidth}px` : "100%",
            zIndex: theme.zIndex.appBar,
            backgroundColor: "background.paper",
            padding: "0.5rem 0",
            borderRadius: "0.5rem",
            boxShadow: isSticky ? theme.shadows[1] : "none"
          }}
        >
          {subjects.length === 0 ? (
            <Typography variant="h6" textAlign="center" width="100%">
              No{" "}
              <Box component="span" color="primary.main">
                Subjects
              </Box>{" "}
              To Display
            </Typography>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              {subjects.length > 2 && (
                <IconButton onClick={handleScrollLeft} disabled={visibleSubjects[0] === 0}>
                  <ArrowBackIos />
                </IconButton>
              )}

              <Box
                sx={{
                  display: "flex",
                  overflow: "hidden",
                  width: isNonMobile ? "50%" : "80%",
                  maxWidth: "600px"
                }}
              >
                <ToggleButtonGroup
                  value={subjectAlignment}
                  exclusive
                  onChange={handleSubjectToggle}
                  sx={{
                    bgcolor: "action.hover",
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.5rem"
                  }}
                >
                  {subjects.slice(visibleSubjects[0], visibleSubjects[1] + 1).map(subject => (
                    <StyledToggleButtonForSubjects key={subject} value={subject} sx={{ width: "100%" }}>
                      <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
                        {subject}
                      </Typography>
                    </StyledToggleButtonForSubjects>
                  ))}
                </ToggleButtonGroup>
              </Box>

              {subjects.length > 2 && (
                <IconButton onClick={handleScrollRight} disabled={visibleSubjects[1] === subjects.length - 1}>
                  <ArrowForwardIos />
                </IconButton>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            flexWrap: "wrap",
            px: "1.5rem"
          }}
        >
          {classAnalysisData.map((stat, index) => (
            <Box
              key={index}
              sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "0.5rem"
              }}
            >
              <Typography color="text.secondary" fontSize="14px">
                {stat.name}
              </Typography>
              <Typography color="text.primary" fontWeight="medium" fontSize="32px">
                {stat.value}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box px="1.5rem" display="flex" flexDirection="column" gap="1.5rem">
          <SectionAnalysis
            subjectAlignment={subjectAlignment}
            sectionAnalysisData={analysis?.syllabusAnalysis?.sectionAnalysis}
            syllabusData={syllabus}
          />
        </Box>

        <Box px="1.5rem">
          <ChapterAnalysis
            chapterAnalysisData={analysis?.syllabusAnalysis?.chapterAnalysis}
            sectionAnalysisData={analysis?.syllabusAnalysis?.sectionAnalysis}
            syllabusData={syllabus}
            subjectAlignment={subjectAlignment}
          />
        </Box>

        <Box px="1.5rem">
          <DifficultyAnalysis studentAnalysisDetails={difficultyAnalysisData} />
        </Box>
      </Box>
    </Box>
  );
};

export default DetailedAnalysis;
