import React, { useState } from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetUpcomingMeetingsQuery } from "../../../redux/api/meetManagerSlice";

const UpcomingExams = () => {
  const { user } = useSelector(state => state.auth);
  const [selectedDates, setSelectedDates] = useState([]);

  const { data: upcomingMeetings, isSuccess: upcomingMeetingsSuccess } = useGetUpcomingMeetingsQuery(user._id);

  // Extract meeting dates in "YYYY-MM-DD" format
  const meetingDates =
    upcomingMeetings?.meetings?.map(meeting => dayjs(meeting.meeting_time).format("YYYY-MM-DD")) || [];

  return (
    <Box display="flex" gap="18px">
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        width="100%"
        bgcolor="#ffffff"
        padding="1.5rem"
        borderRadius="1rem"
        border="1px solid #e6edff"
        aria-label="Upcoming Exams Container"
        gap="14px"
      >
        <Button
          type="button"
          variant="text"
          LinkComponent={Link}
          to="/view-batch"
          className="absolute top-[100%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[0.5rem] h-[26px] border-solid border-[#E6EDFF] border-[1px] text-[#3267ff] cursor-pointer"
        >
          V<span className="lowercase">iew all</span>
        </Button>
        <Box>
          <Typography fontSize="20px" margin="0" fontWeight="medium">
            Upcoming Events
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="1rem">
          {upcomingMeetingsSuccess &&
            upcomingMeetings?.meetings?.slice(0, 4).map((meeting, index) => {
              const meetingTime = meeting?.meeting_time || "TBD";
              let formattedTime = { date: 0, month: "jan" } || "TBD";
              let time = "";

              if (meetingTime !== "TBD") {
                const dateObject = new Date(meetingTime);
                const date = dateObject.getUTCDate();
                const monthIndex = dateObject.getUTCMonth();
                const istOffset = 5.5 * 60 * 60 * 1000;
                const istDate = new Date(dateObject.getTime() + istOffset);

                let hours = istDate.getUTCHours();
                const minutes = String(istDate.getUTCMinutes()).padStart(2, "0");
                const ampm = hours >= 12 ? "pm" : "am";
                hours %= 12;
                hours = hours !== "0" ? hours : 12;

                time = `${String(hours).padStart(2, "0")}:${minutes} ${ampm} IST`;

                const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
                const month = monthNames[monthIndex];

                formattedTime = { date: date, month: month };
              } else {
                formattedTime = "TBD";
                time = "TBD";
              }

              return (
                <Box key={index} display="flex" flexDirection="column" gap="12px">
                  <Box display="flex" flexDirection="row" gap={2}>
                    <Box bgcolor="#eaeaea" color="#3267FF" textAlign="center" p="1rem" borderRadius="0.25rem">
                      {formattedTime === "TBD" ? (
                        "TBD"
                      ) : (
                        <>
                          <strong className="m-0 text-[16px]">{formattedTime.date}</strong>
                          <div className="-mt-2">{formattedTime.month}</div>
                        </>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1} justifyContent="center">
                      <Typography fontWeight="medium" fontSize="17px">
                        {meeting.meeting_name}
                      </Typography>
                      <Box display="flex" gap="0.5rem">
                        <Box display="flex" alignItems="center" gap="3px">
                          <CalendarMonthIcon color="#3d3d3d" fontSize="14px" />
                          <Typography>{time}</Typography>
                        </Box>
                        <Box height="4px" width="4px" borderRadius="10rem" bgcolor="#696969" alignSelf="center" />
                        <Typography color="#8B8B8B">{meeting.creator?.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  {index !== 3 && index !== upcomingMeetings.meetings.length - 1 && (
                    <Divider orientation="horizontal" flexItem />
                  )}
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        width="100%"
        bgcolor="#ffffff"
        pt="15px"
        pl="0px" // Adjust padding left to 0
        pr="0px" // Adjust padding right to 0
        borderRadius="1rem"
        border="1px solid #e6edff"
        overflow="visible"
        aria-label="Upcoming Exams Container"
      >
        {upcomingMeetingsSuccess && (
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={dayjs()} // Set the current date
            dayOfWeekFormatter={day => dayjs(day).format("ddd")}
            renderInput={params => <TextField {...params} fullWidth />} // Ensure TextField is full width
            readOnly
            renderDay={(day, selectedDates, pickersDayProps) => {
              const formattedDate = dayjs(day).format("YYYY-MM-DD");
              let backgroundColor = "transparent";
              let color = "#000";
              console.log("meetingDates", meetingDates, "formattedDate: ", formattedDate);

              if (meetingDates.includes(formattedDate)) {
                console.log("here");
                backgroundColor = "#9c27b0"; // Purple for meeting dates
                color = "#fff";
              } else if (formattedDate === dayjs().format("YYYY-MM-DD")) {
                backgroundColor = "#4caf50"; // Green for today
                color = "#fff";
              }

              return (
                <div
                  {...pickersDayProps}
                  style={{
                    ...pickersDayProps.style,
                    backgroundColor,
                    color,
                    borderRadius: "0.25rem",
                    width: "70%",
                    textAlign: "center"
                  }}
                >
                  {day.format("D")}
                </div>
              );
            }}
            sx={{
              "borderRadius": "2rem",
              "& .MuiPickersCalendarHeader-label": {
                fontSize: "1.2rem"
              },
              "width": "100%", // Set StaticDatePicker width to 100%
              "& .MuiPickersDay-root": {
                "borderRadius": "0.25rem",
                "width": "70%",
                "&.Mui-selected": {
                  "backgroundColor": "#4caf50", // Ensure selected date stays green
                  "color": "#fff", // White text on selected dates
                  "&:hover": {
                    border: "1px solid #4caf50", // Highlight selected date with green border
                    bgcolor: "#fff",
                    color: "#4caf50"
                  }
                },
                "&.MuiPickersDay-today": {
                  border: "1px solid #4caf50", // Highlight today's date with green border
                  color: "#000000"
                },
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.15)" // Light green hover for better visibility
                }
              },
              "& .MuiDateCalendar-root": {
                width: "100%",
                borderRadius: "2rem"
              },
              "& .MuiDayCalendar-weekDayLabel": {
                fontSize: "14px",
                width: "100%"
              },
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
                width: "100%"
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default UpcomingExams;
