import { Box, Button, Typography } from "@mui/material";
import { ArrowUpRight, ChartLine } from "@phosphor-icons/react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAnalysisOfAnExamWithRangeForFacultyQuery } from "../../../redux/api/analysisSlice";
import { useGetEntireSyllabusQuery } from "../../../redux/api/syllabusSlice";

import DifficultyAnalysisGraph from "./Graphs/DifficultyAnalysisGraph";
import OverAllAnalysisGraph from "./Graphs/OverAllAnalysisGraph";
import SubjectAnalysisGraph from "./Graphs/SubjectAnalysisGraph";
import DifficultyAnalysis from "./DifficultyAnalysis";

const OverAllStats = () => {
  const { user } = useSelector(state => state.auth);
  const { analysisId } = useParams();
  const navigate = useNavigate();

  let correctTotals = {
    applying_questions: 0,
    creating_question: 0,
    evaluating_and_analysing: 0,
    remembering: 0,
    understanding_questions: 0
  };

  const {
    data: examDataInRange,
    isSuccess: isExamDataInRangeSuccess,
    isLoading: isExamDataInRangeLoading
  } = useGetAnalysisOfAnExamWithRangeForFacultyQuery({
    school_id: user.school_id,
    analysis_id: analysisId
  });

  console.log("examDataInRange", examDataInRange);

  const { data: syllabusData, isSuccess: isSyllabusSuccess } = useGetEntireSyllabusQuery(user?.school_id);

  const navigateToStudentRankPage = () => {
    navigate(`/Student-Leaderboard/${examDataInRange?.examAnalysis?.exam_id}`, {
      state: { examAnalysis: examDataInRange }
    });
  };

  const navigateToDetailedAnalysisPage = () => {
    navigate(`/detailed-Analysis/${examDataInRange?.examAnalysis?.exam_id}`, {
      state: { analysis: examDataInRange, syllabus: syllabusData }
    });
  };

  if (isExamDataInRangeLoading) {
    return <Box>Loading...</Box>;
  } else if (!isExamDataInRangeSuccess) {
    return <Box>Error</Box>;
  }

  // const RankingIcon = ({props}) => (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 24 24"
  //     width={24}
  //     height={24}
  //     color={"#000000"}
  //     fill={"none"}
  //     {...props}
  //   >
  //     <path
  //       d="M3.5 18C3.5 16.5858 3.5 15.8787 3.93934 15.4393C4.37868 15 5.08579 15 6.5 15H7C7.94281 15 8.41421 15 8.70711 15.2929C9 15.5858 9 16.0572 9 17V22H3.5V18Z"
  //       stroke="currentColor"
  //       strokeWidth="1.5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //     <path
  //       d="M15 19C15 18.0572 15 17.5858 15.2929 17.2929C15.5858 17 16.0572 17 17 17H17.5C18.9142 17 19.6213 17 20.0607 17.4393C20.5 17.8787 20.5 18.5858 20.5 20V22H15V19Z"
  //       stroke="currentColor"
  //       strokeWidth="1.5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //     <path d="M2 22H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  //     <path
  //       d="M9 16C9 14.5858 9 13.8787 9.43934 13.4393C9.87868 13 10.5858 13 12 13C13.4142 13 14.1213 13 14.5607 13.4393C15 13.8787 15 14.5858 15 16V22H9V16Z"
  //       stroke="currentColor"
  //       strokeWidth="1.5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //     <path
  //       d="M12.6911 2.57767L13.395 3.99715C13.491 4.19475 13.7469 4.38428 13.9629 4.42057L15.2388 4.6343C16.0547 4.77141 16.2467 5.36824 15.6587 5.957L14.6668 6.95709C14.4989 7.12646 14.4069 7.4531 14.4589 7.68699L14.7428 8.925C14.9668 9.90492 14.4509 10.284 13.591 9.77185L12.3951 9.05808C12.1791 8.92903 11.8232 8.92903 11.6032 9.05808L10.4073 9.77185C9.5514 10.284 9.03146 9.90089 9.25543 8.925L9.5394 7.68699C9.5914 7.4531 9.49941 7.12646 9.33143 6.95709L8.33954 5.957C7.7556 5.36824 7.94358 4.77141 8.75949 4.6343L10.0353 4.42057C10.2473 4.38428 10.5033 4.19475 10.5993 3.99715L11.3032 2.57767C11.6872 1.80744 12.3111 1.80744 12.6911 2.57767Z"
  //       stroke="currentColor"
  //       strokeWidth="1.5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     />
  //   </svg>
  // );

  console.log("examDataInRange: ", examDataInRange.syllabusAnalysis);
  // Iterate over each subject in subjectAnalysis
  const subjects = examDataInRange?.syllabusAnalysis?.subjectAnalysis;
  if (subjects) {
    Object.keys(subjects).forEach(subject => {
      const data = subjects[subject];
      // Iterate over each category in correctTotals
      Object.keys(correctTotals).forEach(category => {
        correctTotals[category] += data[category].correct;
      });
    });
  }

  console.log("correctTotals: ", correctTotals);

  const examForDetailedAnalysis = {
    applying_questions: correctTotals.applying_questions,
    creating_question: correctTotals.creating_question,
    evaluating_and_analysing: correctTotals.evaluating_and_analysing,
    remembering: correctTotals.remembering,
    understanding_questions: correctTotals.understanding_questions
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={4}>
      <Box width="100%" display="flex" flexDirection="column" gap={2}>
        {isExamDataInRangeSuccess && examDataInRange?.examAnalysis ? (
          <Box>
            <OverAllAnalysisGraph examAnalysis={examDataInRange.examAnalysis} />
            {/* Render your analysis details here if needed */}
          </Box>
        ) : (
          <Box width="100%" fontWeight="bold" display="flex" gap="0.25rem">
            <Typography color="#154FEF" fontWeight="bold">
              Analysis
            </Typography>
            <Typography fontWeight="bold">Not Found</Typography>
          </Box>
        )}
        <Box>
          {isExamDataInRangeSuccess && (
            <SubjectAnalysisGraph
              examData={examDataInRange.syllabusAnalysis}
              totalStudents={examDataInRange.totalStudents}
              dataForSuperInsights={examDataInRange.examAnalysis}
            />
          )}
        </Box>
        <Box>
          <DifficultyAnalysis
            studentAnalysisDetails={[
              { subject: "Analysing and Evaluating", A: examForDetailedAnalysis.evaluating_and_analysing },
              { subject: "Creating", A: examForDetailedAnalysis.creating_question },
              { subject: "Remembering", A: examForDetailedAnalysis.remembering },
              { subject: "Understanding", A: examForDetailedAnalysis.understanding_questions },
              { subject: "Applying", A: examForDetailedAnalysis.applying_questions }
            ]}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <Box
          display="flex"
          flexDirection="column"
          gap="2rem"
          bgcolor="#ffffff"
          border="1px solid #e4ebfc"
          p="1.5rem"
          borderRadius="0.5rem"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <ChartLine size={32} />{" "}
            <Button onClick={navigateToDetailedAnalysisPage}>
              <ArrowUpRight size={32} />
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="1rem">
            <Typography variant="h5" fontSize="18px" fontWeight="medium">
              Detailed Analysis
            </Typography>
            <Typography variant="h5" fontSize="18px" color="#4F4E69">
              Detailed analysis of Subject, Section and chapter of the test will be shown here
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="2rem"
          bgcolor="#ffffff"
          border="1px solid #e4ebfc"
          p="1.5rem"
          borderRadius="0.5rem"
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <ChartLine size={32} />{" "}
            <Button onClick={navigateToStudentRankPage}>
              <ArrowUpRight size={32} />
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="1rem">
            <Typography variant="h5" fontSize="18px" fontWeight="medium">
              Leaderboard
            </Typography>
            <Typography variant="h5" fontSize="18px" color="#4F4E69">
              List of students appeared in the exam sorted by rank can be found here
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OverAllStats;
