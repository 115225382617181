import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { styled } from "@mui/material/styles";

const SuperInsightsForSubjectAnalysisExam = ({ examDataForInsights }) => {
  // Handle the edge case when no exams are available
  if (!examDataForInsights || examDataForInsights.length === 0) {
    return (
      <Box display={"flex"} bgcolor="#ffffff" border={"1px solid #E6EDFF"} borderRadius={"0.5rem"} p="0.5rem 0.5rem">
        <Typography variant="body1">
          Hey! No specific insights right now, but remember: the topper always sticks to the basics.
        </Typography>
      </Box>
    );
  }

  const latestExam = examDataForInsights[0];
  const previousExam = examDataForInsights[1];

  const examType = latestExam?.exam_for;

  // Determine subjects to compare based on exam type
  const subjectsToShow =
    examType === "NEET"
      ? ["PHYSICS", "CHEMISTRY", "BIOLOGY"]
      : examType === "JEE"
        ? ["PHYSICS", "CHEMISTRY", "MATHEMATICS"]
        : examType === "KCET"
          ? ["PHYSICS", "CHEMISTRY", "MATHEMATICS", "BIOLOGY"]
          : [];

  const compareSubjects = subject => {
    const latestScore = latestExam.subjectAnalysis?.[subject] || { correct: 0, wrong: 0, total: 0 };
    const previousScore = previousExam?.subjectAnalysis?.[subject] || { correct: 0, wrong: 0, total: 0 };

    const correctDiff = latestScore.correct - previousScore.correct;
    const classAverageDiff =
      (latestScore.correct / latestScore.total) * 100 - (previousScore.correct / previousScore.total) * 100;

    return {
      subject,
      correctDiff,
      classAverageDiff
    };
  };

  const insights = subjectsToShow.map(subject => {
    const { correctDiff, classAverageDiff } = compareSubjects(subject);
    return (
      <Card
        key={subject}
        sx={{
          display: "flex",
          border: "1px solid #E6EDFF",
          borderRadius: "0.5rem",
          alignItems: "flex-start",
          width: "100%"
        }}
      >
        <CardContent sx={{ display: "flex", alignItems: "flex-start" }}>
          {/* SVG Icon */}
          <Box sx={{ color: "#3267FF" }}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Box marginLeft="12px" display="flex" flexDirection="column">
            <Typography variant="h6" color="#3267FF" fontWeight="medium">
              Super Insights
            </Typography>
            <Typography variant="body1">
              Hey! Your {subject} score has {correctDiff > 0 ? "gained" : "dipped"} by {Math.abs(correctDiff)} points
              from past exams and {classAverageDiff > 0 ? "gained" : "dipped"} {Math.abs(classAverageDiff.toFixed(2))}%
              compared to the class average over the last exams.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  });

  // Styled Box for hiding and showing arrows on hover
  const CarouselContainer = styled(Box)(({ theme }) => ({
    "position": "relative",
    "width": "100%", // Ensure carousel takes full width
    "&:hover .MuiButtonBase-root": {
      opacity: 1 // Make arrows visible on hover
    },
    ".MuiButtonBase-root": {
      opacity: 0, // Hide arrows by default
      transition: "opacity 0.3s ease"
    }
  }));

  return (
    <Carousel
      sx={{
        "boxShadow": "none",
        "border": "none",
        "width": "100%", // Ensure carousel takes full width
        "& .MuiPaper-root": {
          // Remove shadow from the carousel cards themselves
          boxShadow: "none"
        }
      }}
      autoPlay={false} // Disable auto-play
      indicators={true} // Enable navigation dots
      navButtonsAlwaysVisible={false} // Hide navigation buttons by default (only on hover)
      indicatorIconButtonProps={{
        style: {
          color: "#D9D9D9" // Grey color for inactive dots
        }
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: "#0044CC" // Darker blue color for active dot
        }
      }}
      navButtonsProps={{
        style: {
          backgroundColor: "transparent", // Transparent background for the arrow buttons
          boxShadow: "none" // Remove any shadow from navigation buttons
        }
      }}
      animation="slide" // Slide animation
    >
      {insights}
    </Carousel>
  );
};

export default SuperInsightsForSubjectAnalysisExam;
