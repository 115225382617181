import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { BatchCover1 } from "../../../Assets";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";

const BatchCarousel = ({ individualBatch }) => {
  const items = [
    {
      id: 1,
      name: "Name",
      description: individualBatch?.batch_name
    },
    {
      id: 2,
      name: "Type of Batch",
      description: individualBatch?.batch_tag
    },
    {
      id: 3,
      name: "No. of students",
      description: individualBatch?.students?.length
    },
    {
      id: 4,
      name: "No. of faculty",
      description: individualBatch?.faculties?.length
    }
  ];

  return (
    <Box marginTop="1rem" marginBottom="1rem" color="#fff">
      <Carousel
        autoPlay="true"
        animation="slide"
        loop
        duration="2000"
        interval="5000"
        activeIndicatorIconButtonProps={{
          style: {
            color: "#3267ff"
          }
        }}
      >
        {items.map(item => (
          <Box
            key={item.id}
            display="flex"
            flexDirection="column"
            gap="0.5rem"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h4" color="black" fontWeight={300} textAlign="center">
              {item.name}
            </Typography>
            <Typography
              variant="h3"
              color={item.name === "Type of Batch" ? "#3267ff" : "black"}
              fontWeight={600}
              textAlign="center"
            >
              {item.description}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

const BatchStats = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);

  const { data: students } = useGetStudentsQuery(user.school_id);
  const { data: faculties } = useGetFacultiesQuery(user.school_id);

  const { batch: individualBatch } = useSelector(state => state.batch);
  const { batchUserDetails } = useSelector(state => state.batch);

  return (
    <>
      <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" position="relative">
        <Box
          sx={{
            width: "100%",
            height: "70px",
            position: "relative",
            overflow: "hidden",
            marginBottom: "10px"
          }}
        >
          <Box
            sx={{
              width: "100%",
              paddingTop: "20.25%", // 16:9 aspect ratio
              position: "relative"
            }}
          >
            <img
              src={BatchCover1}
              alt="Batch Cover"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                objectFit: "cover",
                borderRadius: "1rem"
              }}
            />
          </Box>
        </Box>
        {isNonMobile && (
          <Box
            width="100%"
            overflow="auto"
            p="1rem"
            display={isNonMobile ? "grid" : "flex"}
            justifyContent="space-between"
            gridTemplateColumns={!isNonMobile ? "" : "repeat(auto-fit, minmax(250px, 1fr))"}
            gap="1rem"
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
              <Typography variant="h6" fontWeight={400}>
                Name
              </Typography>
              <Typography variant={isNonMobile ? "h3" : "h4"} fontWeight={500}>
                {individualBatch?.batch_name}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
              <Typography variant="h6" textAlign={"center"} fontWeight={400}>
                Type of batch
              </Typography>
              <Typography variant="h3" textAlign={"center"} fontWeight={500} color="#3267FF">
                {individualBatch?.batch_tag}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
              <Typography variant="h6" textAlign={"center"} fontWeight={400}>
                Number of students
              </Typography>
              <Typography variant="h3" fontWeight={500}>
                {individualBatch?.students?.length}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="4px">
              <Typography variant="h6" textAlign={"center"} fontWeight={400}>
                Number of faculties
              </Typography>
              <Typography variant="h3" fontWeight={500}>
                {individualBatch?.faculties?.length}
              </Typography>
            </Box>
          </Box>
        )}

        {!isNonMobile && (
          <Box width="90%">
            <BatchCarousel individualBatch={individualBatch} />
          </Box>
        )}

        {/* <IndividualBatchTable /> */}
      </Box>
      <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" position="relative" p="1rem">
        <Typography variant="h4" fontWeight={500} mb="8px">
          Batch Description
        </Typography>
        {individualBatch?.batch_description}
      </Box>
    </>
  );
};

export default BatchStats;
