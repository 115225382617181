import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

import SubjectAnalysisSuperInsightsForTestAnalysis from "../SuperInsights/SubjectAnalysisSuperInsights";

const SubjectAnalysisGraph = ({
  examData,
  totalStudents,
  dataForSuperInsights,
  mainColors = ["#71C481", "#789BFF"]
}) => {
  const numberSection = [0, 20, 40, 60, 80, 100];

  const VerticalLine = ({ left }) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: `${left}`,
        width: "1px",
        borderLeft: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const HorizontalLine = ({ top }) => (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: `${top}`,
        height: "1px",
        borderTop: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const GraphScaleDisplay = () => (
    <div className="bg-red-500 h-[100%]">
      {numberSection
        .slice()
        .reverse()
        .map((item, index) => (
          <div
            className="text-end"
            key={index}
            style={{
              position: "absolute",
              top: `${100 - item}%`,
              transform: "translateY(-50%)",
              left: -30
            }}
          >
            {item}
          </div>
        ))}
    </div>
  );

  const BackgroundBoxOfGraph = ({ color }) => (
    <div
      style={{
        flex: 1,
        backgroundColor: color,
        width: "50%",
        opacity: 0.3
      }}
    />
  );

  const MainBar = ({ color, StudentMarks }) => {
    const [height, setHeight] = useState(0); // Start with 0 height

    useEffect(() => {
      const timer = setTimeout(() => {
        setHeight(StudentMarks); // Set to actual percentage after a short delay
      }, 1000); // Delay for triggering animation
      return () => clearTimeout(timer);
    }, [StudentMarks]);

    return (
      <div
        className={`absolute flex w-1/2 bottom-0 justify-center font-semibold
          ${color === "#000000" || color === "#000" ? "text-white" : "text-black"}`}
        style={{
          height: `${height}%`, // This will animate the bar height
          backgroundColor: color,
          borderTopRightRadius: "0.75rem",
          borderTopLeftRadius: "0.5rem",
          transition: "height 0.7s ease-in-out, box-shadow 0.3s ease-in-out", // Added transition for box-shadow
          boxShadow: "inset 0 0 0 0 rgba(0, 0, 0, 0)" // Default no shadow
        }}
        onMouseEnter={e => {
          e.currentTarget.style.boxShadow = "inset 0 4px 8px rgba(0, 0, 0, 0.2)"; // Add shadow
          const textElement = e.currentTarget.querySelector(".text-to-scale"); // Target the text
          if (textElement) {
            textElement.style.transform = "scale(1.05)"; // Scale text to 105%
          }
        }}
        onMouseLeave={e => {
          e.currentTarget.style.boxShadow = "inset 0 0 0 0 rgba(0, 0, 0, 0)"; // Remove shadow
          const textElement = e.currentTarget.querySelector(".text-to-scale"); // Target the text
          if (textElement) {
            textElement.style.transform = "scale(1)"; // Reset text scale to 100%
          }
        }}
      >
        <Box
          className={`transition-transform duration-300 ease-in-out text-to-scale ${
            StudentMarks <= 10 ? "absolute top-[-1rem]" : "static"
          }`}
          style={{
            transform: "scale(1)", // Default text scale
            transition: "transform 0.3s ease-in-out" // Text scale transition
          }}
        >
          {StudentMarks}%
        </Box>
      </div>
    );
  };

  console.log("examDataInFac: ", examData);

  if (!examData?.subjectAnalysis || Object.keys(examData?.subjectAnalysis).length === 0) {
    return (
      <Box height="100%" width="100%" display="flex" textAlign="center">
        No Exams To display
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        p="1.5rem"
        bgcolor="#ffffff"
        borderRadius="0.5rem"
        border="1px solid #e4ebfc"
      >
        <Typography variant="h3" fontWeight="normal" fontSize="24px">
          Subject Wise Analysis
        </Typography>
        <Box display="flex" flexDirection="row" width="100%">
          <Box p="1rem 2rem" position="relative" width="70%">
            <Box
              position="relative"
              minHeight="40vh"
              width="100%"
              bgcolor="#fff"
              border="1px dashed #e3e3e3"
              borderBottom="1px solid #000"
            >
              {examData?.subjectAnalysis &&
                Object.keys(examData?.subjectAnalysis).map((item, index) => (
                  <VerticalLine
                    key={index}
                    left={`${(index + 1) * (100 / (Object.keys(examData?.subjectAnalysis).length + 1))}%`}
                  />
                ))}

              <HorizontalLine top="20%" />
              <HorizontalLine top="40%" />
              <HorizontalLine top="60%" />
              <HorizontalLine top="80%" />

              <GraphScaleDisplay />

              <Box
                display="flex"
                justifyContent="space-evenly"
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
              >
                {examData?.subjectAnalysis &&
                  Object.entries(examData?.subjectAnalysis).map(([subject, data], index) => {
                    let studentMarks = data?.total === 0 ? 0 : ((data.correct / data.total) * 100).toFixed(2) || 1;
                    return (
                      <Box
                        key={index}
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        position="relative"
                      >
                        <BackgroundBoxOfGraph color={mainColors[index % mainColors.length]} />
                        <MainBar color={mainColors[index % mainColors.length]} StudentMarks={studentMarks} />
                        <Box position="absolute" bottom="-2rem">
                          {subject}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="center" gap="2rem" alignItems="center" mt="5rem">
              <Box display="flex" flexDirection="row" justifyContent="center" gap="8px" alignItems="center">
                {mainColors.map((color, index) => (
                  <Box key={index} width="0.5rem" height="0.5rem" bgcolor={color} />
                ))}
                <Box>Class Average</Box>
              </Box>
            </Box>
          </Box>
          {/**How analysis works */}
          <Box
            display="flex"
            alignSelf="flex-start"
            height="auto"
            flexDirection="column"
            bgcolor="#FAFAFB"
            borderRadius="0.5rem"
            gap="4px"
            width="30%"
            p="12px 8px"
          >
            <Typography variant="h5" fontSize="18px">
              How Subject Analysis Works?
            </Typography>
            <Typography variant="body1" color="#4F4E69" fontSize="13px">
              ScaleYou’s algorithms create a subject-wise analysis by averaging your performance across tests, showing
              the percentage of your overall scores in each subject.{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <SubjectAnalysisSuperInsightsForTestAnalysis examDataForInsights={dataForSuperInsights} />
      </Box>
    </Box>
  );
};

export default SubjectAnalysisGraph;
