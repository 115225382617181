import { TextField, Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSaveApiKeyMutation, useGetAllSupervisorsQuery } from "../../../redux/api/adminSlice";
import CButton from "../../../components/ui/Button";

const ManageSupervisor = () => {
  const { user_id } = useParams();
  const theme = useTheme();

  const [supervisor, setSupervisor] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [saveApiKey, { isError, isLoading, isSuccess: saveApiKeySuccess }] = useSaveApiKeyMutation();

  const { data: supervisorsData, isSuccess } = useGetAllSupervisorsQuery();

  const handleSaveApiKey = () => {
    if (user_id && apiKey) {
      saveApiKey({ id: user_id, apiKey: apiKey });
    }
  };

  useEffect(() => {
    if (isSuccess && supervisorsData && supervisorsData?.supervisorUsers) {
      const supervisor = supervisorsData?.supervisorUsers?.find(user => user._id === user_id);
      setSupervisor(supervisor);
    }
  }, [supervisorsData, user_id]);

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Supervisor: {supervisor?.name}
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View and manage all supervisors in one place
          </Typography>
        </Box>
      </Box>
      {user_id && supervisor && (
        <>
          <Typography variant="h5" fontWeight={600}>
            Manage Examin8 API
          </Typography>
          <TextField
            label="Api Key"
            fullWidth
            type="text"
            value={apiKey}
            onChange={event => setApiKey(event.target.value)}
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "16px",
                backgroundColor: "#fff"
              },
              "& .MuiInputLabel-root": {
                fontSize: "15px"
              }
            }}
          />
          <Box width="100%" display="flex" justifyContent="flex-end">
            <CButton buttonText="Save" variant="primary" onClickFun={handleSaveApiKey} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ManageSupervisor;
