import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import TicketCreator from "../../../components/Supervisor/Ticket/TicketCreator";
import TicketDisplay from "../../../components/Supervisor/Ticket/TicketDisplay";
import CButton from "../../../components/ui/Button";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const RaiseTicket = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const [openCreateIssue, setOpenCreateIssue] = useState(false);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Raise Ticket", link: "" }
  ];

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between" flexDirection={isNonMobile ? "row" : "column"}>
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Raise Ticket
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Create and view your issue tickets here
          </Typography>
        </Box>
        <Box>
          <CButton buttonText="Create Ticket" variant="primary" onClickFun={() => setOpenCreateIssue(true)} />
        </Box>
      </Box>
      <TicketCreator openCreateIssue={openCreateIssue} setOpenCreateIssue={setOpenCreateIssue} />
      <TicketDisplay />
    </Box>
  );
};

export default RaiseTicket;
