import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CaretDown, CheckCircle } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllFacultyAnalysisQuery } from "../../../redux/api/analysisSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const TestHistoryTeacher = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();

  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllFacultyAnalysisQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  useEffect(() => {
    document.title = "Insights | Test History";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "" }
  ];

  return (
    <Box width="100%" p="2rem" bgcolor="rgb(250,250,251,1)" height="91vh">
      <div style={{ padding: "1rem 0", marginTop: "-2rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box width="100%" display="flex" flexDirection="column">
        <Box>
          <Box
            width="100%"
            display="flex"
            flexDirection={isNonMobile ? "row" : "column"}
            justifyContent="space-between"
            alignItems={isNonMobile ? "center" : "start"}
            gap="1rem"
          >
            <Box>
              <Typography variant="h2" fontWeight={600}>
                Test History
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.light}>
                View all the tests conducted
              </Typography>
            </Box>
            <Box display="flex" gap="1rem" alignItems="center">
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3A974C" }} />{" "}
                Completed
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#F48C06" }} />{" "}
                Incomplete
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3267FF" }} />{" "}
                Analysing
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <span style={{ fontWeight: 500 }}>NAN</span>: Not analysed
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "85vh", // Set a maximum height for the scrollable container
            overflowY: "auto", // Enable vertical scrolling
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling if needed
            maxWidth: "100%"
          }}
        >
          <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "8%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Sl. No
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Exam Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Date
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Exam For
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Students Appeared
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Highest Score/Average Score
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "95%",
                    textAlign: "center",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Status
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isGetAnalysisSuccess ? (
                analysisArray?.analysisArray?.length ? (
                  analysisArray?.analysisArray?.map((analysis, index) => (
                    <tr
                      key={analysis.exam_unique_code}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        borderSpacing: "1rem",
                        cursor: "pointer", // Ensure the cursor is always a pointer
                        transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                      }}
                      onClick={() => navigate(`/analyse-exam/${analysis._id}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td
                        style={{
                          width: "8%",
                          gap: "8px",
                          height: "4rem",
                          borderLeft: "5px solid  #3A974C",
                          borderRadius: "0.5rem"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.exam_name}</td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.exam_date}</td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.exam_for}</td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.total_students}</td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {`${Math.floor(analysis.highest_score)}/${Math.floor(analysis.average_score)}`}
                      </td>
                      <td style={{ width: "95%", gap: "8px", height: "4rem", textAlign: "center" }}>
                        <Box display="flex" gap="10px" width="80%" margin="auto" alignItems="center">
                          <CheckCircle size={22} weight="fill" color="#3A974C" /> View Analysis
                        </Box>
                      </td>
                    </tr>
                  ))
                ) : (
                  <Box sx={{ alignSelf: "center" }}>No Analysis Found</Box>
                )
              ) : (
                <Box sx={{ alignSelf: "center" }}>No Analysis Found</Box>
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default TestHistoryTeacher;
