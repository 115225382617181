// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// internal modules
import TotalStats from "../../../components/Teacher/Dashboard/Totalstats";
import TestHistoryTeacher from "../../../components/Teacher/TestHistory/test";
import OverallAnalysisTeacher from "../../../components/Teacher/Dashboard/OverallAnalysis";
import InsightsDashaBoardAnalysisForFaculty from "../../../components/Teacher/Dashboard/InsightsAnalysis";
import UpcomingExams from "../../../components/Teacher/Dashboard/UpcomingExams";
import SubjectWiseAnalysis from "../../../components/Teacher/Dashboard/SubjectAnalysisForTeacher";

import BoardResultsBanner from "../../../components/Teacher/Dashboard/BoardResultsBanner";
import TeacherBanner from "../../../components/Teacher/Dashboard/TeacherBanner";
import DashboardDisplay from "../../../components/Teacher/ManageBatch/DashboardDisplay";
import BatchCover2 from "../../../Assets/batch-cover-2.png";

import { useGetAllFacultyAnalysisQuery } from "../../../redux/api/analysisSlice";
import { useGetStudentResultByStudentIdQuery } from "../../../redux/api/resultsSlice";
import { useGetBatchesByStudentQuery, useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";

const TeacherDashboard = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const [batchNames, setbatchNames] = useState([]);
  const { data: analysisData, isSuccess: isAnalysisSuccess } = useGetAllFacultyAnalysisQuery({
    school_id: user?.school_id,
    faculty_id: user._id
  });

  const { data: batchesOfFaculty, isSuccess: isBatchSuccess } = useGetBatchesByFacultyQuery({
    faculty_id: user?._id,
    school_id: user?.school_id
  });

  useEffect(() => {
    if (isBatchSuccess && batchesOfFaculty?.batches?.length > 0) {
      const totalBatches = batchesOfFaculty.batches.map(batch => batch?.batch_name);
      setbatchNames(totalBatches);
    }
  }, [batchesOfFaculty, isBatchSuccess]);

  const [totalExams, setTotalExams] = useState([]);

  useEffect(() => {
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0) {
      analysisData.analysisArray.forEach(exam => {
        if (exam.exam_for) {
          if (exam.exam_for !== "undefined") uniqueExams.add(exam.exam_for);
        }
      });
      setTotalExams(Array.from(uniqueExams));
    }
  }, [analysisData]);

  // redux
  // render
  return (
    <main>
      <Box
        padding={isNonMobile ? "1rem 2rem" : "1rem 1.25rem"}
        display="flex"
        flexDirection="column"
        gap="1rem"
        bgcolor="rgb(250,250,251,1)"
        width="100%"
        height="100%"
      >
        <img src={BatchCover2} alt="banner" height="125px" width="100%" className="rounded-md" />

        <Box display="flex" gap="0.75rem" flexDirection="column">
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Welcome Back, {user.name}
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.primary.light}>
            Here you can get all the information of your institute
          </Typography>
        </Box>
        <TotalStats />
        <TeacherBanner />
        {isAnalysisSuccess && totalExams.length > 0 && batchNames.length > 0 && user && (
          <OverallAnalysisTeacher
            userData={user}
            analysisData={analysisData}
            totalExams={totalExams}
            batchNames={batchNames}
          />
        )}
        {user && <InsightsDashaBoardAnalysisForFaculty userData={user} />}
        {/* <LineGraph /> */}
        <UpcomingExams />
        <Box display={"flex"} gap={"2rem"}>
          {/* <TopPerformersTeacher /> */}
          <TestHistoryTeacher />
        </Box>
        <DashboardDisplay />
        <SubjectWiseAnalysis />
        <BoardResultsBanner />
      </Box>
    </main>
  );
};

// render
export default TeacherDashboard;
