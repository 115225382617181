import { Box, FormControl, Select, MenuItem, Chip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import AnalysisGraphs from "./AnalysisGraphs";

import SuperInsightsForSubjectAnalysisExam from "./SuperInsightsForDashboard/SuperInsightsForSubjectAnalysisExam";
import SuperInsightsForSubjectAnalysisBoard from "./SuperInsightsForDashboard/SuperInsightsForSubjectAnalysisBoards";

const SubjectAnalysis = ({ analysisData, boardData = {}, typeBoard = false }) => {
  const [examType, setExamType] = useState(analysisData?.analysisArray[0]?.exam_for);
  let [totalExams, setTotalExams] = useState([]);
  const [examData, setExamData] = useState([]);
  const [examDataForInsights, setExamDataForInsights] = useState([]);

  useEffect(() => {
    let uniqueExams = new Set();
    let latestExams = [];

    if (analysisData && analysisData.analysisArray.length > 0 && !typeBoard) {
      // Set exam type based on the first exam's type
      let firstExamType = analysisData.analysisArray[0].exam_for;
      setExamType(firstExamType);

      // Filter exams based on the first exam type
      setExamData(analysisData.analysisArray.filter(item => item.exam_for === firstExamType));

      // Collect unique exam types
      analysisData.analysisArray.forEach(exam => {
        if (exam.exam_for) uniqueExams.add(exam.exam_for);
      });
      setTotalExams(Array.from(uniqueExams));

      const clonedArray = [...analysisData.analysisArray];

      // Sort the exams by date and take the two most recent ones
      let sortedArray = clonedArray.sort((a, b) => new Date(b.exam_date) - new Date(a.exam_date)); // Sort by exam_date descending

      latestExams.push(sortedArray[0]);
      for (let i = 0; i < sortedArray.length; i++) {
        const item = sortedArray[i];
        if (item.exam_for === latestExams[0].exam_for) {
          latestExams.push(item);
          break;
        }
      }

      // Set the latest exams for insights
      setExamDataForInsights(latestExams);
    }

    if (boardData && boardData.result && typeBoard) {
      setExamData(boardData);
    }
  }, [analysisData]);

  const handleExamTypeChange = event => {
    setExamType(event.target.value);
    setExamData(analysisData.analysisArray.filter(item => item.exam_for === event.target.value));
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#fff"
      border="1px solid #E6EDFF"
      width="100%"
      height="100%"
      mt="1rem"
      p="1rem 1rem"
      borderRadius="0.5rem"
      position="relative"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box color="#3267FF">
          <h2 className="m-0 text-black font-medium">Subject Wise Analysis</h2>
          {examData?.length} Tests Till Now
        </Box>
        <Box>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="top" gap={4}>
            {!typeBoard && (
              <FormControl>
                <Select
                  value={examType}
                  onChange={handleExamTypeChange}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2rem",
                    width: "5.2rem",
                    borderRadius: "0.5rem",
                    bgcolor: "#fafafb",
                    borderColor: "#E6EDFF"
                  }}
                >
                  {totalExams.map((exam, index) => (
                    <MenuItem key={index} value={exam}>
                      {exam}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Chip
              sx={{
                bgcolor: typeBoard ? "#E1F1FF" : "#F3F1FF",
                color: typeBoard ? "#2E90FA" : "#875BF7",
                fontWeight: "bold"
              }}
              label={typeBoard ? "Boards Exam" : "Competitive Exams"}
            />
          </Box>
        </Box>
      </Box>
      {!typeBoard && (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={8} xl={8}>
            {examData.length !== 0 && <AnalysisGraphs examData={examData} />}
            {examData.length === 0 && (
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                You have not attempted any tests yet.
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <Box
              display="flex"
              flexDirection="column"
              gap="0.75rem"
              bgcolor="#fafafb"
              border="1px solid #E6EDFF"
              width="100%"
              height="50%"
              mt="1rem"
              p="1rem 1rem"
              borderRadius="0.5rem"
              position="relative"
            >
              <h3 className="m-0">How Subject Analysis Works?</h3>
              <p className="m-0 -mt-1 text-wrap text-start text-[#4F4E69]">
                Insights algorithms generate a detailed subject-wise analysis by averaging your performance across all
                tests and benchmarking it against the class average within your specific batch.
              </p>
            </Box>
          </Grid>
        </Grid>
      )}
      {typeBoard && (
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={12} xl={12}>
            {boardData.length !== 0 && <AnalysisGraphs examData={examData} />}
            {examData.length === 0 && (
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                You have not attempted any tests yet.
              </Box>
            )}
          </Grid>
        </Grid>
      )}

      {!typeBoard && (
        <Box>
          <SuperInsightsForSubjectAnalysisExam examDataForInsights={examDataForInsights} />
        </Box>
      )}
      {typeBoard && (
        <Box>
          <SuperInsightsForSubjectAnalysisBoard
            result={examData?.result?.results}
            previousResult={examData?.previousResult?.results}
          />
        </Box>
      )}
    </Box>
  );
};

export default SubjectAnalysis;
