import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import ScoreAnalysisGraph from "./GraphsComponent/ScoreAnalysisGraph";

const ScoreAnalysis = ({ scoreAnalysisData, mainColors = ["#71C481", "#789BFF"] }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#fff"
      border="1px solid #E6EDFF"
      width="100%"
      height="100%"
      mt="1rem"
      p="1rem 1rem"
      borderRadius="0.5rem"
      alignItems="start"
    >
      <Typography variant="h3" fontWeight="medium">
        Score Analysis
      </Typography>
      <ScoreAnalysisGraph scoreAnalysisData={scoreAnalysisData} />
    </Box>
  );
};

export default ScoreAnalysis;
