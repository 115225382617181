import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowLeft, Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import BatchManager from "../../../components/Supervisor/ManageBatch/BatchManager";
import CButton from "../../../components/ui/Button";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const ManageBatch = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Insights | Manage Batches";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "" }
  ];

  const [isManageOpen, setIsManageOpen] = useState(false);
  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 1rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Manage Batches</title>
        <meta name="description" content="Manage Batch of your Institution at ScaleYou Insights" />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between" flexDirection={isNonMobile ? "row" : "column"}>
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Batches
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            All batches in your institute
          </Typography>
        </Box>
        <Box>
          <CButton
            buttonText="Register Batches"
            variant="primary"
            icon={<Plus color="#fff" size={18} weight="bold" />}
            onClickFun={() => navigate("/create-batch")}
          />
        </Box>
      </Box>
      <BatchManager />
    </Box>
  );
};

export default ManageBatch;
