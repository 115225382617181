// inbuilt modules
import React from "react";

// external modules
import { useTheme } from "@emotion/react";
import { Box, Typography, styled, useMediaQuery } from "@mui/material";
import { ChalkboardTeacher, Exam, ToteSimple, Users } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useGetBatchesByStudentQuery } from "../../../redux/api/batchSlice";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";
import { useGetSchoolFacultyStatsQuery } from "../../../redux/api/performanceSlice";

//importing images
import AllBatchesImage from "../../../Assets/DashBoard/all-batches.svg";
import TotalBoardsResultImage from "../../../Assets/DashBoard/total-board-results.svg";
import TotalCompetitiveExamImage from "../../../Assets/DashBoard/total-competitve-exams.svg";
import TotalFacultiesImage from "../../../Assets/DashBoard/total-faculty.svg";
import TotalStudentsImage from "../../../Assets/DashBoard/total-students.svg";
// functions and variables
const HeroBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start", // Ensures items start at the top (vertical axis)
  justifyContent: "flex-start", // Aligns items to the start on the main axis (horizontal)
  gap: "8px",
  flexDirection: "column" // Ensures the label, value, and icon are arranged vertically
}));

const TotalStats = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // theme
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();
  const { data: students, isSuccess: isGetStudentSuccess } = useGetStudentsQuery(user.school_id);
  const { data: faculties, isSuccess: isGetFacultiesSuccess } = useGetFacultiesQuery(user.school_id);
  // const { data: exams, isSucess : isGetExamsSucce
  const { data: batches, isSuccess: isGetBatchesSuccess } = useGetBatchesByStudentQuery({
    schoolId: user.school_id,
    studentId: user._id
  });

  const heroBoxDetails = [
    {
      icon: <ChalkboardTeacher size={24} color={theme.palette.secondary.main} />,
      value: isGetFacultiesSuccess && faculties.faculties?.length,
      label: "Total Faculty"
    },
    {
      icon: <Users size={24} color={theme.palette.secondary.main} />,
      value: isGetStudentSuccess && students.students?.length,
      label: "Total Students"
    },
    {
      icon: <Exam size={24} color={theme.palette.secondary.main} />,
      value: 5,
      label: "Exams Created"
    },
    {
      icon: <ToteSimple size={24} color={theme.palette.secondary.main} />,
      value: isGetBatchesSuccess && batches?.batches?.length,
      label: "Total batch"
    }
  ];

  const { data: facultyStats, isSuccess: isGetStudentStatsSuccess } = useGetSchoolFacultyStatsQuery({
    faculty_id: user._id,
    school_id: user.school_id
  });

  const newHeroBoxDetails = [
    {
      icon: <img src={TotalFacultiesImage} alt="total-Faculties-image" />,
      value: isGetStudentStatsSuccess && facultyStats?.totalFaculty,
      difference: isGetBatchesSuccess && facultyStats?.difference?.faculty,
      label: "Total Faculty"
    },
    {
      icon: <img src={TotalStudentsImage} alt="total-Students-image" />,
      value: isGetStudentSuccess && students.students?.length,
      difference: isGetBatchesSuccess && facultyStats?.difference?.students,
      label: "Total Students"
    },
    {
      icon: <img src={TotalCompetitiveExamImage} alt="total-Competitive-image" />,
      value: isGetBatchesSuccess && facultyStats?.totalExamsGiven,
      difference: isGetBatchesSuccess && facultyStats?.difference?.examsGiven,
      label: "Exams Created"
    },
    {
      icon: <img src={AllBatchesImage} alt="All-Batches-image" />,
      value: isGetBatchesSuccess && facultyStats?.totalBatches,
      difference: null,
      label: "Total batch"
    },
    {
      icon: <img src={TotalBoardsResultImage} alt="total-Boards-image" />,
      value: isGetBatchesSuccess && batches?.batches?.length,
      difference: isGetBatchesSuccess && facultyStats?.totalBoardResults,
      label: "Total Board Result"
    }
  ];

  return (
    <Box>
      {/**New design starts from here */}
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
        {newHeroBoxDetails.map((box, index) => (
          <Box
            width="18%"
            bgcolor="#fff"
            borderRadius="0.5rem"
            border="1px solid #E6EDFF"
            p="0.75rem 0.75rem"
            display="flex"
            justifyContent="start"
          >
            <HeroBox key={box.label}>
              {box.icon}
              <Typography variant="subtitle2" color={theme.palette.primary.light}>
                {box.label}
              </Typography>
              <Box display="flex" gap="0.5rem" alignItems="end">
                <Typography variant="h2" fontWeight={500} fontSize="32px">
                  {box.value}
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} color="#3A974C">
                  {Number(box.difference) !== 0 ? (box.difference > 0 ? `+${box.difference}` : box.difference) : null}
                </Typography>
              </Box>
            </HeroBox>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

// render
export default TotalStats;
