import React from "react";

export const StudentStandingGraphs = ({ examAnalysis }) => {
  const examStats = [
    { label: "Exam Type", stat: examAnalysis.exam_for },
    { label: "Exam Name", stat: examAnalysis.exam_name },
    {
      label: "Text Taken On",
      year: examAnalysis.exam_date.split("-")[0],
      month: examAnalysis.exam_date.split("-")[1],
      day: examAnalysis.exam_date.split("-")[2]
    },
    { label: "Lowest Score", stat: examAnalysis.lowest_score },
    { label: "Average Score", stat: examAnalysis.average_score },
    { label: "Highest Score", stat: examAnalysis.highest_score }
  ];

  const barHeights = [
    30, 40, 50, 60, 65, 70, 73, 71, 72, 70, 65, 60, 55, 52, 45, 40, 35, 30, 25, 20, 15, 10, 7, 7, 7, 7, 7, 7, 7
  ];

  const numberSection = [0, 20, 40, 60, 80, 100];

  const GraphScaleDisplay = () => (
    <div className="absolute h-[100%]">
      {numberSection
        .slice()
        .reverse()
        .map((item, index) => (
          <div
            className="text-end"
            key={index}
            style={{
              position: "absolute",
              top: `${100 - item}%`,
              transform: "translateY(-50%)",
              left: -30
            }}
          >
            {item}
          </div>
        ))}
    </div>
  );

  const HorizontalLine = ({ top }) => (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: top,
        height: "1px",
        borderTop: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const GraphBars = ({ height, index, hasIndicator, indicatorLabel }) => (
    <div
      className="relative flex items-end justify-center"
      style={{
        height: "100%",
        width: "3%",
        margin: "0 4px",
        position: "relative"
      }}
    >
      {/* Vertical Line and Label for Low, Median, and High */}
      {hasIndicator && (
        <div
          className="absolute -top-1 left-1/2 transform -translate-x-1/2 flex flex-col items-center"
          style={{ width: "2px", height: "100%", border: "1px dashed #c7c7c7" }}
        >
          <span
            className="absolute px-2 py-1 text-center font-bold text-[9px] rounded-md bg-[#02010C] text-white"
            style={{ whiteSpace: "nowrap" }}
          >
            {indicatorLabel}
          </span>
        </div>
      )}
      {/* Bar with dynamic height */}
      <div
        style={{
          zIndex: "10",
          height: `${height}%`,
          background: "linear-gradient(180deg, #8979FF 0%, #524999 100%)",
          width: "70%",
          borderRadius: "2rem",
          border: "1px solid #c7c7c7",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
        }}
      />
    </div>
  );

  return (
    <div className="flex flex-col h-auto gap-4 w-[100%]">
      {/* Exam Stats Section */}
      <div className="flex flex-wrap gap-2 w-[100%]">
        {examStats.map((stat, index) => (
          <div
            key={index}
            className="flex flex-col flex-grow p-4 bg-white rounded-lg border-[1px] border-solid border-[#e6edff]"
          >
            <span className="text-[13px] font-normal text-[#949596]">{stat.label}</span>
            <span className="text-[28px] font-medium">{stat.stat || `${stat.day}/${stat.month}/${stat.year}`}</span>
          </div>
        ))}
      </div>

      {/* Graph Section */}
      <div className="flex flex-col h-[400px] px-[21px] py-[24px] gap-16 rounded-xl border-[1px] border-solid border-[#e6edff] bg-white">
        <h3 className="m-0 font-normal text-[24px]">OverAll Analysis</h3>
        <div className="relative w-full h-full pl-12">
          {/* Graph Grid and Bars */}
          <div className="relative h-full w-full border-[1px] border-dashed border-b-black border-b-solid border-[#e3e3e3]">
            {/* Horizontal Lines for Grid */}
            <HorizontalLine top="20%" />
            <HorizontalLine top="40%" />
            <HorizontalLine top="60%" />
            <HorizontalLine top="80%" />

            {/* Y-Axis Scale */}
            <GraphScaleDisplay />

            {/* Bars with Indicators */}
            <div className="flex px-2 h-full items-end justify-between mb-4">
              {barHeights.map((height, index) => (
                <GraphBars
                  key={index}
                  height={height}
                  index={index}
                  hasIndicator={index === 0 || index === 6 || index === barHeights.length - 1}
                  indicatorLabel={
                    index === 0 ? "Low" : index === 6 ? "Median" : index === barHeights.length - 1 ? "High" : null
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentStandingGraphs;
