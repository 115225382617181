import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, CardContent, Grid, InputBase, Menu, MenuItem, alpha, styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Funnel, SortAscending, Star } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BatchCover1, BatchCover2, BatchCover3, BatchCover4, BatchCover5, BatchCover6 } from "../../../Assets";
import {
  useGetBatchesByFacultyQuery,
  useGetUsersFavBatchesQuery,
  useSetFavBatchMutation
} from "../../../redux/api/batchSlice";

const Search = styled("div")(({ theme }) => ({
  "position": "relative",
  "borderRadius": theme.shape.borderRadius,
  "backgroundColor": alpha(theme.palette.common.black, 0.06),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03)
  },
  "marginLeft": 0,
  "width": "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "color": "inherit",
  "width": "100%",
  "fontSize": theme.typography.pxToRem(15),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "width": "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const a11yProps = index => {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
};

const StyledTabs = styled(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "minHeight": "15px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7"
  }
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
  "textTransform": "none",
  "fontWeight": theme.typography.fontWeightRegular,
  "fontSize": theme.typography.pxToRem(15),
  "color": "rgba(0, 0, 0, 0.4)",
  "minHeight": "15px !important",
  "&.Mui-selected": {
    color: "#635ee7"
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)"
  }
}));

const colorMap = {
  JEE: "#2E3AC4",
  NEET: "#20B058",
  KCET: "#EA1819",
  Boards: "#888888",
  Custom: "#F48C06"
};

const batchTabs = [
  {
    name: "Recently Edited"
  },
  {
    name: "JEE"
  },
  {
    name: "NEET"
  },
  {
    name: "KCET"
  },
  {
    name: "Boards"
  },
  {
    name: "Favourites"
  },
  {
    name: "Custom"
  }
];

const batchCoverImages = [BatchCover1, BatchCover2, BatchCover3, BatchCover4, BatchCover5, BatchCover6];

const BatchTabs = () => {
  const [value, setValue] = useState(0);
  const [dataset, setDataset] = useState([]);
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardMenuAnchorEl, setCardMenuAnchorEl] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const {
    data: batchData,
    isLoading,
    isSuccess
  } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });
  const { data: favBatches, isSuccess: getFavBatchesSuccess, isError } = useGetUsersFavBatchesQuery(user._id);
  const [setFavBatch] = useSetFavBatchMutation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = sortBy => {
    setAnchorEl(null);

    if (sortBy === "faculties") {
      setFilteredData([...filteredData].sort((a, b) => b.num_faculty - a.num_faculty));
    } else if (sortBy === "students") {
      setFilteredData([...filteredData].sort((a, b) => b.num_students - a.num_students));
    } else if (sortBy === "recent") {
      setFilteredData([...filteredData].sort((a, b) => b.created_at - a.created_at));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setFilteredData(data);
    } else {
      if (newValue === 5) {
        setFilteredData(
          data.filter(item => {
            return item?.is_fav;
          })
        );
      } else {
        setFilteredData(
          data.filter(item => {
            return item.tag === batchTabs[newValue]?.name;
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isSuccess && batchData && batchData.batches && favBatches?.favBatches) {
      const batchArray = batchData.batches.map((batch, index) => ({
        id: batch._id,
        sr_no: index + 1,
        name: batch.batch_name,
        tag: batch.batch_tag,
        description: batch.batch_description,
        num_students: batch.students?.length,
        num_faculty: batch.faculties?.length,
        is_fav: favBatches?.favBatches?.some(favId => {
          return favId === String(batch._id);
        }),
        created_at: Date.parse(batch.createdAt)
      }));

      // Sort batches by created_at date in descending order (newest first)
      batchArray.sort((a, b) => b.created_at - a.created_at);

      setData(batchArray);
    }
  }, [batchData, favBatches?.favBatches]);

  useEffect(() => {
    setFilteredData(data || []);
    handleChange("ok", value);
  }, [data]);

  const handleSearch = event => {
    setValue(0);
    setFilteredData(data.filter(item => item.name.toLowerCase().includes(event.target.value.toLowerCase())));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
          {batchTabs.map((batch, index) => (
            <StyledTab label={batch.name} key={index} {...a11yProps(index)} />
          ))}
        </StyledTabs>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} onChange={handleSearch} />
          </Search>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <Funnel size={20} color="blue" />
            Sort
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem
              onClick={() => handleClose("recent")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              Recent
              <SortAscending size={18} color="blue" />
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("faculties")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              Faculties
              <SortAscending size={18} color="blue" />
            </MenuItem>
            <MenuItem
              onClick={() => handleClose("students")}
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center"
              }}
            >
              Students
              <SortAscending size={18} color="blue" />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Grid container spacing={2} paddingTop="1rem">
        {isSuccess ? (
          filteredData?.length ? (
            filteredData?.map((batch, index) => (
              <Grid item key={batch.id} xs={12} sm={6} md={4} xl={3}>
                <Card
                  sx={{
                    "borderRadius": "1rem",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.04)", transition: "ease-in 200ms" },
                    "height": "100%",
                    "border": "1px solid #E6EDFF"
                  }}
                >
                  <CardContent sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", pb: "1rem !important" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "auto",
                        position: "relative",
                        overflow: "hidden",
                        marginBottom: "10px"
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          paddingTop: "56.25%", // 16:9 aspect ratio
                          position: "relative"
                        }}
                        onClick={() => navigate(`/view-batch/${batch.id}`)}
                      >
                        <img
                          src={batchCoverImages[index % batchCoverImages.length]}
                          alt="Batch Cover"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            objectFit: "cover",
                            borderRadius: "1rem"
                          }}
                        />
                        <Box
                          color={batch.is_fav ? "orange" : "white"}
                          sx={{
                            "zIndex": 10000,
                            "&:hover": {
                              color: "orange",
                              transition: "ease-in 100ms",
                              cursor: "pointer"
                            }
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            setFavBatch({ batch_id: batch.id, user_id: user._id });
                          }}
                        >
                          <Star
                            style={{
                              position: "absolute",
                              top: "1rem",
                              right: "1rem",
                              fontSize: "1.5rem"
                            }}
                            weight="fill"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <Typography variant="h4" color="textPrimary" style={{ fontWeight: 500 }}>
                        {batch.name}
                      </Typography>
                      <Box display="flex" gap="1rem" alignItems="center">
                        <div
                          style={{
                            padding: "1px 10px",
                            borderRadius: "1rem",
                            backgroundColor: colorMap[batch.tag] || "orange",
                            color: "#fff",
                            fontWeight: 600
                          }}
                        >
                          {batch.tag}
                        </div>
                      </Box>
                    </Box>
                    {/* Description */}
                    <Box gap="0.5rem" display="flex" alignItems="center">
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        {batch?.num_students} students
                      </Typography>
                      <hr style={{ height: "20px" }} />
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        {batch?.num_faculty} faculty
                      </Typography>
                      <hr style={{ height: "20px" }} />
                      <Typography variant="h6" color="textSecondary" fontWeight={400} fontSize={12}>
                        Created on {new Date(batch?.created_at).toLocaleDateString()}
                        {batch.is_fav}
                      </Typography>
                    </Box>
                    {/* <Button
                    component={Link}
                    to={`/view-batch/${batch.id}`}
                    size="small"
                    color="primary"
                    style={{
                      fontSize: "14px",
                      fontWeight: "1000",
                      marginLeft: "-4px"
                    }}
                  >
                    View Details
                  </Button> */}
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
                height: "30%",
                width: "100%",
                marginTop: "1rem",
                alignItems: "center"
              }}
            >
              No batches to display
            </Box>
          )
        ) : (
          <div style={{ display: "flex", justifyContent: "center", height: "20%", width: "100%" }}>Loading...</div>
        )}
      </Grid>
    </Box>
  );
};

export default BatchTabs;
