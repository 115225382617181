import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import GMeet from "../../../components/SuperAdmin/ManageCollege/GMeet";
import { useSaveCredentialsMutation } from "../../../redux/api/meetManagerSlice";
import { useGetAllSchoolQuery } from "../../../redux/api/schoolSlice";
import SetUserLimit from "../../../components/SuperAdmin/ManageCollege/SetUserLimit";

const ManageCollege = () => {
  const { school_id } = useParams();
  const theme = useTheme();

  const [college, setCollege] = useState(null);

  const { data: schoolsData, isSuccess } = useGetAllSchoolQuery();
  const [saveCredentials, { isError, isLoading, isSuccess: saveCredentialsSuccess }] = useSaveCredentialsMutation();

  useEffect(() => {
    if (isSuccess && schoolsData && schoolsData?.schools) {
      const college = schoolsData?.schools?.find(school => school._id === school_id);
      setCollege(college);
    }
  }, [schoolsData, school_id]);

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Manage Colleges</title>
        <meta name="description" content="Manage Colleges with ScaleYou Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            College: {college?.name}
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View and manage all colleges in one place
          </Typography>
        </Box>
      </Box>
      {school_id && college && (
        <>
          <GMeet school_id={school_id} />
          <SetUserLimit school_id={school_id} college={college} />
        </>
      )}
    </Box>
  );
};

export default ManageCollege;
