import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { solutionsBg } from "../../../Assets/landing";

const Solutions = () => {
  const [activeSection, setActiveSection] = useState("Supervisor");
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const sections = [
    {
      name: "Supervisor",
      icon: <PersonIcon />,
      benefits: [
        "Create Online/Offline Exams in minutes",
        "Scaleyou AI Assitance to monitor and assist all activities of institute. ",
        "Access to 200k Questions in Question Bank",
        "Complete online Analytical data of Institute",
        "Detailed Analysis for all competitive exam",
        "Publish Board Exam results and virtual report card.",
        "Announcements and Notice management system ",
        "PTM reports of each individual student",
        "Manage students and faculties Activity",
        "Attendance system for both faculty and student",
        "Online classroom and Interactive tools",
        "Batch performance Tracking",
        "Comprehensive coverage of Biology, Physics, Chemistry and Mathematics ",
        "Student and faculty performance tracking"
      ]
    },
    {
      name: "Teacher",
      icon: <SchoolIcon />,
      benefits: [
        "Access to all students academic reports",
        "Access to Scaleyou AI for better teacching practices",
        "Access to 200k Questions in Question bank",
        "Manage all activities via both web and App",
        "Virtual Batch facility for efficient e-Learning",
        "Detailed explanations for each question",
        "Online class facility using google meet",
        "Record and manage all online class",
        "Comprehensive analysis of Biology, Physics, Chemistry and Mathematics batch wise",
        "Comprehensive coverage of Biology, Physics, Chemistry and Mathematics",
        "Personalized self performance tracking",
        "Tailored for Karnataka students",
        "Personalized performance tracking",
        "Personalized performance tracking"
      ]
    },
    {
      name: "Student",
      icon: <SupervisorAccountIcon />,
      benefits: [
        "Access to detailed analysis and reports of all exams ",
        "Access to online/offline exams ",
        "Scaleyou AI based personal Growth coach",
        "Access to recorded lectures and live classes",
        "Structured Batches for efficient e-learning",
        "Advanced graph and analytics for self growth",
        "Competitive and Board exams report card ",
        "Virtual faculty assistance for both studuents and parents",
        "Customized exam based on performance",
        "Access to classroom materials like pdf and notes in one place",
        "Online attendance tracker ",
        "Manage all activities via both web and App",
        "Personalized performance tracking",
        "Online class management via Google meet"
      ]
    }
  ];

  return (
    <Box
      sx={{
        backgroundImage: `url(${solutionsBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "120%",
        height: isNonMobile ? "40" : "40"
      }}
      className="relative -mt-[20%] md:-mt-[10%] lg:mt-8 flex flex-col gap-8 lg:gap-20 w-full"
    >
      <div
        className={
          "container mx-auto p-4 lg:px-20 flex flex-col justify-center items-center gap-8 lg:gap-12 w-full max-w-7xl"
        }
      >
        <div className="flex flex-col justify-center items-center w-full gap-2">
          <h1 className="m-0 text-lg lg:text-xl font-semibold text-[#4A3AFF] uppercase"> Solutions</h1>
          <h2 className="m-0 text-xl lg:text-4xl font-semibold text-[#170F49]">Features & Attributes for Each Role</h2>
          <h3 className="m-0 font-normal text-sm text-[#6F6C90] lg:w-1/2 text-center">
            Explore comprehensive features and distinctive attributes tailored for every role, ensuring optimal
            performance and success for your ecosystem and Institute
          </h3>
        </div>
        <section className="flex justify-center items-center gap-2 lg:gap-4">
          {sections.map(section => (
            <button
              key={section.name}
              type="button"
              className={`flex justify-center items-center gap-1 lg:gap-2 bg-transparent outline-none px-2 lg:px-4 py-2 lg:py-3 rounded-md hover:scale-105 md:hover:shadow-sm duration-300 md:hover:shadow-black ${
                activeSection === section.name ? "text-black border border-[#D0D5DD]" : "text-[#667085] border-none"
              }`}
              onClick={() => setActiveSection(section.name)}
            >
              {section.icon}
              <h3 className="m-0 font-normal">{section.name}</h3>
            </button>
          ))}
        </section>

        {sections.map(
          section =>
            activeSection === section.name && (
              <section
                key={section.name}
                className="m-0 w-full bg-white/50 p-4 lg:px-8 lg:py-6 rounded-lg shadow space-y-4"
              >
                <div className="m-0 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
                  {section.benefits.map((benefit, index) => (
                    <div key={index} className="flex justify-start items-start lg:items-center gap-3">
                      <CheckBoxIcon className="text-[#1264CE] text-lg lg:text-2xl" />
                      <h4 className="m-0 font-normal text-[#170F49] lg:text-lg">{benefit}</h4>
                    </div>
                  ))}
                </div>
              </section>
            )
        )}
      </div>
    </Box>
  );
};

export default Solutions;
