import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  toggleButtonGroupClasses,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trash, X } from "@phosphor-icons/react";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteSectionMutation, useUpdateAdescriptionMutation } from "../../../redux/api/taskSlice";
import IndividualFileSection from "./IndividualFileSection";

import VideoPlayer from "../../../utils/VideoPlayer";
import AddRecordClass from "./AddRecordClass";
import CreateLiveClass from "./CreateLiveClass";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0
    }
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent"
  }
}));

const ScrollableContainer = styled("div")({
  overflowX: "auto",
  whiteSpace: "nowrap",
  display: "flex",
  flexWrap: "nowrap"
});

const IndividualLessons = ({ Section, User, BatchId, index }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [value, setValue] = useState(Section.description || "No description set yet"); // Current editor value
  const [originalValue, setOriginalValue] = useState(Section.description ? Section.description : ""); // Original value for cancel
  const [isEditing, setIsEditing] = useState(false); // Edit mode state
  const [selectedVideo, setSelectedVideo] = useState("");
  const [fileName, setFileName] = useState("");
  const [updateDescription, { isLoading }] = useUpdateAdescriptionMutation();
  const [deleteSection, { isLoadingToDelete }] = useDeleteSectionMutation();
  const [alignment, setAlignment] = useState("Pre Session");

  const editorRef = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo"
  ];

  const handleButtonChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Add description",
      defaultActionOnPaste: "text",
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false
    }),
    []
  );

  useEffect(() => {
    const formatAlignment = str => str.toLowerCase().replace(/ /g, "_");

    if (alignment) {
      const formattedAlignment = formatAlignment(alignment);
      setValue(Section?.description?.[formattedAlignment] || "No description set yet");
      setSelectedVideo("");
    }
  }, [alignment, Section]);

  const handleDeleteSectionClick = async () => {
    handleCloseDeleteDialog();
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/task/files/${User?.school_id}/${BatchId}`);
    const allSectionFiles = response.data.contents;
    const sectionFiles = allSectionFiles.filter(file => file.Key.includes(Section._id));
    const details = {
      section_files: sectionFiles,
      section_id: Section._id,
      batch_id: BatchId
    };
    deleteSection(details);
    // await axios.post(`${process.env.REACT_APP_BASE_URL}/task/delete-section`, details);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleEditSectionClick = () => {
    navigate(`/edit-section/${BatchId}/${Section._id}`);
  };

  return (
    <>
      <Box>
        <Accordion elevation={0} sx={{ border: "2px solid #f0f0f1", borderRadius: "1rem" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Box>
              <Typography
                variant="h3"
                sx={{
                  flex: "1",
                  fontWeight: 500
                }}
              >
                {`${index + 1}. ${Section.name}`}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <ScrollableContainer>
                <StyledToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleButtonChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="Pre Session"
                    sx={{
                      backgroundColor: alignment === "Pre Session" ? "#dce5ff !important" : "none",
                      color: alignment === "Pre Session" ? "#3267ff !important" : "none"
                    }}
                  >
                    Pre Session
                  </ToggleButton>
                  <ToggleButton
                    value="In Session"
                    sx={{
                      backgroundColor: alignment === "In Session" ? "#dce5ff !important" : "none",
                      color: alignment === "In Session" ? "#3267ff !important" : "none"
                    }}
                  >
                    In Session
                  </ToggleButton>
                  <ToggleButton
                    value="Post Session"
                    sx={{
                      backgroundColor: alignment === "Post Session" ? "#dce5ff !important" : "none",
                      color: alignment === "Post Session" ? "#3267ff !important" : "none"
                    }}
                  >
                    Post Session
                  </ToggleButton>
                  <ToggleButton
                    value="Live Class"
                    sx={{
                      backgroundColor: alignment === "Live Class" ? "#dce5ff !important" : "none",
                      color: alignment === "Live Class" ? "#3267ff !important" : "none"
                    }}
                  >
                    Live Class
                  </ToggleButton>
                  <ToggleButton
                    value="Recorded Class"
                    sx={{
                      backgroundColor: alignment === "Recorded Class" ? "#dce5ff !important" : "none",
                      color: alignment === "Recorded Class" ? "#3267ff !important" : "none"
                    }}
                  >
                    Recorded Class
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </ScrollableContainer>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"0.25rem"}
              justifyContent={"left"}
              alignItems={"left"}
              padding={"1rem"}
              width={"100%"}
              // sx={{ borderRadius: "1rem" }}
            >
              {alignment === "Live Class" && <CreateLiveClass sectionId={Section?._id} />}
              {alignment === "Recorded Class" && <AddRecordClass sectionId={Section?._id} />}
              {(alignment === "Pre Session" || alignment === "Post Session" || alignment === "In Session") && (
                <Box>
                  <Box
                    sx={{
                      position: "relative", // Ensure the child elements are positioned relative to this box
                      // border: "1px solid #ccc", // Border color and width
                      // borderRadius: "8px", // Border radius
                      padding: "16px", // Padding inside the box
                      height: "max-content", // Height of the box
                      width: "100%", // Full width
                      boxSizing: "border-box" // Include padding and border in the element's total width and height
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                  </Box>
                  {selectedVideo !== "" && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"1rem"}
                      width={isNonMobile ? "100%" : "146%"}
                      marginLeft={isNonMobile ? "0" : "-3rem"}
                      height={"100%"}
                      marginBottom="2rem"
                      px="2rem"
                      padding="1rem"
                      bgcolor="lightgrey"
                      borderRadius="8px"
                    >
                      <Box
                        display={"flex"}
                        width="100%"
                        alignItems="center"
                        justifyContent={isNonMobile ? "space-between" : "start"}
                      >
                        <Typography variant="h3" sx={{ textWrap: "wrap" }}>
                          Topic: {fileName.length > 12 && !isNonMobile ? fileName.substring(0, 12) + "..." : fileName}
                        </Typography>
                        <X
                          weight="fill"
                          size={24}
                          color="#ff3333"
                          onClick={() => setSelectedVideo("")}
                          style={{ cursor: "pointer", marginLeft: "-8rem", zIndex: 9999 }}
                        />
                      </Box>
                      <VideoPlayer videoUrl={selectedVideo} />
                    </Box>
                  )}

                  <IndividualFileSection
                    Section={Section}
                    User={User}
                    BatchId={BatchId}
                    onVideoSelect={videoName => setSelectedVideo(videoName)}
                    onFileSelect={file_name => setFileName(file_name)}
                    alignment={alignment}
                  />
                </Box>
              )}

              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                sx={{ flexDirection: isNonMobile ? "row" : "column" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    "backgroundColor": "#3267ff",
                    "marginTop": "1rem",
                    "marginLeft": isNonMobile ? "0" : "-5%",
                    "width": isNonMobile ? "20%" : "110%",
                    "&:hover": { backgroundColor: "#3267ff" }
                  }}
                  onClick={handleEditSectionClick}
                >
                  <EditIcon size={20} color="#ffffff" style={{ marginRight: "0.1rem" }} />
                  Edit Section
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    "backgroundColor": "#ff3333",
                    "marginTop": "1rem",
                    "marginLeft": isNonMobile ? "0" : "-5%",
                    "width": isNonMobile ? "20%" : "110%",
                    "&:hover": { backgroundColor: "#ff3333" }
                  }}
                  onClick={handleOpenDeleteDialog}
                >
                  <Trash size={20} color="#ffffff" style={{ marginRight: "0.1rem" }} />
                  Delete Section
                </Button>
              </Box>
              {/* Confirmation Dialog */}
              <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Delete Section</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this section? This action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDeleteDialog} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleDeleteSectionClick} color="secondary" autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default IndividualLessons;
