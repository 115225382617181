import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useMediaQuery
} from "@mui/material";
import { CaretDown, PencilSimple, Plus, Trash } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import CButton from "../../../components/ui/Button";
import {
  useCreateAChapterMutation,
  useCreateASectionMutation,
  useCreateASubjectMutation,
  useDeleteAChapterMutation,
  useDeleteASectionMutation,
  useDeleteASubjectMutation,
  useGetEntireSyllabusQuery,
  useUpdateAChapterMutation,
  useUpdateASectionMutation,
  useUpdateASubjectMutation
} from "../../../redux/api/syllabusSlice";
import { toastError } from "../../../utils/ToastHandler";

const StyledTabs = styled(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "minHeight": "25px",
  "minWidth": "50vh",
  "display": "flex",
  "justifyContent": "space-between",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7"
  }
});

const StyledTab = styled(({ label, onEditClick, onDeleteClick, showEditIcons, ...props }) => (
  <Tab
    disableRipple
    label={
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span className="uppercase">{label}</span>
        {showEditIcons && (
          <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <PencilSimple
              onClick={e => {
                e.stopPropagation();
                onEditClick(label);
              }}
              size={18}
              style={{ cursor: "pointer", marginRight: "8px" }}
            />
            <Trash
              onClick={e => {
                e.stopPropagation();
                onDeleteClick("subject", label);
              }}
              color="#ff0000"
              size={18}
              style={{ cursor: "pointer" }}
            />
          </Box>
        )}
      </Box>
    }
    {...props}
  />
))(({ theme }) => ({
  "textTransform": "none",
  "fontWeight": theme.typography.fontWeightRegular,
  "fontSize": theme.typography.pxToRem(15),
  "color": "rgba(0, 0, 0, 0.4)",
  "minHeight": "25px !important",
  "minWidth": "4rem !important",
  "&.Mui-selected": {
    color: "#635ee7"
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)"
  }
}));

const scrollableTabsStyle = {
  "maxWidth": "90vw",
  "overflow": "auto",
  "& .MuiTabs-scroller": {
    overflow: "auto !important"
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "flex-start"
  }
};

const SupervisorDisplaySyllabus = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user.school_id);

  const [createASubject] = useCreateASubjectMutation();
  const [updateASubject] = useUpdateASubjectMutation();
  const [deleteASubject] = useDeleteASubjectMutation();

  const [createASection] = useCreateASectionMutation();
  const [updateASection] = useUpdateASectionMutation();
  const [deleteASection] = useDeleteASectionMutation();

  const [createAChapter] = useCreateAChapterMutation();
  const [updateAChapter] = useUpdateAChapterMutation();
  const [deleteAChapter] = useDeleteAChapterMutation();

  const [showsubjects, setSubjects] = useState([]);
  const [showsections, setSections] = useState([]);

  const [alignment, setAlignment] = useState("");
  const [value, setValue] = useState(0);

  const [subjectName, setSubjectName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [chapterName, setChapterName] = useState("");

  // State variables for updating subject name
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [updatedSubjectName, setUpdatedSubjectName] = useState("");
  const [subjectToUpdate, setSubjectToUpdate] = useState("");

  // State variables for updating section name
  const [openUpdateSectionDialog, setOpenUpdateSectionDialog] = useState(false);
  const [updatedSectionName, setUpdatedSectionName] = useState("");
  const [sectionToUpdate, setSectionToUpdate] = useState("");

  // State variables for updating chapter name
  const [openUpdateChapterDialog, setOpenUpdateChapterDialog] = useState(false);
  const [updatedChapterName, setUpdatedChapterName] = useState("");
  const [chapterToUpdate, setChapterToUpdate] = useState("");

  //State variables for deleteing subject/chapter/section name
  const [openDeleteDialogBox, setOpenDeleteDialogBox] = useState(false);

  const [clickedSection, setClickedSectionName] = useState("");

  const handleNameChange = event => {
    setSubjectName(event.target.value);
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(showsubjects[newAlignment]);
      setValue(newAlignment);
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    if (showsubjects > 10) {
      return toastError("You have reached the limit of 10 subjects");
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddSubject = () => {
    const subjectData = {
      name: subjectName,
      school_id: user?.school_id
    };
    createASubject(subjectData);
    setSectionName("");
  };
  const handleCreateSubject = async () => {
    try {
      // For demonstration purposes, just showing a toast with the name
      if (subjectName.trim() === "") {
        return toastError("Please fill all the fields");
      }

      await handleAddSubject(); // Create the subject
    } catch (error) {
      console.error("Error creating subject:", error);
    }
  };

  //update subject
  const handleUpdateSubject = subjectName => {
    setSubjectToUpdate(subjectName);
    setUpdatedSubjectName(subjectName);
    setOpenUpdateDialog(true);
  };

  const handleConfirmUpdateSubject = () => {
    // No need to convert to string if subject names are already strings
    const old_name = subjectToUpdate;
    const new_name = updatedSubjectName;

    updateASubject({ old_name, new_name, school_id: user.school_id });
    setOpenUpdateDialog(false);
  };

  //update done
  const [editingSubject, setEditingSubject] = useState(null);
  const [showEditIcons, setShowEditIcons] = useState(false);

  const handleEditButtonClick = () => {
    setShowEditIcons(!showEditIcons);
    if (!showEditIcons) {
      setEditingSubject(null); // Reset editing subject if icons are hidden
    }
  };

  const handleDeleteSubject = async subjectName => {
    try {
      deleteASubject(subjectName);
      handleDeleteDialogBoxClose();
      if (selectedItem.name === Object.keys(syllabus)[0]) setValue(1);
      else setValue(0);
    } catch (error) {
      console.error("Error deleting subject:", error);
    }
  };

  const handleSectionNameChange = event => {
    setSectionName(event.target.value);
  };
  const [openAddSectionDialog, setOpenAddSectionDialog] = useState(false);

  const handleOpenAddSectionDialog = () => {
    setOpenAddSectionDialog(true);
  };

  const handleCloseAddSectionDialog = () => {
    setOpenAddSectionDialog(false);
  };

  const handleAddSection = () => {
    const sectionData = {
      name: sectionName,
      school_id: user.school_id,
      alignment: alignment
    };

    createASection(sectionData);
  };
  const handleCreateSection = () => {
    // For demonstration purposes, just showing a toast with the name
    if (sectionName.trim() === "") {
      return toastError("Please fill all the fields");
    }
    handleAddSection();
  };

  const handleDeleteSection = async sectionName => {
    try {
      // Dispatch the action to delete the section
      deleteASection(sectionName);
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  //update section part

  const handleUpdateSection = sectionName => {
    setSectionToUpdate(sectionName);
    setUpdatedSectionName(sectionName);
    setOpenUpdateSectionDialog(true);
  };

  const handleConfirmUpdateSection = () => {
    const old_name = sectionToUpdate;
    const new_name = updatedSectionName;

    updateASection({ old_name, new_name, school_id: user.school_id });
    setOpenUpdateSectionDialog(false);
  };

  const handleChapterNameChange = event => {
    setChapterName(event.target.value);
  };

  const [openAddChapterDialog, setOpenAddChapterDialog] = useState(false);

  const handleOpenAddChapterDialog = ssection => {
    setClickedSectionName(ssection);
    setOpenAddChapterDialog(true);
  };

  const handleCloseAddChapterDialog = () => {
    setOpenAddChapterDialog(false);
  };

  const handleAddChapter = () => {
    const chapterData = {
      name: chapterName,
      school_id: user.school_id,
      alignment: alignment,
      section: clickedSection
    };

    createAChapter(chapterData);
  };

  const handleCreateChapter = () => {
    // For demonstration purposes, just showing a toast with the name
    if (chapterName.trim() === "") {
      return toastError("Please fill all the fields");
    }

    handleAddChapter();
  };

  //update chapter
  const handleUpdateChapter = chapterName => {
    setChapterToUpdate(chapterName);
    setUpdatedChapterName(chapterName);
    setOpenUpdateChapterDialog(true);
  };

  const handleConfirmUpdateChapter = () => {
    const old_name = chapterToUpdate;
    const new_name = updatedChapterName;
    updateAChapter({ old_name, new_name, school_id: user.school_id });
    // Close the dialog
    setOpenUpdateChapterDialog(false);
  };

  //update chapter done

  const handleDeleteChapter = async (sectionName, chapterName) => {
    try {
      // Dispatch the action to delete the chapter
      deleteAChapter(chapterName);
    } catch (error) {
      console.error("Error deleting chapter:", error);
    }
  };

  ///done

  useEffect(() => {
    if (isGetSyllabusSuccess && syllabus) {
      if (Object.keys(syllabus).length) {
        setSubjects(Object.keys(syllabus));
        if (alignment === "") {
          const firstSubject = Object.keys(syllabus)[0];
          setAlignment(firstSubject);
        }
      }
    }
  }, [syllabus]);

  useEffect(() => {
    if (syllabus && Object.keys(syllabus).length) {
      const keys = Object.keys(syllabus);
      const n = keys.length;

      if (value >= 0 && value < n) {
        const key = keys[value];
        const selectedObject = syllabus[key];

        if (selectedObject && Object.keys(selectedObject).length) {
          setSections(Object.entries(selectedObject));
        } else {
          setSections([]);
        }
      } else {
        setSections([]);
      }
    } else {
      setSections([]);
    }
  }, [alignment, syllabus, value]);

  const a11yProps = index => {
    return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  };

  const handleDeleteDialogBoxOpen = (type, name, chapterName) => {
    setSelectedItem({ type, name, chapterName });
    setOpenDeleteDialogBox(true);
  };
  const handleDeleteDialogBoxClose = () => setOpenDeleteDialogBox(false);

  const [selectedItem, setSelectedItem] = useState({ type: "", name: "", chapterName: "" });

  const DeleteDialogBox = ({ type, name, chapterName = "", open, onClose }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: "500px",
            height: "fit-content"
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>
          Delete {type} {name}.
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Deleting {name} {type} will delete it permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogBoxClose} variant="text">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (type === "subject") handleDeleteSubject(name);
              else if (type === "section") handleDeleteSection(name);
              else if (type === "chapter") handleDeleteChapter(name, chapterName);
              else console.error("Raise ticket about this problem");
              handleDeleteDialogBoxClose();
            }}
            variant="text"
            sx={{
              color: "#ff0000"
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (!isGetSyllabusSuccess) {
    return (
      <Typography height="100%" width="100%" textAlign="center">
        Loading
      </Typography>
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      p={isNonMobile ? "1rem" : "0rem"}
      flexDirection="column"
      gap="1rem"
      bgcolor="rgb(250,250,251,1)"
      sx={{ overflowY: "revert-layer" }}
    >
      <DeleteDialogBox
        type={selectedItem.type}
        name={selectedItem.name}
        chapterName={selectedItem.chapterName}
        open={openDeleteDialogBox}
        onClose={handleDeleteDialogBoxClose}
      />
      <Helmet>
        <title>Insights | Manage Syllabus</title>
        <meta name="description" content="Manage Syllabus layed down by your institution at ScaleYou Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box width="100%" display="flex" justifyContent="flex-end" top="90px" right="10px" zIndex="999">
        <CButton
          buttonText="Create Subject"
          variant="primary"
          icon={<Plus weight="bold" color="#fff" size={18} />}
          onClickFun={handleOpenDialog}
        />
      </Box>
      <Box width="100%" p={2} display="flex" justifyContent="center">
        {showsubjects.length ? (
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
            variant={!isNonMobile ? "scrollable" : "standard"}
            scrollButtons={!isNonMobile ? "auto" : false}
            sx={!isNonMobile ? scrollableTabsStyle : {}}
          >
            {showsubjects.map((ssubject, index) => (
              <StyledTab
                label={ssubject}
                onEditClick={handleUpdateSubject}
                onDeleteClick={() => handleDeleteDialogBoxOpen("subject", ssubject)}
                showEditIcons={showEditIcons}
                key={ssubject}
                {...a11yProps(index)}
                sx={!isNonMobile ? { minWidth: "auto" } : {}}
              />
            ))}
          </StyledTabs>
        ) : (
          // <SubjectToggleButton subject="Loading..." />
          <Typography>No Subjects In syllabus</Typography>
        )}
        {/* Dialog for updating subject name */}
        <Dialog
          open={openUpdateDialog}
          onClose={() => setOpenUpdateDialog(false)}
          PaperProps={{
            style: {
              width: "500px", // Adjust width as needed
              height: "300px" // Adjust height as needed
            }
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Update Subject Name</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
              Enter the updated name for the subject:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="updatedSubjectName"
              label="Updated Name"
              fullWidth
              variant="standard"
              value={updatedSubjectName}
              onChange={e => setUpdatedSubjectName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenUpdateDialog(false)}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#fff",
                "opacity": 0.7,
                "color": "black",
                "&:hover": {
                  bgcolor: "#333333",
                  opacity: 0.7
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmUpdateSubject}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#1976d2", // Blue primary color
                "color": "white",
                "&:hover": {
                  bgcolor: "#1565c0" // Darker shade of blue on hover
                }
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {showsubjects.length !== 0 && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" gap="1rem">
          <Link component={"button"} onClick={handleOpenAddSectionDialog}>
            Add Sections
          </Link>
          <button
            onClick={handleEditButtonClick}
            style={{
              cursor: "pointer",
              border: "1px solid #E6EDFF", // Border color primary blue
              borderRadius: "5px", // Border radius
              backgroundColor: "#3267FF", // Primary blue background color
              color: "#ffffff", // Text color
              padding: "8px 16px", // Padding
              zIndex: 999,
              width: "80px"
            }}
            type="button"
          >
            {/* Toggle between "Edit" and "Close" text */}
            {showEditIcons ? "Close" : "Edit"}
          </button>
        </Box>
      )}
      <Box>
        {showsections.length ? (
          showsections.map(([sectionName, sectionContents], index) => (
            <Box key={sectionName} marginBottom="1rem">
              <Accordion
                key={sectionName}
                sx={{
                  border: "1px solid #FFFFFF", // Add border
                  borderRadius: "1rem", // Add border radius
                  boxShadow: "none" // Remove the shadow
                }}
              >
                <AccordionSummary
                  expandIcon={<CaretDown />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  sx={{
                    color: "black",
                    bgcolor: "white",
                    fontWeight: 600,
                    position: "relative" // Add relative positioning
                  }}
                >
                  {/* Trash icon */}

                  {/* Section title */}
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <ChevronRightIcon />
                      <Typography sx={{ fontSize: "15px", fontWeight: 550, width: isNonMobile ? "100%" : "60%" }}>
                        {`Section ${index + 1}: ${sectionName}`}
                      </Typography>
                    </Box>

                    {showEditIcons && (
                      <Box>
                        {/* Trash icon */}
                        <Trash
                          onClick={() => handleDeleteDialogBoxOpen("section", sectionName)}
                          size={18}
                          style={{
                            position: "absolute",
                            right: isNonMobile ? "90px" : "50px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "#ff0000"
                          }}
                        />
                        {/* PencilSimple icon */}
                        <PencilSimple
                          onClick={() => handleUpdateSection(sectionName)}
                          size={18}
                          style={{
                            position: "absolute",
                            right: isNonMobile ? "130px" : "100px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer"
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box width="100%" display="flex" p="0.5rem" flexDirection="column" gap="0.5rem">
                    <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
                      <Link component="button" onClick={() => handleOpenAddChapterDialog(sectionName)}>
                        Add Chapters
                      </Link>
                    </Box>
                    <AccordionDetails
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      {/* Render chapters as separate blue boxes */}
                      {sectionContents.length ? (
                        sectionContents.map((chapter, chapterIndex) => (
                          <Box
                            key={chapterIndex}
                            marginBottom="0.5rem"
                            justifyContent={"space-between"}
                            sx={{
                              width: "100%",
                              padding: "6px", // Adjust the padding as needed
                              marginRight: "1rem",
                              bgcolor: "#D0DDFF", // Blue color
                              borderRadius: "8px", // Adjust the border radius as needed
                              display: "flex", // Add flex display
                              alignItems: "center", // Center items vertically
                              position: "relative" // Add relative positioning
                            }}
                          >
                            <Box display="flex" flexDirection={"row"} width={"70%"}>
                              {/* Chapter title */}
                              <ChevronRightIcon />
                              <Typography
                                noWrap={true}
                                sx={{
                                  flex: "1", // Take up remaining space
                                  fontFamily: "Helvetica, sans-serif",
                                  color: "#00000",
                                  fontSize: "15px",
                                  fontWeight: 550
                                }}
                              >
                                {`${isNonMobile ? "Chapter" : "Ch"} ${chapterIndex + 1}: ${chapter}`}
                              </Typography>
                            </Box>

                            {showEditIcons && (
                              <Box
                                display={"flex"}
                                flexDirection="row"
                                justifyContent="right"
                                justifyItems="right"
                                width="40%"
                                marginRight={isNonMobile ? "1rem" : "0rem"}
                              >
                                <div>
                                  {/* Trash icon */}
                                  <PencilSimple
                                    onClick={() => handleUpdateChapter(chapter)}
                                    size={18}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "20px"
                                    }}
                                  />
                                  <Trash
                                    onClick={() => {
                                      handleDeleteDialogBoxOpen("chapter", sectionName, chapter);
                                    }}
                                    size={18}
                                    color="#ff0000"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "20px"
                                    }}
                                  />
                                  {/* PencilSimple icon */}
                                </div>
                              </Box>
                            )}

                            {/* Edit and delete icons */}
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body1" sx={{ fontFamily: "Arial, sans-serif" }}>
                          No Sections
                        </Typography>
                      )}
                      <Dialog
                        open={openUpdateChapterDialog}
                        onClose={() => setOpenUpdateChapterDialog(false)}
                        PaperProps={{
                          style: {
                            width: "500px",
                            height: "300px"
                          }
                        }}
                      >
                        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Update Chapter Name</DialogTitle>
                        <DialogContent>
                          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
                            Enter the updated name for the chapter:
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="updatedChapterName"
                            label="Updated Name"
                            fullWidth
                            variant="standard"
                            value={updatedChapterName}
                            onChange={e => setUpdatedChapterName(e.target.value)}
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: "16px",
                                backgroundColor: "#fff"
                              },
                              "& .MuiInputLabel-root": {
                                fontSize: "15px"
                              }
                            }}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setOpenUpdateChapterDialog(false)}
                            variant="contained"
                            color="primary"
                            sx={{
                              "height": "44px",
                              "width": "98px",
                              "borderRadius": "7px",
                              "bgcolor": "#fff",
                              "opacity": 0.7,
                              "color": "black",
                              "&:hover": {
                                bgcolor: "#333333",
                                opacity: 0.7
                              }
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleConfirmUpdateChapter}
                            variant="contained"
                            color="primary"
                            sx={{
                              "height": "44px",
                              "width": "98px",
                              "borderRadius": "7px",
                              "bgcolor": "#1976d2", // Blue primary color
                              "color": "white",
                              "&:hover": {
                                bgcolor: "#1565c0" // Darker shade of blue on hover
                              }
                            }}
                          >
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </AccordionDetails>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))
        ) : (
          <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            <Typography display={"flex"} alignSelf={"center"} width={"100%"} variant="h5">
              Sections not found
            </Typography>
          </Box>
        )}

        {/* dialog box to add sections */}
        <Dialog
          open={openUpdateSectionDialog}
          onClose={() => setOpenUpdateSectionDialog(false)}
          PaperProps={{
            style: {
              width: "500px",
              height: "300px"
            }
          }}
        >
          <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Update Section Name</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
              Enter the updated name for the section:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="updatedSectionName"
              label="Updated Name"
              fullWidth
              variant="standard"
              value={updatedSectionName}
              onChange={e => setUpdatedSectionName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": {
                  fontSize: "16px",
                  backgroundColor: "#fff"
                },
                "& .MuiInputLabel-root": {
                  fontSize: "15px"
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenUpdateSectionDialog(false)}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#fff",
                "opacity": 0.7,
                "color": "black",
                "&:hover": {
                  bgcolor: "#333333",
                  opacity: 0.7
                }
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmUpdateSection}
              variant="contained"
              color="primary"
              sx={{
                "height": "44px",
                "width": "98px",
                "borderRadius": "7px",
                "bgcolor": "#1976d2", // Blue primary color
                "color": "white",
                "&:hover": {
                  bgcolor: "#1565c0" // Darker shade of blue on hover
                }
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            width: "500px", // Adjust width as needed
            height: "300px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New Subject</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the subject:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={subjectName}
            onChange={handleNameChange}
            sx={{
              "& .MuiInputBase-root": {
                fontSize: "16px",
                backgroundColor: "#fff"
              },
              "& .MuiInputLabel-root": {
                fontSize: "15px"
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              "height": "44px", // Adjust the height
              "width": "98px",
              "borderRadius": "7px",
              "bgcolor": "#fff", // Set initial background color to black
              "opacity": 0.7, // Decrease opacity
              "color": "black",
              "&:hover": {
                bgcolor: "#333333", // Change background color to slight black on hover
                opacity: 0.7 // Increase opacity on hover
              }
            }}
          >
            Cancel
          </Button>

          <CButton
            buttonText="Create"
            variant="primary"
            onClickFun={() => {
              handleCreateSubject();
              handleCloseDialog();
            }}
          />
        </DialogActions>
      </Dialog>

      {/* section starts here */}
      <Dialog
        open={openAddSectionDialog}
        onClose={handleCloseAddSectionDialog}
        PaperProps={{
          style: {
            width: "500px", // Adjust width as needed
            height: "300px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New Section</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the section:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={sectionName} // Connect value to the name state
            onChange={handleSectionNameChange}
            sx={{
              // Adjust styles here
              "& .MuiInputBase-root": {
                // Modify input base styles
                fontSize: "16px", // Adjust font size
                backgroundColor: "#fff" // Adjust background color
              },
              "& .MuiInputLabel-root": {
                // Modify input label styles
                fontSize: "15px" // Adjust font size
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseAddSectionDialog}
            color="primary"
            sx={{
              "height": "44px", // Adjust the height
              "width": "98px",
              "borderRadius": "7px",
              "bgcolor": "#fff", // Set initial background color to black
              "opacity": 0.7, // Decrease opacity
              "color": "black",
              "&:hover": {
                bgcolor: "#333333", // Change background color to slight black on hover
                opacity: 0.8 // Increase opacity on hover
              }
            }}
          >
            Cancel
          </Button>
          <CButton
            buttonText="Create"
            variant="primary"
            onClickFun={() => {
              handleCreateSection();
              handleCloseAddSectionDialog();
            }}
          />
        </DialogActions>
      </Dialog>

      {/* chapter present here  */}
      <Dialog
        open={openAddChapterDialog}
        onClose={handleCloseAddChapterDialog}
        PaperProps={{
          style: {
            width: "500px", // Adjust width as needed
            height: "300px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create New Chapter</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the chapter:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={chapterName} // Connect value to the name state
            onChange={handleChapterNameChange}
            sx={{
              // Adjust styles here
              "& .MuiInputBase-root": {
                // Modify input base styles
                fontSize: "16px", // Adjust font size
                backgroundColor: "#fff" // Adjust background color
              },
              "& .MuiInputLabel-root": {
                // Modify input label styles
                fontSize: "15px" // Adjust font size
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseAddChapterDialog}
            color="primary"
            sx={{
              "height": "44px",
              "width": "98px",
              "borderRadius": "7px",
              "bgcolor": "#fff",
              "opacity": 0.7,
              "color": "black",
              "&:hover": {
                bgcolor: "#333333",
                opacity: 0.7
              }
            }}
          >
            Cancel
          </Button>
          <CButton
            buttonText="Create"
            variant="primary"
            onClickFun={() => {
              handleCreateChapter();
              handleCloseAddChapterDialog();
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SupervisorDisplaySyllabus;
