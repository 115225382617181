import React, { useEffect, useState } from "react";
import { Box, Typography, InputLabel, MenuItem, FormControl, Select, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";
import { useGetAllExamsOfStudentsByBatchQuery } from "../../../redux/api/examSlice";

import AnalysisGraphs from "./AnalysisGraphs";
import SubjectAnalysisSuperInsights from "./SuperInsights/SubjectAnalysisSuperInsigts";

const SubjectWiseAnalysis = () => {
  const { user } = useSelector(state => state.auth);
  const [batchNames, setBatchNames] = useState([]);
  const [studentNames, setStudentNames] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState("AllBatches"); // Default selection for "All Batches"
  const [selectedStudent, setSelectedStudent] = useState({}); // Default student selection
  const [examDataForInsights, setExamDataForInsights] = useState([]);

  const { data: batchData, isSuccess: isBatchesSuccess } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const { data: examData, isSuccess: isExamSuccess } = useGetAllExamsOfStudentsByBatchQuery(
    {
      batch_id: selectedBatchId,
      student_id: selectedStudent?._id || ""
    },
    { skip: !isBatchesSuccess && !selectedBatchId && !selectedStudent?._id }
  );

  console.log("selectedStudent: ", selectedStudent?._id);

  useEffect(() => {
    if (isBatchesSuccess && batchData?.batches) {
      const batchNamesArray = batchData.batches.map(batch => ({
        name: batch.batch_name,
        id: batch._id
      }));
      setBatchNames(batchNamesArray);

      // Filter unique students by _id
      const uniqueStudentsArray = [];
      const uniqueIds = new Set();
      batchData.batches.forEach(batch => {
        batch.students.forEach(student => {
          if (!uniqueIds.has(student._id)) {
            uniqueIds.add(student._id);
            uniqueStudentsArray.push(student);
          }
        });
      });

      setStudentNames(uniqueStudentsArray);

      if (uniqueStudentsArray.length > 0) {
        setSelectedStudent(uniqueStudentsArray[0]);
      }
    }
  }, [batchData, isBatchesSuccess]);

  useEffect(() => {
    setExamDataForInsights(examData || []);
  }, [examData]);

  const handleBatchChange = event => {
    setSelectedBatchId(event.target.value === "All Batches" ? "AllBatches" : event.target.value);
  };

  const handleStudentChange = event => {
    const selected = studentNames.find(student => student._id === event.target.value);
    setSelectedStudent(selected || {});
  };

  if (!isBatchesSuccess) return <>Loading</>;

  console.log("examData", examData);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      bgcolor="#ffffff"
      padding="1.5rem"
      borderRadius="1rem"
      border="1px solid #e6edff"
      aria-label="SubjectWiseAnalysis Container"
      gap="14px"
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="medium" fontSize="20px">
            Subject Wise Analysis
          </Typography>
          {/* Dropdown for selecting batch names */}
          <Box display="flex" gap={1}>
            <FormControl>
              <Select
                value={selectedBatchId || "All Batches"}
                onChange={handleBatchChange}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                <MenuItem key={-1} value={"AllBatches"}>
                  All Batches
                </MenuItem>
                {batchNames.map(batch => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                value={selectedStudent._id || ""}
                onChange={handleStudentChange}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                {studentNames.map(student => (
                  <MenuItem key={student._id} value={student._id}>
                    {student.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={8} xl={8}>
            <AnalysisGraphs examData={examData} />
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <Box
              display="flex"
              flexDirection="column"
              gap="0.75rem"
              bgcolor="#fafafb"
              border="1px solid #E6EDFF"
              width="100%"
              height="50%"
              mt="1rem"
              p="1rem 1rem"
              borderRadius="0.5rem"
              position="relative"
            >
              <h3 className="m-0">How Subject Analysis Works?</h3>
              <p className="m-0 -mt-1 text-wrap text-start text-[#4F4E69]">
                Insights algorithms generate a detailed subject-wise analysis by averaging your performance across all
                tests and benchmarking it against the class average within your specific batch.
              </p>
            </Box>
          </Grid>
        </Grid>

        <Box>
          <SubjectAnalysisSuperInsights examDataForInsights={examDataForInsights} />
        </Box>
      </Box>
    </Box>
  );
};

export default SubjectWiseAnalysis;
