import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  toggleButtonGroupClasses,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { X } from "@phosphor-icons/react";
import VideoPlayer from "../../../utils/VideoPlayer";
import AddRecordClass from "./AddRecordClass";
import CreateLiveClass from "./CreateLiveClass";
import IndividualFileSection from "./IndividualFileSection";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0
    }
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent"
  }
}));

const ScrollableContainer = styled("div")({
  overflowX: "auto",
  whiteSpace: "nowrap",
  display: "flex",
  flexWrap: "nowrap"
});

const IndividualLessons = ({ Section, User, BatchId, index }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  const [value, setValue] = useState(Section.description ? Section.description : ""); // Current editor value
  const [originalValue, setOriginalValue] = useState(Section.description ? Section.description : ""); // Original value for cancel
  const [isEditing, setIsEditing] = useState(false); // Edit mode state
  const [alignment, setAlignment] = useState("Pre Session");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (alignment === "Pre Session") {
      setValue(Section?.description?.pre_session || "");
    } else if (alignment === "In Session") {
      setValue(Section?.description?.in_session || "");
    } else if (alignment === "Post Session") {
      setValue(Section?.description?.post_session || "");
    }
    setSelectedVideo("");
  }, [alignment, Section]);

  const editorRef = useRef(null);

  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo"
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Add description",
      defaultActionOnPaste: "text",
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false
    }),
    []
  );

  const handleButtonChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <>
      <Box>
        <Accordion elevation={0} sx={{ border: "2px solid #f0f0f1", borderRadius: "1rem" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Box>
              <Typography
                variant="h3"
                sx={{
                  flex: "1",
                  fontWeight: 500
                }}
              >
                {`${index + 1}. ${Section.name}`}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <ScrollableContainer>
              <StyledToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleButtonChange}
                aria-label="Platform"
              >
                <ToggleButton
                  value="Pre Session"
                  sx={{
                    backgroundColor: alignment === "Pre Session" ? "#dce5ff !important" : "none",
                    color: alignment === "Pre Session" ? "#3267ff !important" : "none"
                  }}
                >
                  Pre Session
                </ToggleButton>
                <ToggleButton
                  value="In Session"
                  sx={{
                    backgroundColor: alignment === "In Session" ? "#dce5ff !important" : "none",
                    color: alignment === "In Session" ? "#3267ff !important" : "none"
                  }}
                >
                  In Session
                </ToggleButton>
                <ToggleButton
                  value="Post Session"
                  sx={{
                    backgroundColor: alignment === "Post Session" ? "#dce5ff !important" : "none",
                    color: alignment === "Post Session" ? "#3267ff !important" : "none"
                  }}
                >
                  Post Session
                </ToggleButton>
                <ToggleButton
                  value="Live Class"
                  sx={{
                    backgroundColor: alignment === "Live Class" ? "#dce5ff !important" : "none",
                    color: alignment === "Live Class" ? "#3267ff !important" : "none"
                  }}
                >
                  Live Class
                </ToggleButton>
                <ToggleButton
                  value="Recorded Class"
                  sx={{
                    backgroundColor: alignment === "Recorded Class" ? "#dce5ff !important" : "none",
                    color: alignment === "Recorded Class" ? "#3267ff !important" : "none"
                  }}
                >
                  Recorded Class
                </ToggleButton>
              </StyledToggleButtonGroup>
            </ScrollableContainer>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"0.25rem"}
              justifyContent={"left"}
              alignItems={"left"}
              padding={"1rem"}
              width={"100%"}
              sx={{ borderRadius: "1rem", marginTop: "-1.5rem" }}
            >
              {alignment === "Live Class" && <CreateLiveClass sectionId={Section?._id} />}
              {alignment === "Recorded Class" && <AddRecordClass sectionId={Section?._id} />}
              {(alignment === "Pre Session" || alignment === "Post Session" || alignment === "In Session") && (
                <Box>
                  <Box
                    sx={{
                      position: "relative", // Ensure the child elements are positioned relative to this box
                      // border: "1px solid #ccc", // Border color and width
                      // borderRadius: "8px", // Border radius
                      padding: "16px", // Padding inside the box
                      height: "max-content", // Height of the box
                      width: "100%", // Full width
                      overflowY: "auto", // Scroll if content overflows
                      boxSizing: "border-box" // Include padding and border in the element's total width and height
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                  </Box>
                  {selectedVideo !== "" && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"1rem"}
                      height={"32rem"}
                      marginBottom="2rem"
                      px="2rem"
                    >
                      <Box display={"flex"} width="100%" alignItems="center" justifyContent="space-between">
                        <Typography variant="h3" marginBottom="-1rem">
                          Topic: {fileName}
                        </Typography>
                        <X
                          weight="fill"
                          size={24}
                          color="#ff3333"
                          onClick={() => setSelectedVideo("")}
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <VideoPlayer videoUrl={selectedVideo} />
                    </Box>
                  )}

                  <IndividualFileSection
                    Section={Section}
                    User={User}
                    BatchId={BatchId}
                    onVideoSelect={videoName => setSelectedVideo(videoName)}
                    onFileSelect={file_name => setFileName(file_name)}
                    alignment={alignment}
                  />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default IndividualLessons;
