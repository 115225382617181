import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useForgotPasswordMutation } from "../../../redux/api/authSlice";

const Settings = ({ user }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  return (
    <Box width="100%" bgcolor="#fff" borderRadius="1rem" border="1px solid #E6EDFF" p="1rem">
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Account Security
      </Typography>
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          width="100%"
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          justifyContent="space-between"
          gap="1.5rem"
          mt="1rem"
        >
          <Box width="100%" display="flex" flexDirection="column" gap="10px">
            <Typography variant="h4">Email</Typography>
            <Typography variant="subtitle1" color="grey">
              {user?.email}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ width: "100%", height: "1px", bgcolor: "rgba(0,0,0,0.2)", mt: "10px" }} />
      </Box>
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          width="100%"
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          justifyContent="space-between"
          gap="1.5rem"
          mt="1rem"
        >
          <Box width="100%" display="flex" flexDirection="column" gap="10px">
            <Typography variant="h4">Password</Typography>
            <Typography variant="subtitle1" color="grey">
              Set a permanent password to login to your account
            </Typography>
          </Box>
          <Button
            onClick={() => forgotPassword({ email: user?.email })}
            variant="outline"
            sx={{ width: "max-content", border: "1px solid black" }}
          >
            Change Password
          </Button>
        </Box>
        <Divider sx={{ width: "100%", height: "1px", bgcolor: "rgba(0,0,0,0.2)", mt: "10px" }} />
      </Box>
    </Box>
  );
};

export default Settings;
