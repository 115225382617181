import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import ROI from "./ROI";

const ROIPage = () => {
  return (
    <main className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0">
      <Header home={false} />
      {/* <Solutions /> */}
      <ROI />
      <Footer />
    </main>
  );
};

export default ROIPage;
