import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UserCurrentStandingStats from "./UserCurrentStandingStats";
import SubjectAnalysis from "./SubjectAnalysis";
import DetailedAnalysis from "./DetailedAnalysis";
import ScoreAnalysis from "./ScoreAnalysis";
import DifficultyAnalysis from "./DifficultyAnalysis";
import {
  useGetAllStudentAnalysisQuery,
  useGetStudentAnalysisOfAnExamWithRangeQuery
} from "../../../redux/api/analysisSlice";
import { useGetStudentResultByStudentIdQuery } from "../../../redux/api/resultsSlice";

const NewOverAllStats = ({ analysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { analysisId } = useParams();
  const { data: analysisData, isSuccess: isAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);
  const { data: boardData, isSuccess: isBoardSuccess } = useGetStudentResultByStudentIdQuery({
    studentId: user._id,
    schoolId: user.school_id
  });

  const [totalExams, setTotalExams] = useState();
  const [examForDetailedAnalysis, setExamForDetailedAnalysis] = useState({});

  const [avgScoresInEveryExamType, setAvgScoresInEveryExamType] = useState([
    { subject: "Analysing and Evaluating", A: 0 },
    { subject: "Creating", A: 0 },
    { subject: "Remembering", A: 0 },
    { subject: "Understanding", A: 0 },
    { subject: "Applying", A: 0 }
  ]);

  useEffect(() => {
    let avgScoresInEveryExamTypeVar = [
      { subject: "Analysing and Evaluating", A: 0 },
      { subject: "Creating", A: 0 },
      { subject: "Remembering", A: 0 },
      { subject: "Understanding", A: 0 },
      { subject: "Applying", A: 0 }
    ];
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0) {
      const currentExam = analysisData.analysisArray.find(exam => exam._id === analysisId);
      uniqueExams.add(currentExam.exam_for);

      if (currentExam) {
        const currentExamDate = new Date(currentExam.exam_date);

        // Filter exams based on the criteria
        const relevantExams = analysisData.analysisArray.filter(
          exam => exam._id !== analysisId && new Date(exam.exam_date) < currentExamDate
        );

        // Populate unique exams
        relevantExams.forEach(exam => {
          if (exam.exam_for && exam.exam_for !== "undefined") {
            uniqueExams.add(exam.exam_for);
          }
        });

        // Initialize totals and counts for averaging
        const totals = {
          "Analysing and Evaluating": 0,
          "Creating": 0,
          "Remembering": 0,
          "Understanding": 0,
          "Applying": 0
        };
        const counts = {
          "Analysing and Evaluating": 0,
          "Creating": 0,
          "Remembering": 0,
          "Understanding": 0,
          "Applying": 0
        };

        // Calculate totals for each type
        relevantExams.forEach(exam => {
          if (exam.evaluating_and_analysing !== undefined) {
            totals["Analysing and Evaluating"] += exam.evaluating_and_analysing;
            counts["Analysing and Evaluating"]++;
          }
          if (exam.creating_question !== undefined) {
            // eslint-disable-next-line dot-notation
            totals["Creating"] += exam.creating_question;
            // eslint-disable-next-line dot-notation
            counts["Creating"]++;
          }
          if (exam.remembering !== undefined) {
            // eslint-disable-next-line dot-notation
            totals["Remembering"] += exam.remembering;
            // eslint-disable-next-line dot-notation
            counts["Remembering"]++;
          }
          if (exam.understanding_questions !== undefined) {
            // eslint-disable-next-line dot-notation
            totals["Understanding"] += exam.understanding_questions;
            // eslint-disable-next-line dot-notation
            counts["Understanding"]++;
          }
          if (exam.applying_questions !== undefined) {
            // eslint-disable-next-line dot-notation
            totals["Applying"] += exam.applying_questions;
            // eslint-disable-next-line dot-notation
            counts["Applying"]++;
          }
        });

        // Calculate averages
        avgScoresInEveryExamTypeVar.forEach(type => {
          const total = totals[type.subject];
          const count = counts[type.subject];
          type.A = count ? total / count : 0;
        });

        setAvgScoresInEveryExamType(avgScoresInEveryExamTypeVar);

        // Set state for analysis
        setExamForDetailedAnalysis(currentExam);
        setTotalExams(Array.from(uniqueExams));
      }
    }
  }, [analysisData]);

  const { data: allStudentsRangeData, isSuccess: isAllStudentsRangeDataSuccess } =
    useGetStudentAnalysisOfAnExamWithRangeQuery(
      {
        school_id: user.school_id,
        exam_id: examForDetailedAnalysis?.exam_id,
        student_id: user._id
      },
      { skip: !examForDetailedAnalysis }
    );

  return (
    <Box display="flex" flexDirection={"column"} gap={2} width="100%">
      {examForDetailedAnalysis && isAllStudentsRangeDataSuccess && (
        <UserCurrentStandingStats
          allStudentsRangeData={allStudentsRangeData}
          studentAnalysisDetails={examForDetailedAnalysis}
        />
      )}
      {isAllStudentsRangeDataSuccess && <ScoreAnalysis scoreAnalysisData={allStudentsRangeData?.examResponseDetails} />}
      {isAnalysisSuccess && isBoardSuccess && <SubjectAnalysis analysisData={analysisData} boardData={boardData} />}
      {examForDetailedAnalysis && isAllStudentsRangeDataSuccess && (
        <DifficultyAnalysis
          studentAnalysisDetails={[
            { subject: "Analysing and Evaluating", A: examForDetailedAnalysis.evaluating_and_analysing },
            { subject: "Creating", A: examForDetailedAnalysis.creating_question },
            { subject: "Remembering", A: examForDetailedAnalysis.remembering },
            { subject: "Understanding", A: examForDetailedAnalysis.understanding_questions },
            { subject: "Applying", A: examForDetailedAnalysis.applying_questions }
          ]}
          previousStudentAnalysisDetails={avgScoresInEveryExamType}
        />
      )}
      {isAnalysisSuccess && examForDetailedAnalysis && isAllStudentsRangeDataSuccess && (
        <DetailedAnalysis
          analysisData={analysisData}
          previousStudentAnalysisDetailsForSuperInsights={avgScoresInEveryExamType}
        />
      )}
    </Box>
  );
};

export default NewOverAllStats;
