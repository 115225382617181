// inbuilt modules
import { Box, Divider, useMediaQuery } from "@mui/material";
import React, { useState } from "react";

// external modules
import { Outlet } from "react-router-dom";

// internal modules
import Navbar from "../../../components/Layout/Navbar";
import Sidebar from "../../../components/Layout/Sidebar";

// functions and variables

const Layout = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // useState
  const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile);

  return (
    <Box display="flex">
      <Sidebar drawerWidth="250px" isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <Box
        sx={{ transition: isNonMobile ? "margin-left 0.5s" : "" }}
        marginLeft={isSidebarOpen && isNonMobile ? "250px" : "0px"}
        display={"flex"}
        flexDirection={"column"}
        minHeight={"100dvh"}
        flexGrow={1}
        width={0}
        overflow="hidden"
      >
        <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        <Divider />
        <Box flexGrow={1}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
