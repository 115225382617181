import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const batchAnalysisApiSlice = createApi({
  reducerPath: "batchAnalysisApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/batchAnalysis`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["BatcheAnalysis"],
  endpoints: builder => ({
    addExamToBatchAnalysisforExams: builder.mutation({
      query: ({ batchIds, examId }) => ({
        url: `/add-to-exam-analysis/${examId}`,
        method: "POST",
        body: { batchIds }
      }),
      invalidatesTags: ["BatcheAnalysis"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Linking Batch...", { id: "updateBatch" });
          await queryFulfilled;
          toast.success("Exam Successfully Linked To Batches", { id: "updateBatch" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateBatch" });
        }
      }
    })
  })
});

export const { useAddExamToBatchAnalysisforExamsMutation } = batchAnalysisApiSlice;
