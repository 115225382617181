import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ExamStepperEdit from "../../../components/Supervisor/TestEditor/ExamStepperEdit";
import { useGetAExamQuery } from "../../../redux/api/examSlice";
import { setExamInEdit } from "../../../redux/features/exam/examSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const EditTest = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { testId } = useParams();
  const [examById, setExamById] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  const { data: exam, isSuccess: isGetExamSuccess } = useGetAExamQuery(Number(testId));

  useEffect(() => {
    isGetExamSuccess ? setExamById(exam?.exam) : {};
    dispatch(setExamInEdit(exam?.exam));
  }, [testId, exam]);

  const theme = useTheme();

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "/test-history" },
    { name: `${exam ? exam.exam.exam_name : ""}`, link: "" }
  ];

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 0.75rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Create Exam</title>
        <meta name="description" content="Create exams for students using Scaleyou Insights" />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      {Object.keys(examById)?.length ? (
        <>
          <Box display="flex" gap="0.75rem" flexDirection="column">
            <Typography variant="h2" fontWeight={600}>
              Exam Creator
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.light}>
              Pick up from where you left off!
            </Typography>
          </Box>
          <Box width="100%" borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" p="1rem">
            <ExamStepperEdit examById={examById} />
          </Box>
        </>
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
};

export default EditTest;
