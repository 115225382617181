import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import BatchAdder from "../../../components/Supervisor/ManageBatch/BatchAdder";
import CButton from "../../../components/ui/Button";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const CreateBatch = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem 1rem"}
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Create Batch</title>
        <meta name="description" content="Create Batches for your institutions at ScaleYou Insights" />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box display="flex" gap="0.75rem" justifyContent="space-between" flexDirection={isNonMobile ? "row" : "column"}>
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Manage Batches
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Add and manage student here
          </Typography>
        </Box>
        <Box>
          <CButton
            buttonText="Register Batches"
            variant="primary"
            icon={<Plus color="#fff" size={18} weight="bold" />}
            onClickFun={() => navigate("/manage-batch")}
          />
        </Box>
      </Box>
      <BatchAdder />
    </Box>
  );
};

export default CreateBatch;
