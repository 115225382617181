import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { WarningCircle } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useGetBatchesByStudentQuery, useGetBatchesByStudentWithXpQuery } from "../../../redux/api/batchSlice";
import { useGetLatestResultWithRankbyBatchQuery } from "../../../redux/api/resultsSlice";

const InsightsDashaBoardAnalysis = ({ userData }) => {
  const [examType, setExamType] = useState(0);
  const [batchforCompetitiveExam, setBatchforCompetitiveExam] = useState(0);
  const [batchforBoardExam, setBatchforBoardExam] = useState(0);

  const { data: allBatches, isSuccess: allBatchesFetchingSuccess } = useGetBatchesByStudentQuery({
    schoolId: userData?.school_id,
    studentId: userData?._id
  });

  const { data: studentsWithTheirXp, isSuccess: studentsWithTheirXpSuccess } = useGetBatchesByStudentWithXpQuery(
    {
      schoolId: userData?.school_id,
      studentId: userData?._id,
      examType: userData.examType?.[examType],
      batchId:
        allBatchesFetchingSuccess && allBatches?.batches?.length > 0
          ? allBatches.batches[batchforCompetitiveExam]?._id
          : undefined
    },
    {
      skip: !allBatchesFetchingSuccess || !allBatches?.batches?.length || !userData.examType?.length
    }
  );

  const {
    data: batchForBoard,
    isSuccess: batchForBoardSuccess,
    isLoading: batchForBoardLoading
  } = useGetLatestResultWithRankbyBatchQuery(
    {
      schoolId: userData?.school_id,
      studentId: userData?._id,
      batchId:
        allBatchesFetchingSuccess && allBatches?.batches?.length > 0
          ? allBatches.batches[batchforBoardExam]?._id
          : undefined
    },
    {
      skip: !allBatchesFetchingSuccess || !allBatches?.batches?.length || !userData.examType?.length
    }
  );

  const handleExamTypeChange = event => {
    setExamType(event.target.value);
  };

  const handleBatchforCompetitiveExamChange = event => {
    setBatchforCompetitiveExam(event.target.value);
  };

  const handleBatchforBoardExamChange = event => {
    setBatchforBoardExam(event.target.value);
  };

  const renderTopPerformingStudents = () => (
    <Box
      width="100%"
      position="relative"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="0.5rem 1rem"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <h3 className="font-semibold">Top Performing Students</h3>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={4}>
          {userData.examType?.length > 0 && (
            <FormControl>
              <Select
                value={examType}
                onChange={handleExamTypeChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                {userData.examType.map((exam, index) => (
                  <MenuItem key={index} value={index}>
                    {exam}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {allBatchesFetchingSuccess && allBatches?.batches?.length > 0 && (
            <FormControl>
              <Select
                value={batchforCompetitiveExam}
                onChange={handleBatchforCompetitiveExamChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                {allBatches.batches.map((batch, index) => (
                  <MenuItem value={index} key={index}>
                    {batch.batch_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="10px">
        <WarningCircle size={24} style={{ minWidth: "24px" }} weight="fill" color="#0563FF" />
        <Typography variant="subtitle1" fontWeight={400}>
          Note: 1 Marks = 1 XP
        </Typography>
      </Box>
      <Box>
        <table className="w-[100%] h-[100%]">
          <thead>
            <tr className="flex justify-around bg-[#eaeaea] rounded-t-xl h-[3rem]">
              <th className="text-center ml-5 flex items-center text-lg text-[#030229]">Total XP</th>
              <th className="text-center flex items-center text-lg text-[#030229]">Student Name</th>
            </tr>
          </thead>
          <tbody>
            {studentsWithTheirXpSuccess && studentsWithTheirXp?.length > 0 ? (
              studentsWithTheirXp.map((student, index) => (
                <tr
                  key={index}
                  className={`flex rounded-xl justify-between h-[3rem] items-center ${
                    userData?._id === student.studentId ? "bg-[#f8f8f8]" : ""
                  }`}
                >
                  <td className="w-1/2 text-center">{student?.xp}</td>
                  <td className="w-1/2 text-center">{student?.name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );

  const renderTopPerformingStudentsInBoards = () => (
    <Box
      width="100%"
      position="relative"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="0.5rem 1rem"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <h3 className="font-semibold">Top Performing Students in Boards</h3>
        {allBatchesFetchingSuccess && allBatches?.batches?.length > 0 && (
          <FormControl>
            <Select
              value={batchforBoardExam}
              onChange={handleBatchforBoardExamChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2rem",
                borderRadius: "0.5rem",
                bgcolor: "#fafafb",
                borderColor: "#E6EDFF"
              }}
            >
              {allBatches.batches.map((batch, index) => (
                <MenuItem value={index} key={index}>
                  {batch.batch_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap="10px">
        <WarningCircle size={24} style={{ minWidth: "24px" }} weight="fill" color="#0563FF" />
        <Typography variant="subtitle1" fontWeight={400}>
          Students ranked on the basis of marks from all past exams
        </Typography>
      </Box>
      <Box>
        <table className="w-[100%]">
          <thead>
            <tr className="flex justify-around bg-[#eaeaea] rounded-t-xl h-[3rem]">
              <th className="text-center ml-6 flex items-center text-lg text-[#030229]">Rank</th>
              <th className="text-center flex items-center text-lg text-[#030229]">Student Name</th>
            </tr>
          </thead>
          <tbody>
            {batchForBoardLoading ? (
              <tr>
                <td colSpan="2" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : batchForBoardSuccess && batchForBoard?.studentResults?.length > 0 ? (
              batchForBoard.studentResults.map((item, index) => (
                <tr
                  key={index}
                  className={`flex rounded-xl justify-between h-[3rem] items-center ${
                    userData?._id === item.id ? "bg-[#f8f8f8]" : ""
                  }`}
                >
                  <td className="w-1/2 text-center">{item.rank}</td>
                  <td className="w-1/2 text-center">{item.name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );

  return (
    <div className="flex flex-col gap-4 w-[100%]">
      <section className="flex flex-row gap-4">
        {renderTopPerformingStudents()}
        {renderTopPerformingStudentsInBoards()}
      </section>
    </div>
  );
};

export default InsightsDashaBoardAnalysis;
