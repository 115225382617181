import { Box, InputBase, Typography, styled, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BatchDetails from "../../../components/Teacher/ViewBatchTeacher/BatchDetails";
import BatchStats from "../../../components/Teacher/ViewBatchTeacher/BatchStats";
import { useGetBatchQuery, useGetBatchesQuery } from "../../../redux/api/batchSlice";
import { setBatch, setBatchUserDetails } from "../../../redux/features/batch/batchSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "color": "inherit",
  "width": "100%",
  "fontSize": theme.typography.pxToRem(15),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "width": "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

const ViewBatch = () => {
  const { batchId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const { data: batchData, isSuccess } = useGetBatchesQuery(user.school_id);
  const { data: batchUserData, isSuccess: isBatchUserDataSuccess } = useGetBatchQuery(batchId);

  useEffect(() => {
    if (isSuccess && batchData && batchData.batches) {
      const batch = batchData.batches.find(batch => batch._id === batchId);
      setName(batch?.batch_name || "");
      dispatch(setBatch(batch));
      dispatch(setBatchUserDetails(batchUserData?.batch));
    }
  }, [batchData, batchId, batchUserData]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/view-batch" },
    { name: `${name}`, link: `/view-batch/${batchId}` }
  ];

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Batch Name: {name}
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Manage individual batch here
          </Typography>
        </Box>
      </Box>
      <BatchStats />
      <BatchDetails />
    </Box>
  );
};

export default ViewBatch;
