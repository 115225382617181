// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import { Helmet } from "react-helmet-async";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllStudentAnalysisQuery } from "../../../redux/api/analysisSlice";
import { useGetStudentResultByStudentIdQuery } from "../../../redux/api/resultsSlice";
import { useGetBatchesByStudentQuery } from "../../../redux/api/batchSlice";

// internal modules
import OverallAnalysis from "../../../components/Student/Dashboard/OverallAnalysis";
import SubjectAnalysis from "../../../components/Student/Dashboard/SubjectAnalysis";
import TotalStats from "../../../components/Student/Dashboard/Totalstats";
import TestHistoryStudent from "../../../components/Student/Dashboard/testhistory";
import DashboardDisplay from "../../../components/Teacher/ManageBatch/DashboardDisplay";
import InsightsDashaBoardAnalysis from "../../../components/Student/Dashboard/InsightsAnalysis";
import SuperInsights from "../../../components/Student/Dashboard/SuperInsights";
import BatchCover2 from "../../../Assets/batch-cover-2.png";

const StudentDashboard = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();

  // redux
  const { user } = useSelector(state => state.auth);
  const [batchNames, setbatchNames] = useState([]);
  const { data: analysisData, isSuccess: isAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);
  const { data: boardData, isSuccess: isBoardSuccess } = useGetStudentResultByStudentIdQuery({
    studentId: user._id,
    schoolId: user.school_id
  });

  const { data: batchesOfStudent, isSuccess: isBatchSuccess } = useGetBatchesByStudentQuery({
    schoolId: user?.school_id,
    studentId: user._id
  });

  useEffect(() => {
    if (isBatchSuccess && batchesOfStudent?.batches?.length > 0) {
      const totalBatches = batchesOfStudent.batches.map(batch => batch?.batch_name);
      setbatchNames(totalBatches);
    }
  }, [batchesOfStudent, isBatchSuccess]);

  const [totalExams, setTotalExams] = useState([]);

  useEffect(() => {
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0) {
      analysisData.analysisArray.forEach(exam => {
        if (exam.exam_for) {
          if (exam.exam_for !== "undefined") uniqueExams.add(exam.exam_for);
        }
      });
      setTotalExams(Array.from(uniqueExams));
    }
  }, [analysisData]);

  // render
  return (
    <Box
      padding={`1rem ${isNonMobile ? 2 : 0}rem`}
      display="flex"
      flexDirection="column"
      gap="1rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Dashboard</title>
        <meta name="description" content="Start your detailed Analysis with Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <img src={BatchCover2} alt="banner" height="125px" width="100%" className="rounded-md" />
      <Box display="flex" flexDirection="column">
        <Typography variant="h2" fontWeight={600} color={theme.palette.primary.dark} style={{ padding: "7px" }}>
          Welcome Back, {user.name}
        </Typography>
        <Typography
          variant="subtitle1"
          color={theme.palette.primary.light}
          style={{ padding: "0px", fontSize: "17px" }}
        >
          Here you can get all the information of yourself
        </Typography>
      </Box>
      <TotalStats />
      {batchNames.length === 0 && (
        <Box
          display={"flex"}
          width={"100%"}
          alignSelf={"center"}
          justifySelf={"center"}
          justifyContent={"center"}
          padding={"4px"}
          border="1px solid #3267ff"
        >
          <Box>You are not in any batch</Box>
        </Box>
      )}
      {totalExams.length === 0 && (
        <Box
          display={"flex"}
          width={"100%"}
          alignSelf={"center"}
          justifySelf={"center"}
          justifyContent={"center"}
          padding={"4px"}
          border="1px solid #3267ff"
        >
          <Box>You have not written any exam</Box>
        </Box>
      )}
      {isAnalysisSuccess && totalExams.length > 0 && batchNames.length > 0 && user && (
        <OverallAnalysis userData={user} analysisData={analysisData} totalExams={totalExams} batchNames={batchNames} />
      )}
      {/* <LineGraph /> */}
      {user && <InsightsDashaBoardAnalysis userData={user} />}
      <TestHistoryStudent />
      <DashboardDisplay who="student" />
      {/* Graph section */}
      {isAnalysisSuccess && <SubjectAnalysis analysisData={analysisData} />}
      {isAnalysisSuccess && isBoardSuccess && (
        <SubjectAnalysis analysisData={analysisData} boardData={boardData} typeBoard={true} />
      )}
    </Box>
  );
};

// render
export default StudentDashboard;
