import { Close as CloseIcon } from "@mui/icons-material"; // Importing CloseIcon
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { Bell, List } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../redux/api/authSlice";
import { useFetchProfileDataQuery, useUpdateUserDataMutation } from "../../redux/api/userSlice";
import { clearLocalStorage } from "../../redux/features/auth/authSlice";

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const ProfileContainer = styled(Box)({
  position: "fixed",
  top: "calc(-1% + 7px)" /* Adjust vertical position */,
  right: "0",
  width: "320px" /* Adjust width as needed */,
  background: "rgba(0, 0, 0, 0.5)",
  zIndex: "100100",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const ProfileCard = styled(Box)({
  width: "100%" /* Adjust width as needed */,
  background: "#fff",
  padding: "20px",
  borderRadius: "2px",
  textAlign: "center",
  position: "relative" /* Add relative positioning */
});

const EditButton = styled(IconButton)({
  position: "absolute",
  bottom: "-9px",
  left: "65%",
  transform: "translateX(-35%)",
  color: "#fff"
});

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();

  const { user } = useSelector(state => state.auth);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");

  const { data: completeData, error, loading } = useFetchProfileDataQuery(user);
  const [updateUser, { isLoading, isError, isSuccess }] = useUpdateUserDataMutation();

  const handleToggleProfile = () => {
    if (!isProfileOpen) {
      setIsProfileOpen(true);
      setIsSettingsOpen(false);
    }
  };

  const closeprofile = () => {
    setIsProfileOpen(false);
    setIsSettingsOpen(false);
  };

  const handleToggleSettings = () => {
    if (!isSettingsOpen) {
      setIsSettingsOpen(true);
      setIsProfileOpen(false);
    }
  };

  const saveChanges = async () => {
    if ((phoneNo.length === 10 || phoneNo === "") && fullName !== "") {
      const userInfo = {
        email: completeData?.user.email,
        phone_no: phoneNo,
        name: fullName,
        address_field: address
      };
      await updateUser(userInfo);
    }
  };

  useEffect(() => {
    if (completeData?.user) {
      setFullName(completeData.user?.name || "");
      setPhoneNo(completeData.user?.phone_no || "");
      setAddress(completeData.user?.address_field || "");
    }
  }, [completeData]);

  const handleLogout = async () => {
    await logout();
    dispatch(clearLocalStorage());
    navigate("/");
  };

  return (
    <>
      <AppBar
        sx={{
          background: "none",
          position: "static",
          boxShadow: "none"
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", py: "5px" }}>
          {/* Sidebar toggle button */}
          <IconButton
            color={theme.palette.secondary.main}
            size="large"
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          >
            <List />
          </IconButton>
          <FlexBetween>
            <Bell
              width={25}
              height={25}
              weight="bold"
              color={theme.palette.primary.main}
              style={{ marginRight: "10px" }}
            />
            <Button
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
                bgcolor: "rgb(50, 103, 255, 0.1)",
                borderRadius: "0.75rem",
                padding: "0.5rem 1rem"
              }}
              onClick={handleToggleProfile}
            >
              <Avatar>{fullName.split(" ")[0][0] + (fullName.split(" ")[1] ? fullName.split(" ")[1][0] : "")}</Avatar>
              <Box textAlign="left">
                <Typography fontWeight={500} variant="body1" sx={{ color: theme.palette.secondary.main }}>
                  {fullName}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: theme.palette.primary.light }}>
                  {completeData?.user && completeData?.user?.role === 1
                    ? "Supervisor"
                    : completeData?.user?.role === 2
                      ? "Faculty"
                      : completeData?.user?.role === 3
                        ? "Student"
                        : "SuperAdmin"}{" "}
                </Typography>
              </Box>
            </Button>
          </FlexBetween>
        </Toolbar>
      </AppBar>

      {/* Profile Card */}
      {isProfileOpen && (
        <ProfileContainer onClick={handleToggleProfile}>
          <ProfileCard sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
            <IconButton
              sx={{ position: "absolute", top: "10px", right: "10px", color: theme.palette.secondary.main, zIndex: 10 }}
              onClick={closeprofile}
            >
              <CloseIcon />
            </IconButton>
            <Box position="relative" sx={{ display: "flex", flexDirection: "row" }}>
              <Avatar sx={{ width: "100px", height: "100px", margin: "0 auto 10px", position: "relative" }}>
                {fullName[0]}
              </Avatar>
              {/* <PencilSimple
                size={32}
                color="black"
                style={{
                  backgroundColor: "#EAF0FF",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  left: 160,
                  borderRadius: 100,
                  zIndex: 5
                }}
                onClick={handleProfileImage}
              /> */}
            </Box>

            <Typography variant="h6" sx={{ marginBottom: "10px", color: "#3267FF" }}>
              {fullName}, {completeData?.user?.school_name}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
              {completeData?.user && completeData?.user?.role === 1
                ? "Supervisor"
                : completeData?.user?.role === 2
                  ? "Faculty"
                  : completeData?.user?.role === 3
                    ? "Student"
                    : "SuperAdmin"}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
              <Button
                variant="outlined"
                sx={{
                  "marginRight": "10px",
                  "color": isProfileOpen ? "#3267FF" : "#CCCCCC",
                  "border": "none",
                  "&:hover": {
                    border: "none"
                  }
                }}
                onClick={handleToggleProfile}
              >
                Profile
              </Button>

              <Button
                variant="outlined"
                sx={{
                  "marginRight": "10px",
                  "color": isSettingsOpen ? "#3267FF" : "#CCCCCC",
                  "border": "none",
                  "&:hover": {
                    border: "none"
                  }
                }}
                onClick={handleToggleSettings}
              >
                Settings
              </Button>
              <Button sx={{ color: "#FF0000" }} onClick={handleLogout}>
                Log Out
              </Button>
            </Box>
            {isProfileOpen && (
              <>
                <Typography fontSize="large" sx={{ display: "flex", justifyContent: "left", marginBottom: "20px" }}>
                  Basic Info
                </Typography>
                <TextField
                  fullWidth
                  label="Full Name"
                  variant="outlined"
                  defaultValue={fullName}
                  InputProps={{ style: { color: "#3267FF" }, readOnly: true }}
                  sx={{ marginBottom: "20px" }}
                />
                <TextField
                  fullWidth
                  label={phoneNo ? "Phone number" : "set contact in settings"}
                  disabled={!phoneNo}
                  variant="outlined"
                  defaultValue={phoneNo || ""}
                  InputProps={{ style: { color: "#3267FF" }, readOnly: true }}
                  sx={{ marginBottom: "20px" }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  defaultValue={completeData?.user?.email || ""}
                  InputProps={{ style: { color: "#3267FF" }, readOnly: true }}
                  sx={{ marginBottom: "20px" }}
                />
                <TextField
                  fullWidth
                  label={address ? "address" : "set address in settings"}
                  disabled={!address}
                  variant="outlined"
                  defaultValue={address ? completeData?.user?.address_field : " "}
                  InputProps={{ style: { color: "#3267FF" }, readOnly: true }}
                  sx={{ marginBottom: "20px" }}
                />
              </>
            )}
          </ProfileCard>
        </ProfileContainer>
      )}

      {/* Settings Card */}
      {isSettingsOpen && (
        <ProfileContainer onClick={handleToggleSettings}>
          <ProfileCard sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
            <IconButton
              sx={{ position: "absolute", top: "10px", right: "10px", color: theme.palette.secondary.main }}
              onClick={closeprofile}
            >
              <CloseIcon />
            </IconButton>

            <Avatar sx={{ width: "100px", height: "100px", margin: "0 auto 10px", position: "relative" }}>
              {fullName[0]}
            </Avatar>

            <Typography variant="h6" sx={{ marginBottom: "10px", color: "#3267FF" }}>
              {fullName}, {completeData?.user?.school_name}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
              {completeData?.user.role === 1
                ? "Supervisor"
                : completeData?.user.role === 2
                  ? "Faculty"
                  : completeData?.user.role === 3
                    ? "Student"
                    : "SuperAdmin"}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
              <Button
                variant="outlined"
                sx={{
                  "marginRight": "10px",
                  "color": isProfileOpen ? "#3267FF" : "#CCCCCC",
                  "border": "none",
                  "&:hover": {
                    border: "none"
                  }
                }}
                onClick={handleToggleProfile}
              >
                Profile
              </Button>
              <Button
                variant="outlined"
                sx={{
                  "marginRight": "10px",
                  "color": isSettingsOpen ? "#3267FF" : "#CCCCCC",
                  "border": "none",
                  "&:hover": {
                    border: "none"
                  }
                }}
                onClick={handleToggleSettings}
              >
                Settings
              </Button>
              <Button sx={{ color: "#FF0000" }} onClick={handleLogout}>
                Log Out
              </Button>
            </Box>
            {isSettingsOpen && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem"
                }}
              >
                <TextField
                  fullWidth
                  label="Full Name"
                  type="text"
                  variant="outlined"
                  value={fullName}
                  InputProps={{ style: { color: "#3267FF" } }}
                  onChange={e => setFullName(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Phone number"
                  type="text"
                  variant="outlined"
                  value={phoneNo}
                  InputProps={{ style: { color: "#3267FF" } }}
                  onChange={e => setPhoneNo(e.target.value || "")}
                />

                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  defaultValue={completeData?.user?.email}
                  InputProps={{ style: { color: "#CCCCCC", pointerEvents: "none" } }}
                />
                <Typography
                  variant="subtitle2"
                  fontWeight={400}
                  sx={{ display: "flex", justifyContent: "right", color: "#000000" }}
                >
                  You cannot change this, contact supervisor
                </Typography>
                <TextField
                  fullWidth
                  label="address"
                  type="text"
                  variant="outlined"
                  value={address || " "}
                  InputProps={{ style: { color: "#3267FF" } }}
                  onChange={e => setAddress(e.target.value)}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <Button variant="text" style={{ color: "#000000" }}>
                    Cancel
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: "#3267FF" }} onClick={saveChanges}>
                    Save Changes
                  </Button>
                </Box>
              </Box>
            )}
          </ProfileCard>
        </ProfileContainer>
      )}
    </>
  );
};

export default Navbar;
