import React from "react";
import { Box } from "@mui/material";

const SuperInsightsForDifficultyAnalysis = ({
  studentAnalysisDetails,
  previousStudentAnalysisDetails,
  classAverageAnalysisDetails
}) => {
  const calculateDifference = (current, previous) => {
    return ((current - previous) / Math.abs(previous || 1)) * 100;
  };

  let isImproved = false;
  let performanceChange = 0;
  let decreasedMetrics = [];

  if (previousStudentAnalysisDetails && previousStudentAnalysisDetails.length > 0) {
    // Compare with previous exam and track areas of decreased performance
    performanceChange =
      studentAnalysisDetails.reduce((total, detail, index) => {
        const previousScore = previousStudentAnalysisDetails[index]?.A || 0;
        const currentScore = detail.A || 0;
        const difference = calculateDifference(currentScore, previousScore);

        // Track if current score is lower
        if (difference < 0) {
          decreasedMetrics.push(detail.subject);
        }

        return total + difference;
      }, 0) / studentAnalysisDetails.length;

    isImproved = performanceChange > 0;
  } else if (classAverageAnalysisDetails && classAverageAnalysisDetails.length > 0) {
    // Compare with class average and track areas of decreased performance
    performanceChange =
      studentAnalysisDetails.reduce((total, detail, index) => {
        const classAverageScore = classAverageAnalysisDetails[index]?.A || 0;
        const currentScore = detail.A || 0;
        const difference = calculateDifference(currentScore, classAverageScore);

        if (difference < 0) {
          decreasedMetrics.push(detail.subject);
        }

        return total + difference;
      }, 0) / studentAnalysisDetails.length;

    isImproved = performanceChange > 0;
  }

  // Generate Note message
  const noteMessage =
    decreasedMetrics.length > 0
      ? `Your performance at Difficulty Level ${decreasedMetrics.join(
          ", "
        )} was lower compared to other levels. Identify your weakness in this area and work to turn it into a strength for success in the upcoming JEE 2025.`
      : "Great work! You've maintained or improved across all areas. Keep up the good work as you prepare for JEE 2025!";

  // Array of Pro Tips
  const proTips = [
    "Don’t just memorize formulas—grasp the core concepts. This will help you tackle any type of problem confidently.",
    "Regularly solve previous years' papers and take timed mock tests. It’s essential for improving your speed and accuracy under exam conditions.",
    "Focus on studying effectively rather than just clocking long hours. Concentrate fully during study sessions and take short breaks to stay fresh.",
    "Keep revising what you’ve learned. Top rankers often use the last 2-3 months for intense revision with short notes or flashcards for quick reviews.",
    "Identify your weak areas early and work on them consistently. Turn those weaknesses into strengths over time.",
    "A balanced lifestyle is crucial—get enough sleep, eat well, and exercise regularly. It keeps your mind sharp and focused.",
    "Keep a positive mindset and trust your preparation. Confidence can make a big difference during the actual exam.",
    "Analyze mistakes after every mock test to understand where you went wrong. Make sure you don’t repeat them."
  ];

  // Select a random pro tip
  const randomProTip = proTips[Math.floor(Math.random() * proTips.length)];

  return (
    <Box display={"flex"} flexDirection="column" gap={1}>
      <Box
        display={"flex"}
        flexDirection="column"
        bgcolor="#ffffff"
        border={"1px solid #E6EDFF"}
        borderRadius={"0.5rem"}
        p="0.5rem 0.5rem"
      >
        <Box color="#3267ff" display={"flex"} alignItems={"center"} gap={1}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>

          <h4 className="m-0 font-semibold">Super Insights</h4>
        </Box>
        <Box display="flex" flexDirection="column" mt={1}>
          {isImproved ? (
            <p className="text-[#4F4E69] m-0">
              This exam was a bit more challenging than the previous one, but you performed{" "}
              <span style={{ color: "#3267FF", fontWeight: "bold" }}>{Math.abs(performanceChange.toFixed(1))}%</span>{" "}
              better this time.
            </p>
          ) : (
            <p className="text-[#4F4E69] m-0">
              Although this exam was more challenging than the previous one, your performance fell short by{" "}
              <span style={{ color: "#3267FF", fontWeight: "bold" }}>{Math.abs(performanceChange.toFixed(1))}%</span>.
              Reflect on the areas that were difficult, and use this as an opportunity to improve for next time.
            </p>
          )}
        </Box>
      </Box>
      {/** Note Taking Section */}
      <Box display="flex" gap={1}>
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="#ffffff"
          p="1rem"
          border={"1px solid #E6EDFF"}
          borderRadius={"0.5rem"}
        >
          <Box color="#3267ff" display={"flex"} alignItems={"center"} gap={1}>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>

            <h4 className="m-0 font-semibold">Note</h4>
          </Box>
          <Box mt={1}>
            <p className="text-[#4F4E69] m-0">{noteMessage}</p>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="1rem"
          bgcolor="#ffffff"
          border={"1px solid #E6EDFF"}
          borderRadius={"0.5rem"}
        >
          <Box color="#3267ff" display={"flex"} alignItems={"center"} gap={1}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4 18.845C4 17.4308 4 16.7237 4.43934 16.2843C4.87868 15.845 5.58579 15.845 7 15.845H7.5C8.44281 15.845 8.91421 15.845 9.20711 16.1379C9.5 16.4308 9.5 16.9022 9.5 17.845V22.845H4V18.845Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.5 19.845C15.5 18.9022 15.5 18.4308 15.7929 18.1379C16.0858 17.845 16.5572 17.845 17.5 17.845H18C19.4142 17.845 20.1213 17.845 20.5607 18.2843C21 18.7237 21 19.4308 21 20.845V22.845H15.5V19.845Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.5 22.845H22.5"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 16.845C9.5 15.4308 9.5 14.7237 9.93934 14.2843C10.3787 13.845 11.0858 13.845 12.5 13.845C13.9142 13.845 14.6213 13.845 15.0607 14.2843C15.5 14.7237 15.5 15.4308 15.5 16.845V22.845H9.5V16.845Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.1911 3.42264L13.895 4.84212C13.991 5.03972 14.2469 5.22925 14.4629 5.26554L15.7388 5.47927C16.5547 5.61638 16.7467 6.21321 16.1587 6.80197L15.1668 7.80206C14.9989 7.97143 14.9069 8.29807 14.9589 8.53196L15.2428 9.76997C15.4668 10.7499 14.9509 11.129 14.091 10.6168L12.8951 9.90305C12.6791 9.774 12.3232 9.774 12.1032 9.90305L10.9073 10.6168C10.0514 11.129 9.53146 10.7459 9.75543 9.76997L10.0394 8.53196C10.0914 8.29807 9.99941 7.97143 9.83143 7.80206L8.83954 6.80197C8.2556 6.21321 8.44358 5.61638 9.25949 5.47927L10.5353 5.26554C10.7473 5.22925 11.0033 5.03972 11.0993 4.84212L11.8032 3.42264C12.1872 2.65241 12.8111 2.65241 13.1911 3.42264Z"
                stroke="#3267FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <h4 className="m-0 font-semibold">Pro Tip</h4>
          </Box>
          <Box mt={1}>
            <p className="text-[#4F4E69] m-0">{randomProTip}</p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SuperInsightsForDifficultyAnalysis;
