import { HotTable } from "@handsontable/react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Typography,
  useTheme
} from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import "handsontable/dist/handsontable.full.css";
import { registerAllModules } from "handsontable/registry";
import { registerRenderer, textRenderer } from "handsontable/renderers";
import { HyperFormula } from "hyperformula";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useGetStudentsQuery } from "../../../../redux/api/supervisorSlice";
import { excelData } from "../../../../utils/excelData";
import CButton from "../../../ui/Button";

// Register Handsontable's modules
registerAllModules();

const ResultMarksSetter = ({ publishButtonRef, finalPublishData, setFinalPublishData, publishFinal }) => {
  const hotRef = useRef(null);
  const theme = useTheme();

  const [output, setOutput] = useState("Click 'Load' to load data from server");
  const [isAutosave, setIsAutosave] = useState(true);
  const [open, setOpen] = useState(false);

  const data = excelData;
  const [columnName, setColumnName] = useState("");

  const { user } = useSelector(state => state.auth);
  const { data: studentsData, isSuccess } = useGetStudentsQuery(user.school_id);

  const hyperformulaInstance = HyperFormula.buildEmpty({
    // initialize it with the `'internal-use-in-handsontable'` license key
    licenseKey: "internal-use-in-handsontable"
  });

  // const loadClickCallback = () => {};

  const [errorChecked, setErrorsChecked] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const [totalStudents, setTotalStudents] = useState(0);
  const [wrongRollNumbers, setWrongRollNumbers] = useState([]);
  const [duplicateRollNumbers, setDuplicateRollNumbers] = useState({});

  registerRenderer("customStylesRenderer", (hotInstance, TD, ...rest) => {
    textRenderer(hotInstance, TD, ...rest);

    TD.style.fontWeight = "bold";
    TD.style.color = "green";
    TD.style.background = "#d7f1e1";
  });

  useEffect(() => {
    const hot = hotRef.current.hotInstance;

    hot.loadData(data);
    setOutput("Data loaded");

    hot.updateSettings({
      cells(row, col, prop) {
        const cellProperties = {};

        if (
          hot.getDataAtRowProp(row, prop) === "Subject Names →" ||
          hot.getDataAtRowProp(row, prop) === "Passing Marks →" ||
          hot.getDataAtRowProp(row, prop) === "Total Marks →" ||
          hot.getDataAtRowProp(row, prop) === "Roll No ↓"
        ) {
          cellProperties.editor = false;
        } else {
          cellProperties.editor = "text";
        }

        return cellProperties;
      }
    });
  }, []);

  const saveClickCallback = () => {
    const hot = hotRef.current.hotInstance;
    const finalData = hot.getData();

    if (
      finalData[0][0] !== "Subject Names →" ||
      finalData[1][0] !== "Passing Marks →" ||
      finalData[2][0] !== "Total Marks →" ||
      finalData[3][0] !== "Roll No ↓"
    ) {
      toast.error("The default fields have been changed. Please refresh the page again");
    }

    const wrongRollNumbersIn = [];
    let totalStudentsIn = 0;

    const encounteredRollNumbers = new Set();
    const duplicateRollNumbersIn = {};

    for (let i = 4; i < data?.length; i++) {
      const rollNumber = finalData[i][0];
      const isExist = studentsData?.students?.some(student => student.roll_no === rollNumber);
      const isDuplicate = encounteredRollNumbers.has(rollNumber);
      totalStudentsIn += isExist && !isDuplicate;

      if (isExist) {
        if (isDuplicate) {
          if (!duplicateRollNumbersIn[rollNumber]) {
            duplicateRollNumbersIn[rollNumber] = [];
          }
          duplicateRollNumbersIn[rollNumber].push(i + 1); // Store the row number (i + 1)
        } else {
          encounteredRollNumbers.add(rollNumber);
        }
      } else if (!isExist && finalData[i][0] !== "") {
        wrongRollNumbersIn.push({
          row: i + 1,
          error: rollNumber !== "" ? `Roll No ${rollNumber} is a wrong roll number` : `Row ${i + 1} is empty`
        });
      }
    }
    setTotalStudents(totalStudentsIn);
    setWrongRollNumbers(wrongRollNumbersIn);
    setDuplicateRollNumbers(duplicateRollNumbersIn);
    setFinalPublishData(finalData);
    setOpen(true);
  };

  const addColumnCallback = () => {
    if (columnName === "") {
      toast.error("Please enter column name");
      setColumnName("");
    } else {
      data[0].push(columnName);
      for (let i = 1; i < data.length; i++) {
        if (i === 3) {
          data[i].push("Obtained Marks ↓");
        } else {
          data[i].push("");
        }
      }
      const hot = hotRef.current.hotInstance;

      hot.loadData(data);
      setOutput("Data loaded");
      setColumnName("");
    }
  };

  const addRowCallback = () => {
    data.push(Array.from({ length: data[0].length }));
    const hot = hotRef.current.hotInstance;

    hot.loadData(data);
    setOutput("Data loaded");
  };

  const handleCloseConfirmDialog = () => {
    setOpen(false);
  };

  const handlePublishResult = async () => {
    if (confirmText !== "publish" || !errorChecked) {
      toast.error("Please confirm the data");
      return;
    }
    publishFinal(finalPublishData);
    handleCloseConfirmDialog();
  };

  return (
    <Box padding="1rem">
      <Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box width="100%" display="flex" alignItems="center" gap="5px" justifyContent="flex-end">
            <input
              placeholder="Enter subject name"
              value={columnName}
              onChange={e => setColumnName(e.target.value)}
              style={{ padding: "0rem !important", lineHeight: "1.2rem" }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  addColumnCallback();
                }
              }}
            />
            <Button
              sx={{
                borderRadius: "5px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                bgcolor: "rgba(0,0,0,0.1)"
              }}
              onClick={addColumnCallback}
            >
              <Plus />
              Add Column
            </Button>
          </Box>
        </Box>
      </Box>
      <Box padding="1rem" width="100%" height="100%" gap="10px" display="flex" flexDirection="column">
        <HotTable
          ref={hotRef}
          cell={[
            {
              row: 0,
              col: 0,
              renderer: "customStylesRenderer"
            },
            {
              row: 1,
              col: 0,
              renderer: "customStylesRenderer"
            },
            {
              row: 2,
              col: 0,
              renderer: "customStylesRenderer"
            },
            {
              row: 3,
              col: 0,
              renderer: "customStylesRenderer"
            }
          ]}
          rowHeaders={true}
          colHeaders={true}
          formulas={{
            engine: HyperFormula
            // [plugin configuration]
          }}
          height="auto"
          autoWrapRow={true}
          autoWrapCol={true}
          colWidths={150}
          manualColumnResize={true}
          manualColumnMove={true}
          licenseKey="non-commercial-and-evaluation"
          afterChange={(change, source) => {
            if (source === "loadData") {
              return;
            }

            const hot = hotRef.current.hotInstance;
            const finalData = hot.getData();

            if (isSuccess) {
              for (let i = 0; i < change.length; i++) {
                const [row, col, oldValue, newValue] = change[i];
                if (col === 0 && row > 3) {
                  const isExist = studentsData?.students?.find(student => student.roll_no === newValue);

                  if (!isExist) {
                    toast.error("Invalid Roll No. Not present in list of students!");
                  }
                } else if (col >= 1 && row > 3) {
                  const isCorrect = Number(newValue) <= Number(finalData[2][col]);
                  if (!isCorrect) {
                    toast.error("You are entering more than the total marks!");
                  }
                }
              }
            }
          }}
        />
        <Button
          sx={{
            borderRadius: "5px",
            padding: "5px",
            display: "flex",
            width: "fit-content",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            bgcolor: "rgba(0,0,0,0.05)"
          }}
          onClick={addRowCallback}
        >
          <Plus />
          Add row
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        {/* <button type="button" id="load" className="button button--primary button--blue" onClick={loadClickCallback}>
              Load data
            </button> */}{" "}
        <button
          type="button"
          id="save"
          className="button button--primary button--blue"
          onClick={saveClickCallback}
          ref={publishButtonRef}
          hidden
        >
          Save data
        </button>
      </Box>
      <Dialog open={open} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm before Publishing!</DialogTitle>
        <Box width="100%" p="1rem" display="flex" flexDirection="column" gap="10px">
          <Typography variant="subtitle1" mb="0.2rem">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              You have entered a{" "}
              <span style={{ fontWeight: 600, fontSize: "16px" }}>
                total of {totalStudents} student details correctly.
              </span>
            </div>
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <Typography variant="subtitle1" mb="0.2rem">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              You have entered{" "}
              <span style={{ fontWeight: 600, fontSize: "16px" }}>{wrongRollNumbers.length} wrong roll numbers.</span>
            </div>
            They are in these rows:
            <br /> {wrongRollNumbers.map(error => error.row).join(", ")}.
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <Typography variant="subtitle1" mb="0.2rem">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              You have entered{" "}
              <span style={{ fontWeight: 600, fontSize: "16px" }}>
                {Object.values(duplicateRollNumbers).length} duplicate roll numbers.
              </span>
            </div>
            They are in these rows:
            <br />
            {Object.values(duplicateRollNumbers).flat().join(", ")}.
          </Typography>
          <Divider sx={{ width: "100%" }} />
        </Box>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            Please correct the above errors! If they were intended please proceed to publish
            <Typography variant="h4" mb="0.2rem">
              Check the box if you have confirmed all details!
            </Typography>
            <Divider sx={{ width: "100%" }} />
            <FormControl>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Checkbox
                  aria-label="confirm-checkbox"
                  checked={errorChecked}
                  onChange={e => setErrorsChecked(e.target.checked)}
                />
                Please confirm before publishing
              </Box>
            </FormControl>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" gap="0.75rem">
            <Typography variant="h4" mb="0.2rem">
              Type 'publish' below
            </Typography>
            <Divider sx={{ width: "80%" }} />
            <input
              className="border-2 px-2 py-1 w-60 rounded-md bg-[#F7F6F9] text-[#3266FC]"
              value={confirmText}
              onChange={e => setConfirmText(e.target.value)}
              style={{
                color: theme.palette.secondary.main,
                width: "100%",
                height: "52px",
                padding: "10px",
                backgroundColor: "#F7F6F9",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "5px"
              }}
              placeholder="Type 'publish' here"
              type="text"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <CButton buttonText="Publish Result" variant="primary" onClickFun={handlePublishResult} />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResultMarksSetter;
