export const ReligionList = {
  hindu: "Hindu",
  muslim: "Muslim",
  christian: "Christian",
  buddhist: "Buddhist",
  other: "Other"
};

export const CategoryList = {
  general: "General",
  obc: "OBC",
  sc: "SC",
  st: "ST"
};

export const CasteList = {
  brahmin: "Brahmin",
  kshatriya: "Kshatriya",
  vaishya: "Vaishya",
  other: "Other"
};

export const SubCasteList = {
  arora: "Arora",
  brahman: "Brahman",
  vaishya: "Vaishya",
  shukla: "Shukla",
  bhatia: "Bhatia",
  other: "Other"
};
