import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const adminApiSlice = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/admin`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Supervisors"],
  endpoints: build => ({
    createASupervisor: build.mutation({
      query: supervisorData => ({
        url: "/create-supervisor",
        method: "POST",
        body: supervisorData
      }),
      invalidatesTags: ["Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("creating supervisor...", { id: "createSupervisor" });
          await queryFulfilled;
          toast.success("supervisor created successfully", { id: "createSupervisor" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createSupervisor" });
        }
      }
    }),
    getAllSupervisors: build.query({
      query: () => ({
        url: "/getall",
        method: "GET"
      }),
      providesTags: ["Supervisors"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    deleteSupervisor: build.mutation({
      query: id => ({
        url: `/delete-supervisor/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("deleting supervisor...", { id: "deleteSupervisor" });
          await queryFulfilled;
          toast.success("supervisor deleted successfully", { id: "deleteSupervisor" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteSupervisor" });
        }
      }
    }),
    saveApiKey: build.mutation({
      query: credentials => ({
        url: "/update-api-key",
        method: "PUT",
        body: credentials
      }),
      invalidatesTags: ["Supervisors"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Saving Api Key", { id: "saveCredentials" });
          await queryFulfilled;
          toast.success("Api Key Saved Successfully", { id: "saveCredentials" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "saveCredentials" });
        }
      }
    })
  })
});

export const {
  useCreateASupervisorMutation,
  useGetAllSupervisorsQuery,
  useDeleteSupervisorMutation,
  useSaveApiKeyMutation
} = adminApiSlice;
