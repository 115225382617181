import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import SupervisorManager from "../../../components/SuperAdmin/CreateSupervisor/SupervisorManager";
import CButton from "../../../components/ui/Button";
import { useCreateASupervisorMutation } from "../../../redux/api/adminSlice";
import { useGetAllSchoolQuery } from "../../../redux/api/schoolSlice";
import { toastError } from "../../../utils/ToastHandler";

const CreateSupervisor = () => {
  const theme = useTheme();

  //supervisor
  const [openDialog, setOpenDialog] = useState(false);
  const [supervisorName, setSupervisorName] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  // const { schools } = useSelector(state => state.school); // Assuming you have a schools state in Redux

  const dispatch = useDispatch();
  const handleNameChange = event => {
    setSupervisorName(event.target.value);
  };

  const handleEmailChange = event => {
    setSupervisorEmail(event.target.value);
  };

  const [selectedSchoolName, setSelectedSchoolName] = useState(""); // State to store the selected school name
  const [selectedSchoolId, setSelectedSchoolId] = useState(""); // State to store the selected school IDs
  const { data: schoolsData, isSuccess } = useGetAllSchoolQuery();
  // useEffect(() => {

  //   dispatch(getAllSchools());
  // }, [dispatch]);

  const handleSchoolChange = event => {
    const selectedName = event.target.value;
    setSelectedSchoolName(selectedName);

    const selectedSchool = schoolsData.schools.find(school => school.name === selectedName);

    if (selectedSchool) {
      setSelectedSchoolId(selectedSchool._id);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [createASupervisor, { isloading }] = useCreateASupervisorMutation();
  const handleAddSupervisor = () => {
    const supervisorData = {
      name: supervisorName,
      email: supervisorEmail,
      school_id: selectedSchoolId,
      role: 1,
      rollNo: "-1"
    };
    createASupervisor(supervisorData);
    // dispatch(createASupervisor(supervisorData));
  };

  const handleCreateSupervisor = () => {
    if (supervisorName.trim() === "") {
      return toastError("Please fill all the fields");
    }
    handleAddSupervisor();
  };

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Helmet>
        <title>Insights | Manage Supervisors</title>
        <meta name="description" content="Manage Supervisors with ScaleYou Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Supervisors
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View and manage all Supervisors here
          </Typography>
        </Box>
        <CButton
          buttonText="Create Supervisor"
          variant="primary"
          icon={<Plus color="#fff" size={18} weight="bold" />}
          onClickFun={handleOpenDialog}
        />
      </Box>
      <SupervisorManager />
      {/* Register New Supervisor dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            width: "700px", // Adjust width as needed
            height: "400px" // Adjust height as needed
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", paddingBottom: "3px" }}>Create Supervisor</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px", paddingBottom: "15px" }}>
            Please enter the name of the Supervisor:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={supervisorName} // Connect value to the name state
            onChange={handleNameChange}
            sx={{
              "& .MuiInputBase-root": {
                // Modify input base styles
                fontSize: "16px", // Adjust font size
                backgroundColor: "#fff" // Adjust background color
              },
              "& .MuiInputLabel-root": {
                // Modify input label styles
                fontSize: "15px" // Adjust font size
              }
            }}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            value={supervisorEmail}
            onChange={handleEmailChange}
            sx={{
              "marginTop": "15px",
              "& .MuiInputBase-root": {
                fontSize: "16px",
                backgroundColor: "#fff"
              },
              "& .MuiInputLabel-root": {
                fontSize: "15px"
              }
            }}
          />
          <FormControl fullWidth sx={{ marginTop: "15px" }}>
            <InputLabel>School</InputLabel>
            <Select value={selectedSchoolName} onChange={handleSchoolChange} label="School">
              {isSuccess &&
                schoolsData &&
                schoolsData?.schools?.length &&
                schoolsData.schools.map(school => (
                  <MenuItem key={school.id} value={school.name}>
                    {school.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              height: "44px", // Adjust the height
              width: "98px",
              borderRadius: "7px" // Adjust the width
            }}
          >
            Cancel
          </Button>
          {/* Set the color to black (rgba(0, 0, 0, 0)) */}
          <CButton buttonText="Create" variant="primary" onClickFun={handleCreateSupervisor} />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateSupervisor;
