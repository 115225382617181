import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Download } from "@phosphor-icons/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { FileUpload } from "../../../Assets";
import { CasteList, CategoryList, ReligionList, SubCasteList } from "../../../utils/CasteDetailsList";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontWeight: "400"
}));

const MoreDetails = ({ user, moreDetails, setMoreDetails, isEdit, setIsEdit, handleSubmitMoreDetails }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const allowedFileTypesRegex = /\.(pdf)$/i; // Regex for PDF files
  const [file, setFile] = useState(null);

  const handleChange = event => {
    const { name, value } = event.target;
    setMoreDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const checkFileExtension = selectedFile => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        return true;
      } else {
        toast.error("Please upload a PDF file");
        return false;
      }
    }
  };

  const handleFileChange = (event, fieldName) => {
    const selectedFile = event.target.files[0]; // Access the file from event.target

    if (checkFileExtension(selectedFile)) {
      setMoreDetails(prevDetails => ({
        ...prevDetails,
        [fieldName]: selectedFile
      }));
      toast.success("File uploaded successfully");
    }
  };

  const handleDrop = (e, fieldName) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleFileChange(droppedFile, fieldName);
  };

  const handleDownloadClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const renderFileUploadSection = (label, fieldName, fieldValue) => {
    const inputRef = useRef(null);

    return (
      <Box width="100%" display="flex" flexDirection="column">
        <Typography variant="subtitle1">{label}</Typography>
        <Box
          width="100%"
          display="flex"
          padding="5px"
          alignItems="center"
          border="1px dashed #AFAFAF"
          borderRadius="5px"
          onClick={!fieldValue ? () => inputRef.current.click() : undefined}
          onDragOver={!fieldValue ? e => e.preventDefault() : undefined}
          onDrop={!fieldValue ? e => handleDrop(e, fieldName) : undefined}
          sx={{ cursor: fieldValue ? "default" : "pointer" }}
        >
          <img src={FileUpload} width="64px" alt="Upload icon" />
          <Box display="flex" flexDirection="column" gap="5px" width="100%">
            {fieldValue ? (
              <>
                {typeof fieldValue === "string" ? (
                  <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" fontWeight={500}>
                      File uploaded: {fieldValue?.split("/").pop()}
                    </Typography>
                    <Download
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() => handleDownloadClick(fieldValue)}
                    />
                  </Box>
                ) : (
                  `File selected: ${fieldValue.name}`
                )}
              </>
            ) : (
              <>
                <Typography variant="h6" fontWeight={500}>
                  Drop your file here, or browse
                </Typography>
                <Typography variant="subtitle2" fontWeight={400}>
                  PDF (Max 800x400px - 2Mb)
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <input
          ref={inputRef}
          type="file"
          onChange={e => handleFileChange(e, fieldName)} // Pass event 'e' directly
          name={fieldName}
          accept=".pdf"
          style={{ display: "none" }}
        />

        {fieldValue && (
          <Button
            variant="contained"
            sx={{
              "bgcolor": "secondary.main",
              "height": "24px",
              "display": "flex",
              "gap": "5px",
              "padding": "15px",
              "mt": 1,
              "&:hover": { bgcolor: "secondary.light" }
            }}
            onClick={() => inputRef.current.click()}
          >
            Change File
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
    >
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Caste and Reservation Details
      </Typography>

      {/* Religion and Category */}
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Religion *</Typography>
          <FormControl>
            <Select
              labelId="religion"
              id="religion"
              value={moreDetails.religion}
              onChange={handleChange}
              name="religion"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(ReligionList).map((religion, index) => (
                <MenuItem value={religion} key={index}>
                  {religion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Category *</Typography>
          <FormControl>
            <Select
              labelId="category"
              id="category"
              value={moreDetails.category}
              onChange={handleChange}
              name="category"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(CategoryList).map((category, index) => (
                <MenuItem value={category} key={index}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Caste and Sub-Category */}
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Caste *</Typography>
          <FormControl>
            <Select
              labelId="caste"
              id="caste"
              value={moreDetails.caste}
              onChange={handleChange}
              name="caste"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(CasteList).map((caste, index) => (
                <MenuItem value={caste} key={index}>
                  {caste}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Sub-Category *</Typography>
          <FormControl>
            <Select
              labelId="subCaste"
              id="subCaste"
              value={moreDetails.subCaste}
              onChange={handleChange}
              name="subCaste"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(SubCasteList).map((subCaste, index) => (
                <MenuItem value={subCaste} key={index}>
                  {subCaste}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Family Income */}
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Family Income *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Family Income"
              value={moreDetails.familyIncome}
              onChange={handleChange}
              name="familyIncome"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
      </Box>

      {/* Upload Sections */}
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        {renderFileUploadSection("Upload Caste Certificate", "casteCertificateUrl", moreDetails.casteCertificateUrl)}
        {renderFileUploadSection("Upload Income Certificate", "incomeCertificateUrl", moreDetails.incomeCertificateUrl)}
      </Box>

      {/* Aadhar Card Number */}
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Aadhar Card Number (12 Digits)</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Aadhar Card Number"
              value={moreDetails.aadharNumber}
              onChange={handleChange}
              name="aadharNumber"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        {renderFileUploadSection("Upload Aadhar Card", "aadharUrl", moreDetails.aadharUrl)}
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="70%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Physically Challenged (Handicapped?)</Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={moreDetails.physicallyChallenged}
              onChange={handleChange}
              name="physicallyChallenged"
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {/* Submit Button */}
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{
            "textTransform": "none",
            "backgroundColor": "#333333",
            "borderRadius": "5px",
            "&:hover": { backgroundColor: "#808080" }
          }}
          onClick={handleSubmitMoreDetails}
        >
          {isEdit ? "Update" : "Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default MoreDetails;
