import { Button, Select, MenuItem, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";

const selectTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "1px solid black" // Default border
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid black" // Border on focus
          }
        }
      }
    }
  }
});

const ROI = () => {
  const [selectedField, setSelectedField] = useState("");

  return (
    <main className="relative flex flex-col gap-8 lg:gap-20 w-full">
      <div className="flex flex-col justify-start items-center gap-0">
        <h1 className="font-DMSans text-[60px] font-bold text-wrap text-center text-[#4a3aff]">
          Discover Your Investment Potential
        </h1>
        <h2 className="m-0 font-DMSans font-normal text-[24px] leading-[30px] text-center w-[70%] text-black">
          Unlock the full potential of your investments by accurately calculating your ROI. Understand where your money
          is working best and make informed decisions to maximize your returns.
        </h2>
      </div>

      <div className="flex justify-center">
        <h3 className="m-0 text-center text-[40px] font-medium">
          Maximise Your Gains: <br />
          ROI Calculator at Your Fingertips
        </h3>
      </div>

      <form className="flex flex-col self-center w-[75%] gap-8">
        <div className="flex flex-col">
          <label htmlFor="cName" className="font-DMSans font-normal text-2xl">
            Enter college name*
          </label>
          <input
            className="w-full h-[38px] bg-[#fcfcfc] border-[1px] rounded-lg"
            type="text"
            name="cName"
            id="cName"
            required
          />
        </div>
        <div className="flex flex-col">
          <label className="font-DMSans font-normal text-2xl" htmlFor="nStudents">
            Number of students*
          </label>
          <input
            className="w-full h-[38px] border-[1px] rounded-lg bg-[#fcfcfc]"
            type="text"
            name="nStudents"
            id="nStudents"
            required
          />
        </div>

        <div className="flex flex-col">
          <label className="font-DMSans font-normal text-2xl" htmlFor="AverageFees">
            Average Fees*
          </label>
          <input
            className="w-full h-[38px] bg-[#fcfcfc] border-[1px] rounded-lg"
            type="text"
            name="AverageFees"
            id="AverageFees"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="Course-Stream" className="font-DMSans font-normal text-2xl">
            Course Stream
          </label>
          <ThemeProvider theme={selectTheme}>
            <Select
              name="Course-Stream"
              value={selectedField}
              displayEmpty
              inputProps={{ "aria-label": "Select Field" }}
              variant="outlined"
              sx={{
                "borderRadius": "0.5rem",
                "height": "40px",
                "backgroundColor": "#fcfcfc",

                "&:hover": {
                  border: "1px solid black", // Maintain the border on hover
                  background: "none",
                  backgroundColor: "#fcfcfc", // Maintain the background color on hover
                  transition: "none"
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid black" // Remove the border from the outlined input
                },

                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "2px solid black" // Ensure the border remains removed on focus
                },

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the border on hover
                  transition: "none"
                }
              }}
              className="border-[1px] border-black"
              fullWidth
            >
              <MenuItem value="">
                <em>Select Stream</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </ThemeProvider>
        </div>

        <div className="flex flex-col">
          <label className="font-DMSans font-normal text-2xl" htmlFor="topPercentile">
            No of students in top percentile
          </label>
          <input
            className="w-full h-[38px] bg-[#fcfcfc] border-[1px] rounded-lg"
            type="text"
            name="topPercentile"
            id="topPercentile"
          />
        </div>

        <Button
          type="submit"
          variant="text"
          sx={{
            "backgroundColor": "orange",
            "color": "white",
            "borderRadius": "0.5rem",
            "width": "15%",
            "height": "3%",
            "&:hover": {
              backgroundColor: "#4a3aff"
            }
          }}
        >
          Submit
        </Button>
      </form>

      <div className="flex flex-col w-75% gap-8 justify-center items-center">
        <div className="flex flex-col gap-8 ml-[-7.5rem]">
          <h3 className="m-0 w-full font-DMSans font-normal text-[24px] leading-[30px] text-start text-black">
            Accelerate your institutes success with a skyrocketing{" "}
            <span className="text-[#ff9212] font-bold">Revenue Base</span>, driving growth <br /> and unlocking new
            opportunities for expansion.
          </h3>
          <div className="flex flex-col">
            <label className="font-DMSans font-normal text-2xl" htmlFor="AverageFees">
              Average Fees*
            </label>
            <input
              className="w-[114%] h-[38px] bg-[#fcfcfc] border-[1px] rounded-lg"
              type="text"
              name="AverageFees"
              id="AverageFees"
              required
            />
          </div>
        </div>
        <div className="flex flex-col gap-8 ml-[-7.5rem]">
          <h3 className="m-0 w-full font-DMSans font-normal text-[24px] leading-[30px] text-start text-black">
            Boost student success with measurable <span className="text-[#ff9212] font-bold">Performance</span>{" "}
            improvements that set them on <br /> the path to excellence.
          </h3>
          <div className="flex flex-col">
            <label className="font-DMSans font-normal text-2xl" htmlFor="AverageFees">
              Average Fees*
            </label>
            <input
              className="w-[113.5%] h-[38px] bg-[#fcfcfc] border-[1px] rounded-lg"
              type="text"
              name="AverageFees"
              id="AverageFees"
              required
            />
          </div>
        </div>

        <div className="flex flex-col gap-8 ml-[-9.2rem]">
          <h3 className="m-0 w-full font-DMSans font-normal text-[24px] leading-[30px] text-start text-black">
            Gain back valuable time—<span className="text-[#ff9212] font-bold">Save Time</span> up to 1.5 hours daily
            for faculty and staff with <br />
            streamlined efficiency.
          </h3>
          <div className="flex flex-col">
            <label className="font-DMSans font-normal text-2xl" htmlFor="AverageFees">
              Average Fees*
            </label>
            <input
              className="w-[117.5%] h-[38px] bg-[#fcfcfc] border-[1px] rounded-lg"
              type="text"
              name="AverageFees"
              id="AverageFees"
              required
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <h2 className="m-0 font-DMSans font-normal text-[30px] leading-[30px] text-center w-[70%] text-black">
          Experience unmatched <span className="text-[#ff9212] font-bold">Growth</span> and efficiency with the powerful
          results delivered by our platform.
        </h2>
      </div>
    </main>
  );
};

export default ROI;
