// inbuilt modules
import React, { useMemo } from "react";

// external modules
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

// internal modules
import App from "./App";
import { store } from "./redux/store/store";
import { themeSettings } from "./theme/theme";

const AppWrapper = () => {
  // hooks
  const theme = useMemo(() => createTheme(themeSettings()), []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <HelmetProvider>
            <App />
          </HelmetProvider>
          <Toaster />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

// render
export default AppWrapper;
