// inbuilt modules
import React from "react";

// external modules
import { Helmet } from "react-helmet-async";
import { Box, Chip, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import {
  CalendarDots,
  CaretDown,
  Check,
  CheckCircle,
  CircleNotch,
  Warning,
  WarningCircle
} from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetStudentResultsQuery } from "../../../redux/api/resultsSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

// functions and variables
const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const ViewResults = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { data, isSuccess: isGetResultsSuccess } = useGetStudentResultsQuery({
    school_id: user?.school_id,
    studentId: user?.roll_no
  });

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test Results", link: "" }
  ];

  return (
    <Box width="100%" p="2rem" bgcolor="rgb(250,250,251,1)" height="91vh">
      <Helmet>
        <title>Insights | view Results</title>
        <meta name="description" content="View Results of your previous tests with ScaleYou Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box width="100%" display="flex" flexDirection="column" gap="2rem">
        <Box>
          <Box
            width="100%"
            display="flex"
            flexDirection={isNonMobile ? "row" : "column"}
            justifyContent="space-between"
            alignItems={isNonMobile ? "center" : "start"}
            gap="1rem"
          >
            <Box>
              <Typography variant="h2" fontWeight={600}>
                Test Results
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.light}>
                View Test Results here
              </Typography>
            </Box>
            <Box display="flex" gap="1rem" alignItems="center">
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3A974C" }} />{" "}
                Completed
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#F48C06" }} />{" "}
                Incomplete
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <div style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "#3267FF" }} />{" "}
                Analysing
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <span style={{ fontWeight: 500 }}>NAN</span>: Not analysed
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "85vh", // Set a maximum height for the scrollable container
            overflowY: "auto", // Enable vertical scrolling
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling if needed
            maxWidth: "100%"
          }}
        >
          <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Sl. No
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Test Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "20%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Test Type
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Date of test
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "85%",
                    textAlign: "center",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Status
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.results?.map((test, index) => {
                  const resultDate = new Date(test.resultDate);
                  const now = new Date();
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        borderSpacing: "1rem",
                        cursor: "pointer", // Ensure the cursor is always a pointer
                        transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                      }}
                      onClick={() => navigate(`/view-result/${test._id}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td
                        style={{
                          width: "10%",
                          gap: "8px",
                          height: "4rem",
                          borderLeft: `5px solid ${resultDate < now ? "#3A974C" : "red"}`,
                          borderRadius: "0.5rem"
                        }}
                      >
                        {index + 1}
                      </td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{test.resultName}</td>
                      <td style={{ width: "20%", gap: "8px", height: "4rem" }}>{test.resultFor}</td>
                      <td style={{ width: "10%", gap: "8px", height: "4rem" }}>
                        {new Date(test.examDate).toLocaleDateString("en-IN")}
                      </td>
                      <td style={{ width: "90%", gap: "8px", height: "4rem", textAlign: "center" }}>
                        {resultDate < now ? (
                          <Box
                            display="flex"
                            gap="1rem"
                            width="100%"
                            margin="auto"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CheckCircle size={28} weight="fill" color="#3A974C" />
                            Published
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            gap="1rem"
                            width="100%"
                            margin="auto"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CalendarDots size={28} weight="fill" color="#3A974C" />
                            <Typography variant="subtitle2" fontWeight={400}>
                              Scheduled
                              <br />
                              <span style={{ fontWeight: 500 }}>{resultDate.toLocaleString("en-IN")}</span>
                            </Typography>
                          </Box>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {!data && (
                <tr>
                  <td colSpan="5">
                    <CTypography variant="h6" fontWeight={"500"} sx={{ alignSelf: "center" }}>
                      No results found
                    </CTypography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

// render
export default ViewResults;
