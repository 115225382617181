import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const syllabusApiSlice = createApi({
  reducerPath: "syllabusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Syllabus"],
  endpoints: build => ({
    getEntireSyllabus: build.query({
      query: schoolId => ({
        url: `/supervisor/entire-syllabus/${schoolId}`
      }),
      providesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
        }
      }
    }),
    createASubject: build.mutation({
      query: subjectData => ({
        url: "/subject",
        method: "POST",
        body: { subjectData }
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating A Subject...", { id: "createSubject" });
          await queryFulfilled;
          toast.success("Subject Created Successfully", { id: "createSubject" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createSubject" });
        }
      }
    }),
    updateASubject: build.mutation({
      query: ({ old_name, new_name, school_id }) => ({
        url: `/subject/${old_name}`,
        method: "PUT",
        body: { new_name, school_id }
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating a subject...", { id: "updateSubject" });
          await queryFulfilled;
          toast.success("Subject updated successfully", { id: "updateSubject" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateSubject" });
        }
      }
    }),
    deleteASubject: build.mutation({
      query: id => ({
        url: `/subject/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting subject...", { id: "deleteSubject" });
          await queryFulfilled;
          toast.success("Subject deleted successfully", { id: "deleteSubject" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteSubject" });
        }
      }
    }),
    createASection: build.mutation({
      query: sectionData => ({
        url: "/section",
        method: "POST",
        body: { sectionData }
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating a section...", { id: "createSection" });
          await queryFulfilled;
          toast.success("Section Created Successfully", { id: "createSection" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createSection" });
        }
      }
    }),
    updateASection: build.mutation({
      query: ({ old_name, new_name, school_id }) => ({
        url: `/section/${old_name}`,
        method: "PUT",
        body: { new_name, school_id }
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating a section...", { id: "updateSection" });
          await queryFulfilled;
          toast.success("Section updated successfully", { id: "updateSection" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateSection" });
        }
      }
    }),
    deleteASection: build.mutation({
      query: id => ({
        url: `/section/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting section...", { id: "deleteSection" });
          await queryFulfilled;
          toast.success("Section deleted successfully", { id: "deleteSection" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteSection" });
        }
      }
    }),
    createAChapter: build.mutation({
      query: chapterData => ({
        url: "/chapter",
        method: "POST",
        body: { chapterData }
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating a chapter...", { id: "createChapter" });
          await queryFulfilled;
          toast.success("Chapter Created Successfully", { id: "createChapter" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createChapter" });
        }
      }
    }),
    updateAChapter: build.mutation({
      query: ({ old_name, new_name, school_id }) => ({
        url: `/chapter/${old_name}`,
        method: "PUT",
        body: { new_name, school_id }
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Updating a chapter...", { id: "updateChapter" });
          await queryFulfilled;
          toast.success("Chapter updated successfully", { id: "updateChapter" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "updateChapter" });
        }
      }
    }),
    deleteAChapter: build.mutation({
      query: name => ({
        url: `/chapter/${name}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Syllabus"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Deleting chapter...", { id: "deleteChapter" });
          await queryFulfilled;
          toast.success("Chapter deleted successfully", { id: "deleteChapter" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "deleteChapter" });
        }
      }
    })
  })
});

export const {
  useGetEntireSyllabusQuery,
  useCreateASubjectMutation,
  useUpdateASubjectMutation,
  useDeleteASubjectMutation,
  useCreateASectionMutation,
  useUpdateASectionMutation,
  useDeleteASectionMutation,
  useCreateAChapterMutation,
  useUpdateAChapterMutation,
  useDeleteAChapterMutation
} = syllabusApiSlice;
