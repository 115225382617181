import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllFacultyAnalysisQuery } from "../../../redux/api/analysisSlice";

const TestHistoryTeacher = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllFacultyAnalysisQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "#" }
  ];
  return (
    <Box
      width="100%"
      bgcolor="#ffffff"
      padding="1.5rem"
      position="relative"
      borderRadius="1rem"
      border="1px solid #e6edff"
    >
      <Box width="100%" display="flex" flexDirection="column" gap="2rem">
        <Box>
          <Box
            width="100%"
            display="flex"
            flexDirection={isNonMobile ? "row" : "column"}
            justifyContent="space-between"
            alignItems={isNonMobile ? "center" : "start"}
            gap="1rem"
          >
            <Typography variant="h4" fontWeight={600}>
              Test History
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: "85vh", // Set a maximum height for the scrollable container
            overflowY: "auto", // Enable vertical scrolling
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling if needed
            maxWidth: "100%"
          }}
        >
          <table style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Exam Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Date
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Exam For
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Highest Score
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "15%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Average Score
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "95%",
                    textAlign: "center",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      Status
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isGetAnalysisSuccess ? (
                analysisArray?.analysisArray?.length ? (
                  analysisArray?.analysisArray?.slice(0, 3).map((analysis, index) => (
                    <tr
                      key={analysis.exam_unique_code}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        borderSpacing: "1rem",
                        cursor: "pointer", // Ensure the cursor is always a pointer
                        transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                      }}
                      onClick={() => navigate(`/analyse-exam/${analysis._id}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.exam_name}</td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.exam_date}</td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem" }}>{analysis.exam_for}</td>
                      <td style={{ width: "20%", height: "4rem", gap: "8px" }}>
                        {`${Math.floor(analysis.highest_score)}`}
                      </td>
                      <td style={{ width: "15%", gap: "8px", height: "4rem", color: "#3267ff" }}>
                        {Math.floor(analysis.average_score)}
                      </td>
                      <td
                        style={{
                          padding: "1rem 2rem 1rem 2rem",
                          width: "95%",
                          gap: "8px",
                          height: "4rem",
                          textAlign: "center"
                        }}
                      >
                        <Box
                          pt="0.75rem"
                          pr="2rem"
                          pb="0.75rem"
                          pl="2rem"
                          display="flex"
                          gap="10px"
                          width="100%"
                          margin="auto"
                          alignItems="center"
                          borderRadius="2rem"
                          color="#C75F5F"
                          bgcolor="#faefef"
                          textAlign="center"
                          whiteSpace="nowrap"
                          fontWeight={550}
                        >
                          View Test
                        </Box>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No Analysis Found
                    </td>
                  </tr>
                )
              ) : (
                <>No Analysis Found</>
              )}
            </tbody>
          </table>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Button
          type="button"
          variant="text"
          LinkComponent={Link}
          to="/view-batch"
          className="absolute top-[100%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[0.5rem] h-[26px] border-solid border-[#E6EDFF] border-[1px] text-[#3267ff] cursor-pointer"
        >
          V<span className="lowercase">iew all</span>
        </Button>
      </Box>
    </Box>
  );
};

export default TestHistoryTeacher;
