import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

const ComingSoon = () => {
  return (
    <main>
      <Helmet>
        <title>Insights | Tutorials</title>
        <meta name="description" content="Here are some videos on how you can use Scalyou Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
      </Helmet>
      <Box p="2rem">Coming soon...</Box>;
    </main>
  );
};

export default ComingSoon;
