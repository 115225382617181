import React from "react";
import { Box } from "@mui/material";

const SuperInsightsForUserCurrStandings = ({ superInsightsData }) => {
  const studentScore = superInsightsData?.currentExamDetails?.student_score ?? 0;
  const studentAvgMarks = superInsightsData?.studentAvgMarks ?? 0;
  const classAverage = superInsightsData?.currentExamDetails?.average_score;

  // Calculate the difference
  const calculateDifference =
    superInsightsData?.totalExams > 1 ? studentScore - studentAvgMarks : studentScore - classAverage;

  // Calculate the average of the two values for the denominator
  const average =
    (Math.abs(studentScore) + Math.abs(superInsightsData.totalExams > 1 ? studentAvgMarks : classAverage)) / 2 || 1; // Avoid division by zero

  // Calculate percentage difference
  const percentageDifference = (Math.abs(calculateDifference) / average) * 100;
  const message =
    superInsightsData?.totalExams > 1 && calculateDifference > 0
      ? `Great job! You’ve scored ${percentageDifference.toFixed(
          2
        )}% higher than your average. Keep up the good work—you're making fantastic progress!`
      : `Don't worry! You scored ${percentageDifference.toFixed(
          2
        )}% less than your average. Learn from this, and you’ll come back stronger!`;

  const altMessage =
    superInsightsData?.totalExams === 1 && calculateDifference > 0
      ? `Well done! You’ve scored ${percentageDifference.toFixed(
          2
        )}% higher than the class average. A strong start—keep up the great work!`
      : `Good effort! You scored ${percentageDifference.toFixed(
          2
        )}% below the class average. Use this as motivation to improve in the next exam—you’re capable of great things!`;
  return (
    <Box display={"flex"} bgcolor="#ffffff" border={"1px solid #E6EDFF"} borderRadius={"0.5rem"} p="0.5rem 0.5rem">
      <Box color="#3267ff" display={"flex"} alignItems={"top"}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="12px">
        <h4 className="m-0 font-semibold">Super Insights</h4>
        <p className="text-[#4F4E69] m-0">{message}</p>
      </Box>
    </Box>
  );
};

export default SuperInsightsForUserCurrStandings;
