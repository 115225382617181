import { useTheme } from "@emotion/react";
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateAnnouncementMutation } from "../../../redux/api/announcementSlice";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";
import Selection from "./ui/Selection";

const CreateAnnouncement = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    batch: { batches },
    auth: { user },
    announcements: { announcements }
  } = useSelector(state => state);
  const { data: batchesData, isSuccess: isGetBatchesSuccess } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });
  const [name, setName] = useState("");

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/manage-batch" },
    { name: "Announcements", link: "/create-announcements" },
    { name: "Create-New", link: "#" }
  ];

  const [announcementText, setAnnouncementText] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");
  const dispatch = useDispatch();

  let initialValue = [{ label: null, value: null }];

  const getTextBoxInputValue = input => {
    return input.map(itm => itm.label).join(";");
  };

  const [currentSelection, setCurrentSelection] = useState(getTextBoxInputValue(initialValue));

  const discardHandler = () => {
    setAnnouncementText("");
    setAnnouncementDescription("");
  };

  const [createAnnouncement, { isLoading }] = useCreateAnnouncementMutation();
  const submitHandler = () => {
    if (announcementText.trim() && currentSelection) {
      const obj = {
        topic: announcementText.trim(),
        description: announcementDescription.trim(),
        authorId: user._id,
        author: user._id,
        batchId: currentSelection.split(";"),
        schoolId: user.school_id
      };
      createAnnouncement(obj);
      discardHandler();
    }
  };

  return (
    <main>
      <Box minHeight={"100%"}>
        <div style={{ padding: "1rem" }}>
          <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
        </div>
        <Box padding={"1rem"}>
          <Typography fontWeight={600} fontSize={"1rem"}>
            New Announcement
          </Typography>
          <Typography>Make an announcement for batches</Typography>
        </Box>
        <Box padding={"2rem"}>
          <Box
            border={"0px solid lightgrey"}
            borderRadius={"0.5rem"}
            sx={{ boxShadow: 1 }}
            maxWidth={"1000px"}
            marginX={"auto"}
            overflow={"hidden"}
          >
            <Grid
              container
              width={"100%"}
              sx={{
                "--Grid-borderWidth": "1px",
                "borderRadius": "0.5rem",
                "borderTop": "var(--Grid-borderWidth) solid",
                "borderLeft": "var(--Grid-borderWidth) solid",
                "borderColor": "divider",
                "& > div": {
                  borderRight: "var(--Grid-borderWidth) solid",
                  borderBottom: "var(--Grid-borderWidth) solid",
                  borderColor: "divider"
                }
              }}
            >
              <Grid
                {...{ xs: 12, sm: 12, md: 8, lg: 8 }}
                minHeight={160}
                item
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Box padding={"1rem 1rem"} borderBottom={"1px solid lightgrey"}>
                  <Typography fontWeight={600} fontSize={"1rem"}>
                    Create an announcement
                  </Typography>
                </Box>
                <Box
                  padding={"1rem"}
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    borderBottom: "1px solid lightgrey"
                  }}
                >
                  <Box>
                    <TextField
                      id="outlined-textarea"
                      label="Announcement"
                      placeholder="📢Announcement Title..."
                      multiline
                      fullWidth
                      value={announcementText}
                      onChange={e => setAnnouncementText(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <TextField
                      id="outlined-textarea"
                      // label="Description"
                      placeholder="Text (optional)"
                      multiline
                      fullWidth
                      minRows={4}
                      value={announcementDescription}
                      onChange={e => setAnnouncementDescription(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box sx={{ padding: "0.5rem 1rem" }}>
                  <Button type="button" onClick={discardHandler}>
                    Discard
                  </Button>
                </Box>
              </Grid>
              <Grid
                {...{ xs: 12, sm: 12, md: 4, lg: 4 }}
                minHeight={160}
                item
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Box padding={"1rem 1rem"} borderBottom={"1px solid lightgrey"}>
                  <Typography fontWeight={600} fontSize={"1rem"}>
                    Choose where to share
                  </Typography>
                </Box>
                {batchesData?.batches?.length > 0 && (
                  <Box
                    padding={"1rem"}
                    sx={{
                      flexGrow: "1",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      borderBottom: "1px solid lightgrey",
                      width: "100%"
                    }}
                  >
                    <Selection
                      batches={batchesData.batches}
                      setCurrentSelection={setCurrentSelection}
                      initialValue={initialValue}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    padding: "0.5rem 1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Button
                    onClick={submitHandler}
                    type="button "
                    sx={{ backgroundColor: "rgb(0,122,255)", color: "white" }}
                  >
                    Post
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </main>
  );
};

export default CreateAnnouncement;
