import React from "react";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Text } from "recharts";
import { Box } from "@mui/material";

const data = [
  {
    subject: "Analysing and Evaluating",
    A: 120,
    B: 110,
    fullMark: 150
  },
  {
    subject: "Creating",
    A: 98,
    B: 130,
    fullMark: 150
  },
  {
    subject: "Remembering",
    A: 86,
    B: 130,
    fullMark: 150
  },
  {
    subject: "Understanding",
    A: 99,
    B: 100,
    fullMark: 150
  },
  {
    subject: "Apply",
    A: 99,
    B: 100,
    fullMark: 150
  }
];

const renderLabel = ({ cx, cy, value, index }) => {
  const angle = (360 / data.length) * (index - 1);
  const radius = 130; // Adjust based on chart size
  const x = cx + radius * Math.cos((Math.PI * angle) / 180);
  const y = cy + radius * Math.sin((Math.PI * angle) / 180);

  return (
    <Text x={x} y={y} fontSize={12} fill="#8979FF" textAnchor="inherit">
      {value}
    </Text>
  );
};

const DifficultyAnalysisGraph = ({ studentAnalysisDetails }) => {
  return (
    <Box style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart data={studentAnalysisDetails}>
          {/* Custom PolarGrid */}
          <PolarGrid />
          {/* Custom PolarRadiusAxis with tick display
          <PolarRadiusAxis angle={30} domain={[0, 100]} tickCount={5} /> */}
          {/* Subject Labels */}
          <PolarAngleAxis dataKey="subject" />
          {/* Radar Data */}
          <Radar
            name="Student"
            dataKey="A"
            stroke="#8979FF"
            fill="#8979FF"
            fillOpacity={0.6}
            dot // Show dots at each node
            label={renderLabel} // Add custom label at each node
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DifficultyAnalysisGraph;
