import axios from "axios";
import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { ArrowLeft, CaretDown, Download, FilePdf, FilePpt, Link, Trash, Video } from "@phosphor-icons/react";
import {
  useDeleteFileMutation,
  useDeleteLinkMutation,
  useFetchSectionFilesQuery,
  useUploadFileMutation,
  useUploadLinkMutation
} from "../../../redux/api/taskSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    "borderRadius": 6,
    "marginTop": theme.spacing(1),
    "minWidth": 180,
    "color": theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    "boxShadow":
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

const SessionDetails = ({ sectionDetails, session_name, currentDescription, setCurrentDescription, onFileChange }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const params = useParams();

  const { user } = useSelector(state => state.auth);
  const { batchId, sectionId } = params;
  const theme = useTheme();
  const { data: sectionFiles, isFileFetchLoading } = useFetchSectionFilesQuery({ schoolId: user?.school_id, batchId });

  const [sectionFilesInThisSession, setSectionFilesInThisSession] = useState(
    sectionDetails?.section?.pre_session || []
  );
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [linkName, setLinkName] = useState("");
  const [alignment, setAlignment] = useState("Upload");
  const [fileName, setFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [wrongUrlFormat, setWrongUrlFormat] = useState(false);
  const [emptyLinkFields, setEmptyLinkFields] = useState(false);
  const [sectionToUpload, setSectionToUpload] = useState("");
  const [file, setFile] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const MenuOpen = Boolean(anchorEl);
  const [fileKey, setFileKey] = useState("");
  const [linkToBeDeleted, setLinkToBeDeleted] = useState(false);
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();
  const [uploadLink] = useUploadLinkMutation();
  const [deleteLink] = useDeleteLinkMutation();

  const inputRefFile = useRef();
  const hasPageRendered = useRef(false);

  useEffect(() => {
    setSectionFilesInThisSession(sectionDetails?.section[session_name]);

    // if (description !== undefined) setCurrentDescription(description);
    // else {
    //   setCurrentDescription(sectionDetails?.section?.description?.pre_session || "");
    // }
  }, [session_name, sectionDetails]);

  useEffect(() => {
    if (hasPageRendered.current) {
      if (fileName !== "") {
        handleUpload();
      }
    } else {
      hasPageRendered.current = true;
    }
  }, [fileName]);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteLink = async indiLink => {
    const details = {
      section_id: sectionId,
      filename: indiLink
    };
    await deleteLink(details);
    setLinkToBeDeleted("");
    setUrl("");
    setLinkName("");
  };

  const allowedFileTypesRegex = /\.(pdf|mp4|ppt|txt|pptx)$/i;

  const checkFileExtension = selectedFile => {
    if (selectedFile && allowedFileTypesRegex.test(selectedFile.name)) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteClick = async fileKey => {
    const details = {
      school_id: user?.school_id,
      batch_id: batchId,
      section_id: sectionId,
      file_key: fileKey
    };
    // Add your delete logic here
    deleteFile(details);
    setFileKey("");
  };

  const handleFileChange = e => {
    if (checkFileExtension(e.target.files[0])) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setFileExtension(e.target.files[0]?.name.split(".").pop());
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    if (checkFileExtension(e.dataTransfer.files[0])) {
      setFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
    } else {
      toast.error("Please upload a file with allowed extensions: PDF, MP4, PPT");
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("school_id", user?.school_id);
    formData.append("batch_id", batchId);
    formData.append("section_id", sectionId);
    formData.append("file_extension", fileExtension);
    formData.append("file_name", fileName);
    formData.append("session", session_name);

    try {
      const response = await uploadFile({ formData });
      onFileChange(response.data.file_key);
      setSectionToUpload("");
      setFile(null);
      setFileName("");
      handleClose();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setWrongUrlFormat(false);
    setEmptyLinkFields(false);
    setAlignment("Upload");
    setOpen(false);
  };

  const handlDialogeOpen = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleLinkClick = async () => {
    if (linkName === "" || url === "") {
      setEmptyLinkFields(true);
    } else if (!url.includes("http")) {
      setEmptyLinkFields(false);
      setWrongUrlFormat(true);
    } else {
      const details = {
        school_id: user?.school_id, // Replace with actual school ID
        batch_id: batchId, // Replace with actual batch ID
        section_id: sectionId, // Replace with actual section ID
        session: session_name, // Replace with actual session number
        link_name: linkName, // Replace with actual link name
        link_address: url // Replace with actual URL
      };
      await uploadLink(details);
      handleClose();
    }
  };
  const editorRef = useRef(null);
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo"
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Add description",
      defaultActionOnPaste: "text",
      defaultLineHeight: 1.5,
      enter: "div",
      triggerChangeEvent: true,
      direction: "",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 100,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false
    }),
    []
  );

  const handleFileClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "mp4") {
        window.location.href = `/view-batch/${batchId}/tasks/stream-video?url=${encodeURIComponent(downloadUrl)}`;
      } else {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const handleTextChange = newText => {
    setCurrentDescription(newText);
  };

  useEffect(() => {
    // Set the current description from the sectionDetails when the component mounts or updates
    setCurrentDescription(sectionDetails?.section?.description?.[session_name] || "");
  }, [sectionDetails]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"left"}
        width="100%"
        overflow="hidden"
        marginTop="1rem"
        flexDirection="column"
        gap="0.7rem"
      >
        <Typography variant="h4" fontWeight="500">
          Title Description
        </Typography>
        <JoditEditor
          ref={editorRef}
          value={currentDescription}
          config={config}
          onBlur={handleTextChange}
          onChange={handleTextChange}
          style={{
            width: "100%",
            overflow: "hidden"
          }}
        />
      </Box>
      <Box marginTop="1rem">
        <Typography>Resources</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <Box
            width="100%"
            padding="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="15px"
            border="1px dashed #AFAFAF"
            borderRadius="1rem"
            onDragOver={e => e.preventDefault()}
            onDrop={handleDrop}
          >
            {sectionFiles?.contents?.length > 0 && (
              <List dense={false} sx={{ width: "100%" }}>
                {sectionFiles?.contents
                  ?.filter(
                    file =>
                      file.Key.includes(sectionId) && sectionFilesInThisSession?.includes(file.Key.split("/").pop())
                  )
                  .map((file, index) => (
                    <ListItem key={index}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box display="flex" alignItems="center">
                          <ListItemIcon>
                            {(file.Key.split("/").pop().split(".").pop() === "ppt" ||
                              file.Key.split("/").pop().split(".").pop() === "pptx") && (
                              <FilePpt size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                            {file.Key.split("/").pop().split(".").pop() === "mp4" && (
                              <Video size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                            {file.Key.split("/").pop().split(".").pop() === "pdf" && (
                              <FilePdf size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            )}
                          </ListItemIcon>
                          <ListItemText sx={{ marginLeft: "-1.75rem" }} primary={`${file.Key.split("/").pop()}`} />
                        </Box>
                        <ListItemIcon>
                          <Box>
                            <Tooltip title="Download">
                              <Download
                                size={28}
                                className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                style={{ cursor: "pointer", padding: "0.2rem", marginRight: "1rem" }}
                                onClick={() => handleFileClick(file.Key)}
                              />
                            </Tooltip>
                          </Box>
                          <Box>
                            <Tooltip title="Delete" sx={{ display: "flex", justifyContent: "center" }}>
                              <Trash
                                color="#ff0000"
                                size={28}
                                className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                style={{ cursor: "pointer", padding: "0.2rem" }}
                                onClick={() => {
                                  setFileKey(file.Key);
                                  handlDialogeOpen();
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </ListItemIcon>
                      </Box>
                    </ListItem>
                  ))}
              </List>
            )}
            <List dense={false} sx={{ width: "100%", marginTop: "-1.9rem" }}>
              {sectionDetails?.section?.links.length > 0 &&
                sectionDetails?.section.links
                  .filter(link => sectionFilesInThisSession?.includes(link))
                  .map((Individuallink, index) => {
                    return (
                      <ListItem key={index}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          columnGap="1rem"
                        >
                          <Box display="flex" alignItems="center">
                            <ListItemIcon>
                              <Video size={28} style={{ padding: "0.2rem" }} color="#3267ff" />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ marginLeft: "-1.75rem" }}
                              primary={`${Individuallink?.split(" ")?.slice(0, -1)?.join(" ")}`}
                            />
                          </Box>
                          <ListItemIcon>
                            <Box>
                              <Tooltip title="Delete" sx={{ display: "flex", justifyContent: "center" }}>
                                <Trash
                                  color="#ff0000"
                                  size={28}
                                  className="hover:bg-[#f2f2f2] hover:rounded-lg"
                                  style={{ cursor: "pointer", padding: "0.2rem", marginLeft: "1.8rem" }}
                                  onClick={() => {
                                    setLinkToBeDeleted(Individuallink);
                                    handlDialogeOpen();
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </ListItemIcon>
                        </Box>
                      </ListItem>
                    );
                  })}
            </List>
            <Box display="flex" flexDirection="column" alignItems="center">
              <svg width="41" height="56" viewBox="0 0 41 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_5254_7507)">
                  <rect x="0.5" width="40" height="56" rx="8" fill="#F8FAFC" />
                  <path
                    d="M-1.5 56H23.0178C26.5564 56 28.3522 51.743 25.8827 49.2085L10.0316 32.9403C8.46151 31.3289 5.87182 31.3289 4.30175 32.9403L-4.36492 41.835C-5.09271 42.582 -5.5 43.5836 -5.5 44.6265V52C-5.5 54.2091 -3.70914 56 -1.5 56Z"
                    fill="#DBE8FF"
                  />
                  <path
                    d="M37.5 56H3.81383C0.299953 56 -1.50641 51.794 0.913096 49.2458L22.9326 26.055C24.5097 24.394 27.1569 24.394 28.7341 26.055L40.4007 38.3423C41.1065 39.0856 41.5 40.0715 41.5 41.0965V52C41.5 54.2091 39.7091 56 37.5 56Z"
                    fill="url(#paint0_linear_5254_7507)"
                  />
                  <rect x="9.5" y="11" width="8" height="8" rx="4" fill="#534FFA" />
                </g>
                <rect x="1" y="0.5" width="39" height="55" rx="7.5" stroke="white" />
                <defs>
                  <linearGradient
                    id="paint0_linear_5254_7507"
                    x1="41.5"
                    y1="22.9999"
                    x2="-6.86864"
                    y2="25.1816"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.244074" stopColor="#93BCFF" />
                    <stop offset="1" stopColor="#4285F4" />
                  </linearGradient>
                  <clipPath id="clip0_5254_7507">
                    <rect x="0.5" width="40" height="56" rx="8" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Box display="flex" flexDirection="column" paddingTop="0.6rem">
                <Button
                  variant="contained"
                  // onClick={handleUpload}
                  onClick={handleClickOpen}
                  sx={{ "backgroundColor": "#154fef", "&:hover": { backgroundColor: "#154fef" } }}
                >
                  Upload File
                </Button>

                <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
                  {alignment !== "Upload" && (
                    <IconButton
                      aria-label="back"
                      onClick={() => {
                        setAlignment("Upload");
                        setWrongUrlFormat(false);
                        setEmptyLinkFields(false);
                      }}
                      sx={{
                        position: "absolute",
                        left: 8,
                        top: 10
                        // color: theme => theme.palette.grey[500]
                      }}
                    >
                      <ArrowLeft />
                    </IconButton>
                  )}
                  {alignment === "Upload" && (
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      fontSize="28 !important"
                      display="flex"
                      justifyContent="center"
                      id="customized-dialog-title"
                    >
                      Choose format
                    </DialogTitle>
                  )}

                  {(alignment === "Document" || alignment === "Video") && (
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      fontSize="28 !important"
                      display="flex"
                      justifyContent="center"
                      id="customized-dialog-title"
                    >
                      Upload {alignment}
                    </DialogTitle>
                  )}

                  {alignment === "Link" && (
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      fontSize="28 !important"
                      display="flex"
                      justifyContent="center"
                      id="customized-dialog-title"
                    >
                      Create A Link
                    </DialogTitle>
                  )}

                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8
                      // color: theme => theme.palette.grey[500]
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    {alignment === "Upload" && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        height="20rem"
                        width="100%"
                        alignSelf="center"
                        paddingTop="12%"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          padding="1rem"
                          columnGap="2rem"
                          flexDirection="row"
                          height="8rem"
                          sx={{ width: "100%" }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            border="2px dashed #154fef"
                            sx={{ width: isNonMobile ? "24%" : "35%" }}
                            height="130%"
                            borderRadius="0.6rem"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -4 64 72">
                              <g data-name="Upload File">
                                <path
                                  d="M51 14h-4V9a1.089 1.089 0 0 0-.293-.707l-8-8A1.111 1.111 0 0 0 38 0H18a1 1 0 0 0-1 1v11h-5a3 3 0 0 0-3 3v26a5.006 5.006 0 0 0 5 5h10a1 1 0 0 0 0-2H14a3 3 0 0 1-3-3V15a1 1 0 0 1 1-1h10.89a1.027 1.027 0 0 1 .852.471l4.41 7.059A1 1 0 0 0 29 22h21a1 1 0 0 0 0-2h-3v-4h4a2 2 0 0 1 2 2v23a3 3 0 0 1-3 3H40a1 1 0 0 0 0 2h10a5.006 5.006 0 0 0 5-5V18a4 4 0 0 0-4-4zm-7.414-6H39V3.414zM29.554 20l-4.121-6.6c-.056-.086-.131-.153-.193-.232A2.984 2.984 0 0 0 22.89 12H19V2h18v7a1 1 0 0 0 1 1h7v10z"
                                  style={{ fill: "#28282b" }}
                                />
                                <path
                                  d="M22 6h11a1 1 0 0 0 0-2H22a1 1 0 0 0 0 2zM34 9a1 1 0 0 0-1-1H22a1 1 0 0 0 0 2h11a1 1 0 0 0 1-1zM41 12H29a1 1 0 0 0 0 2h12a1 1 0 0 0 0-2zM41 16H31a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2zM42.924 37.383a1 1 0 0 0-.217-1.09l-10-10a1 1 0 0 0-1.414 0l-10 10A1 1 0 0 0 22 38h5v12a1 1 0 0 0 2 0V37a1 1 0 0 0-1-1h-3.586L32 28.414 39.586 36H36a1 1 0 0 0-1 1v13a1 1 0 0 0 2 0V38h5a1 1 0 0 0 .924-.617zM28 53a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1zM32 53a1 1 0 0 0-1 1v9a1 1 0 0 0 2 0v-9a1 1 0 0 0-1-1zM36 53a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1z"
                                  style={{ fill: "#28282b" }}
                                />
                              </g>
                            </svg>
                            <Button
                              sx={{
                                "marginBottom": "0.3rem",
                                "width": "95%",
                                "padding": "0.6rem",
                                "backgroundColor": "#154fef",

                                "color": "#fff",
                                "&:hover": { backgroundColor: "#154fef" }
                              }}
                              onClick={() => setAlignment("Document")}
                            >
                              Document
                            </Button>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            border="2px dashed #154fef"
                            sx={{ width: isNonMobile ? "24%" : "35%" }}
                            height="130%"
                            borderRadius="0.6rem"
                          >
                            <svg
                              width="64"
                              height="76"
                              xmlns="http://www.w3.org/2000/svg"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              viewBox="0 0 24 24"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path d="M24 23h-24v-21h24v21zm-20-1v-4h-3v4h3zm15 0v-19h-14v19h14zm4 0v-4h-3v4h3zm-6-9.5l-9 5v-10l9 5zm3 .5v4h3v-4h-3zm-16 4v-4h-3v4h3zm5-1.2l5.941-3.3-5.941-3.3v6.6zm11-7.8v4h3v-4h-3zm-16 4v-4h-3v4h3zm16-9v4h3v-4h-3zm-16 4v-4h-3v4h3z" />
                            </svg>
                            <Button
                              sx={{
                                "height": "2.5rem",
                                "width": "95%",
                                "padding": "0.6rem",
                                "backgroundColor": "#154fef",
                                "color": "#fff",
                                "&:hover": { backgroundColor: "#154fef" }
                              }}
                              onClick={handleMenuClick}
                              endIcon={<CaretDown />}
                            >
                              Upload Video
                            </Button>
                          </Box>
                          <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button"
                            }}
                            anchorEl={anchorEl}
                            open={MenuOpen}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                setAlignment("Video");
                                handleMenuClose();
                              }}
                              disableRipple
                            >
                              <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Video style={{ marginRight: "0.5rem" }} />
                                Video
                              </Box>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAlignment("Link");
                                handleMenuClose();
                              }}
                              disableRipple
                            >
                              <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Link style={{ marginRight: "0.5rem" }} />
                                Link
                              </Box>
                            </MenuItem>
                          </StyledMenu>
                        </Box>
                      </Box>
                    )}

                    {(alignment === "Document" || alignment === "Video") && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="1rem"
                        height="20rem"
                        width="100%"
                        paddingTop="3.5rem"
                      >
                        <Box display="flex" rowGap="0.1rem" flexDirection="column">
                          <Typography variant="h6">Upload your {alignment}</Typography>
                          {alignment === "Document" && (
                            <Typography variant="subtitle2" color={theme.palette.primary.light}>
                              Allowed file types: PDF, PPT
                            </Typography>
                          )}
                          {alignment === "Video" && (
                            <Typography variant="subtitle2" color={theme.palette.primary.light}>
                              Allowed file types: MP4
                            </Typography>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          border="2px dashed #154fef"
                          height="36%"
                          width="30%"
                          justifyContent="center"
                          alignContent="center"
                          borderRadius={"0.6rem"}
                        >
                          <svg
                            style={{ alignSelf: "center" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="#000000"
                            viewBox="0 0 256 256"
                          >
                            <path d="M178.34,165.66,160,147.31V208a8,8,0,0,1-16,0V147.31l-18.34,18.35a8,8,0,0,1-11.32-11.32l32-32a8,8,0,0,1,11.32,0l32,32a8,8,0,0,1-11.32,11.32ZM160,40A88.08,88.08,0,0,0,81.29,88.68,64,64,0,1,0,72,216h40a8,8,0,0,0,0-16H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.12A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,100.8,66,8,8,0,0,0,3.2,15.34,7.9,7.9,0,0,0,3.2-.68A88,88,0,0,0,160,40Z" />
                          </svg>

                          <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                "display": "flex",
                                "marginLeft": "0.25rem",
                                "alignSelf": "center",
                                "width": "95%",
                                "backgroundColor": "#154fef",
                                "&:hover": { backgroundColor: "#154fef" }
                              }}
                              // onClick={handleClose}
                            >
                              <input
                                type="file"
                                id="file-upload"
                                ref={inputRefFile}
                                onChange={handleFileChange}
                                name="file"
                                hidden
                              />
                              Upload
                            </Button>
                          </label>
                        </Box>
                      </Box>
                    )}

                    {alignment === "Link" && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="1rem"
                        height="20rem"
                        width="100%"
                        paddingTop="1rem"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          height="100%"
                          width="100%"
                          alignItems="center"
                          justifyContent="center"
                          gap="1rem"
                          marginTop="-2rem"
                        >
                          <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                            <Typography variant="h6" color="#154fef">
                              Name for your URL
                            </Typography>
                            <OutlinedInput
                              error={emptyLinkFields}
                              id="LinkName-inputFiled"
                              value={linkName}
                              onChange={e => setLinkName(e.target.value)}
                              fullWidth
                            />
                          </Box>
                          <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                            <Typography variant="h6" color="#154fef">
                              Paste Your URL
                            </Typography>
                            <OutlinedInput
                              error={wrongUrlFormat || emptyLinkFields}
                              id="Url-inputFiled"
                              value={url}
                              onChange={e => setUrl(e.target.value)}
                              helperText="Make sure to include http:// or https:// in the URL"
                              fullWidth
                            />
                            <Typography
                              variant="h6"
                              fontStyle="italic"
                              sx={{ color: emptyLinkFields || wrongUrlFormat ? "#ff3333" : "#ffffff" }}
                            >
                              {emptyLinkFields
                                ? "Input Fields must not be empty"
                                : "Make sure to include http:// or https:// in the URL"}
                            </Typography>
                          </Box>

                          <Box display="flex" justifyContent="end" width="100%">
                            <Button
                              sx={{
                                "backgroundColor": "#154fef",
                                "color": "#fff",
                                "border": "2px solid #154fef",
                                "&:hover": { backgroundColor: "#154fef" }
                              }}
                              onClick={() => {
                                handleLinkClick();
                              }}
                            >
                              Upload Link
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </DialogContent>
                </BootstrapDialog>
              </Box>
              <Box display="flex">
                <Dialog
                  open={deleteDialogOpen}
                  keepMounted
                  onClose={handlDialogeOpen}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>{"DELETE"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Are you sure you want to delete the file?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handlDialogeOpen}>Cancel</Button>
                    <Button
                      onClick={() => {
                        fileKey ? handleDeleteClick(fileKey) : handleDeleteLink(linkToBeDeleted);
                        handlDialogeOpen();
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SessionDetails;
