import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetchProfileDataQuery } from "../../../redux/api/userSlice";
import { BloodGroups } from "../../../utils/BloodGroups";
import { statesDistricts } from "../../../utils/IndiaStatesDistricts";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontWeight: "400"
}));

const StudentDetails = ({ user, studentDetails, setStudentDetails, isEdit, setIsEdit, handleSubmitStudentDetails }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();

  const handleChange = event => {
    const { name, value } = event.target;
    setStudentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
    >
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Student Details
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">First Name *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="First Name"
              value={studentDetails.firstName}
              onChange={handleChange}
              name="firstName"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Middle Name *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Middle Name"
              value={studentDetails.middleName}
              onChange={handleChange}
              name="middleName"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Last Name *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Last Name"
              value={studentDetails.lastName}
              onChange={handleChange}
              name="lastName"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Father's Name *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Father's Name"
              value={studentDetails.fatherName}
              onChange={handleChange}
              name="fatherName"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
            <Typography variant="subtitle1">Mother's Name *</Typography>
            <FormControl>
              <StyledTextField
                placeholder="Mother's Name"
                value={studentDetails.motherName}
                onChange={handleChange}
                name="motherName"
                type="text"
                InputProps={{
                  inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Address</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Address"
              value={studentDetails.addressLine1}
              onChange={handleChange}
              name="addressLine1"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
            <Typography variant="subtitle1">Pincode</Typography>
            <FormControl>
              <StyledTextField
                placeholder="Pincode"
                value={studentDetails.pincode}
                onChange={handleChange}
                name="pincode"
                type="text"
                InputProps={{
                  inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Address Line 2</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Address Line 2"
              value={studentDetails.addressLine2}
              onChange={handleChange}
              name="addressLine2"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" gap="1.5rem">
          <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
            <Typography variant="subtitle1">State</Typography>
            <FormControl>
              <Select
                labelId="examType"
                id="examType"
                value={studentDetails.state}
                onChange={handleChange}
                name="state"
                sx={{
                  color: "rgba(0,0,0,0.6)",
                  fontSize: "15px",
                  height: "40px"
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {statesDistricts.states.map((stateObj, index) => {
                  return (
                    <MenuItem value={stateObj.state} key={index}>
                      {stateObj.state}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="70%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Gender</Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={studentDetails.gender}
              onChange={handleChange}
              name="gender"
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Date of Birth</Typography>
          <FormControl>
            <input
              className="border-2  px-2 py-1 rounded-md"
              value={studentDetails.dateOfBirth}
              onChange={handleChange}
              name="dateOfBirth"
              style={{
                height: "40px",
                padding: "10px",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "5px"
              }}
              placeholder="Select a Date"
              type="date"
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Blood Group</Typography>
          <FormControl>
            <Select
              labelId="bloodGroup"
              id="bloodGroup"
              value={studentDetails.bloodGroup}
              onChange={handleChange}
              name="bloodGroup"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(BloodGroups).map((bloodGroup, index) => {
                return (
                  <MenuItem value={bloodGroup} key={index}>
                    {bloodGroup}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="1.5rem">
        {isEdit ? (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "lightgreen",
              padding: "5px 10px"
            }}
            onClick={handleSubmitStudentDetails}
          >
            Save Details
          </Button>
        ) : (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "#3267FF",
              color: "#fff",
              padding: "5px 10px"
            }}
            onClick={() => setIsEdit(true)}
          >
            Edit Details
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default StudentDetails;
