import { Box, styled, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { man } from "../../../Assets";
import ContactDetails from "../../../components/Profile/Common/ContactDetails";
import Settings from "../../../components/Profile/Common/Settings";
import FacultyDetails from "../../../components/Profile/Faculty/FacultyDetails";
import {
  useFetchProfileDataQuery,
  useUpdateContactDetailsMutation,
  useUpdateStudentDetailsMutation
} from "../../../redux/api/userSlice";

const StyledTabs = styled(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "minHeight": "15px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#635ee7"
  }
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
  "textTransform": "none",
  "fontWeight": theme.typography.fontWeightRegular,
  "fontSize": theme.typography.pxToRem(15),
  "color": "rgba(0, 0, 0, 0.4)",
  "minHeight": "15px !important",
  "&.Mui-selected": {
    color: "#635ee7"
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)"
  }
}));

const scrollableTabsStyle = {
  "maxWidth": "90vw",
  "overflow": "auto",
  "& .MuiTabs-scroller": {
    overflow: "auto !important"
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "flex-start"
  }
};

const a11yProps = index => {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
};

const batchTabs = [
  {
    name: "Faculty Details"
  },
  {
    name: "Contact Details"
  },
  {
    name: "Settings"
  }
];

const ProfileTeacher = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (isEditingContactDetails || isEditingFacultyDetails) {
      toast.error("Please save the changes before moving to another tab");
      return;
    }
    setValue(newValue);
  };

  const { user } = useSelector(state => state.auth);
  const { data: completeData, error, loading } = useFetchProfileDataQuery(user);
  const [updateStudentDetails] = useUpdateStudentDetailsMutation();
  const [updateContactDetails] = useUpdateContactDetailsMutation();

  const [facultyDetails, setFacultyDetails] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    schoolName: "",
    memberSince: { day: 0, month: 0, year: 0 }
  });
  const [isEditingFacultyDetails, setIsEditingFacultyDetails] = useState(false);

  const [contactDetails, setContactDetails] = useState({
    email: "",
    phone: ""
  });
  const [isEditingContactDetails, setIsEditingContactDetails] = useState(false);

  useEffect(() => {
    const user = completeData?.user || undefined;

    const date = new Date(user.created_at);

    // Get day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    if (user) {
      setFacultyDetails({
        firstName: user?.name || "",
        middleName: user?.middle_name || "",
        lastName: user?.last_name || "",
        schoolName: user?.school_name || "",
        memberSince: { day, month, year }
      });

      setContactDetails({
        email: user?.email || "",
        phone: user?.phone_no || ""
      });
    }
  }, [completeData]);

  const handleSubmitFacultyDetails = () => {
    const data = {
      userId: user._id,
      facultyDetails
    };

    updateStudentDetails(data);

    setIsEditingFacultyDetails(false);
  };

  const handleSubmitContactDetails = () => {
    const data = {
      userId: user._id,
      contactDetails: {
        phone_no: contactDetails.phone
      }
    };

    updateContactDetails(data);

    setIsEditingContactDetails(false);
  };

  const getTab = value => {
    switch (value) {
      case 0:
        return (
          <FacultyDetails
            user={user}
            facultyDetails={facultyDetails}
            setFacultyDetails={setFacultyDetails}
            isEdit={isEditingFacultyDetails}
            setIsEdit={setIsEditingFacultyDetails}
            handleSubmitFacultyDetails={handleSubmitFacultyDetails}
          />
        );
      case 1:
        return (
          <ContactDetails
            user={user}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            isEdit={isEditingContactDetails}
            setIsEdit={setIsEditingContactDetails}
            handleSubmitContactDetails={handleSubmitContactDetails}
          />
        );
      default:
        return <Settings user={user} />;
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="1rem"
      alignItems="center"
      padding="2rem 1rem"
      bgcolor="rgb(250,250,251,1)"
    >
      <Box height="300px" display="flex" gap="10px" alignItems="center" flexDirection="column">
        <img
          src={man}
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            opacity: "0px",
            marginBottom: "0.5rem"
          }}
        />
        <Typography variant="h3" fontWeight={500}>
          {facultyDetails?.firstName} {facultyDetails?.middleName} {facultyDetails?.lastName}
        </Typography>
        <Typography variant="h5" color="rgba(0,0,0,0.4)">
          {facultyDetails?.schoolName}
        </Typography>
        <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
          <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.4167 16.8466C11.0289 16.7355 8.6139 17.3072 6.50716 18.5616C4.8566 19.5444 0.528926 21.5511 3.16477 24.0624C4.45236 25.289 5.8864 26.1664 7.68935 26.1664H14"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.0833 8.08398C18.0833 10.9835 15.7328 13.334 12.8333 13.334C9.93384 13.334 7.58333 10.9835 7.58333 8.08398C7.58333 5.18449 9.93384 2.83398 12.8333 2.83398C15.7328 2.83398 18.0833 5.18449 18.0833 8.08398Z"
              stroke="#101828"
              strokeWidth="2"
            />
            <path
              d="M21 24.667V26.167M21 24.667C19.6504 24.667 18.4614 24.0041 17.764 22.9975M21 24.667C22.3496 24.667 23.5386 24.0041 24.236 22.9975M17.764 22.9975L16.3338 23.917M17.764 22.9975C17.3516 22.4022 17.1111 21.6866 17.1111 20.917C17.1111 20.1473 17.3515 19.4319 17.7639 18.8367M24.236 22.9975L25.6662 23.917M24.236 22.9975C24.6484 22.4022 24.8888 21.6866 24.8888 20.917C24.8888 20.1473 24.6485 19.4319 24.2361 18.8367M21 17.167C22.3497 17.167 23.5388 17.83 24.2361 18.8367M21 17.167C19.6503 17.167 18.4612 17.83 17.7639 18.8367M21 17.167V15.667M24.2361 18.8367L25.6667 17.917M17.7639 18.8367L16.3333 17.917"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          <Typography variant="h5" fontSize="20px" fontWeight={500}>
            Member since {facultyDetails.memberSince.day}/{facultyDetails.memberSince.month}/
            {facultyDetails.memberSince.year}
          </Typography>
        </Box>
      </Box>
      <Box height="100%" width="100%" display="flex" flexDirection="column" gap="15px" alignItems="center">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant={!isNonMobile ? "scrollable" : "standard"}
          scrollButtons={!isNonMobile ? "auto" : false}
          sx={!isNonMobile ? scrollableTabsStyle : { borderBottom: "1px solid rgba(0,0,0,0.2)", maxWidth: "70%" }}
        >
          {batchTabs.map((batch, index) => (
            <StyledTab
              label={batch.name}
              key={index}
              {...a11yProps(index)}
              sx={!isNonMobile ? { minWidth: "auto" } : {}}
            />
          ))}
        </StyledTabs>
        <Box width="100%" height="max-content">
          {getTab(value)}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileTeacher;
