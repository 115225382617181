import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, useMediaQuery, Grid, Paper, Typography } from "@mui/material";
import { StudentStandingGraphs } from "./GraphsComponent/StudentStandingGraphs";

import SuperInsightsForUserCurrStandings from "./SuperInsights/SuperInsightsForUserCurrStandings";

const UserCurrentStandingStats = ({ allStudentsRangeData, studentAnalysisDetails }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const selectedIntervalIndex = allStudentsRangeData?.data?.findIndex(interval => {
    const intervalKey = Object.keys(interval)[0];
    return intervalKey === allStudentsRangeData?.selectedInterval;
  });

  let messageTitle = "";
  let messageSubtitle = "";

  if (selectedIntervalIndex === 9) {
    messageTitle = "Keep going, success is within reach!";
    messageSubtitle = ""; // If you want a subtitle, add here.
  } else if (selectedIntervalIndex >= 3 && selectedIntervalIndex <= 6) {
    messageTitle = "WOW!! Congratulations";
    messageSubtitle = (
      <>
        You scored better than <span style={{ color: "#3267FF" }}>{allStudentsRangeData?.percentile ?? 0}%</span> of all
        the test takers
      </>
    );
  } else if (selectedIntervalIndex < 3) {
    messageTitle = "You're on your way! Keep striving!";
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        width="100%"
        height={isNonMobile ? "500px" : "100%"}
        bgcolor="#fff"
        borderRadius="1rem"
        border="1px solid #E6EDFF"
        p="1rem"
        display="flex"
        justifyContent="start"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        <Box display="flex" width="100%" flexDirection="column" justifyContent="start" alignItems="start">
          <h2 style={{ margin: 0, color: "#000000", fontSize: "1.5rem", fontWeight: 500 }}>{messageTitle}</h2>
          <h3 style={{ margin: 0, fontWeight: "normal" }}>{messageSubtitle}</h3>
        </Box>
        <Box display="flex" flexDirection="row" height="100%" width="100%" gap="2.5rem">
          <Box height="100%" position="relative" width="100%">
            <StudentStandingGraphs data={allStudentsRangeData} />
          </Box>
          <Box display="flex" alignItems="end" marginBottom={3}>
            <Grid container spacing={1} alignItems="stretch">
              {/* First Row */}
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  style={{
                    backgroundColor: "#FAFAFB",
                    padding: "16px",
                    paddingBottom: "1rem",
                    textAlign: "start",
                    height: "100%" // Ensure full height
                  }}
                >
                  <Typography variant="subtitle2" color="#949596">
                    Exam Type
                  </Typography>
                  <Typography variant="h3" fontWeight={"medium"}>
                    {studentAnalysisDetails?.exam_for}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  style={{
                    backgroundColor: "#FAFAFB",
                    padding: "16px",
                    paddingBottom: "1rem",
                    textAlign: "start",
                    height: "100%" // Ensure full height
                  }}
                >
                  <Typography variant="subtitle2" color="#949596">
                    Exam Name
                  </Typography>
                  <Typography variant="h3" fontWeight={"medium"} noWrap="true">
                    {studentAnalysisDetails?.exam_name}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  style={{
                    backgroundColor: "#FAFAFB",
                    padding: "16px",
                    paddingBottom: "1rem",
                    textAlign: "start",
                    height: "100%" // Ensure full height
                  }}
                >
                  <Typography variant="subtitle2" color="#949596">
                    Your Score
                  </Typography>
                  <Typography variant="h3" color="#3267FF" fontWeight={"medium"}>
                    {studentAnalysisDetails?.student_score}/{studentAnalysisDetails?.exam_total_marks}
                  </Typography>
                </Paper>
              </Grid>

              {/* Second Row */}
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  style={{
                    backgroundColor: "#FAFAFB",
                    padding: "16px",
                    paddingBottom: "1rem",
                    textAlign: "start",
                    height: "100%"
                  }}
                >
                  <Typography variant="subtitle2" color="#949596">
                    Your Rank
                  </Typography>
                  <Typography variant="h3" color="#3267FF" fontWeight={"medium"}>
                    #{allStudentsRangeData?.rank}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  style={{
                    backgroundColor: "#FAFAFB",
                    padding: "16px",
                    paddingBottom: "1rem",
                    textAlign: "start",
                    height: "100%" // Ensure full height
                  }}
                >
                  <Typography variant="subtitle2" color="#949596">
                    Average Score
                  </Typography>
                  <Typography variant="h3" fontWeight={"medium"}>
                    {studentAnalysisDetails?.average_score}/{studentAnalysisDetails?.exam_total_marks}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                  elevation={0}
                  style={{
                    backgroundColor: "#FAFAFB",
                    padding: "16px",
                    paddingBottom: "1rem",
                    textAlign: "start",
                    height: "100%" // Ensure full height
                  }}
                >
                  <Typography variant="subtitle2" color="#949596">
                    Highest Score
                  </Typography>
                  <Typography variant="h3" fontWeight={"medium"}>
                    {studentAnalysisDetails?.topper_score}/{studentAnalysisDetails?.exam_total_marks}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <SuperInsightsForUserCurrStandings superInsightsData={allStudentsRangeData.superInsightsDetails} />
    </Box>
  );
};

export default UserCurrentStandingStats;
