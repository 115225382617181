import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Download } from "@phosphor-icons/react";
import axios from "axios";
import toast from "react-hot-toast";
import { FileUpload } from "../../../Assets";
import { ReligionList } from "../../../utils/CasteDetailsList";
import { BoardList } from "../../../utils/BoardList";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontWeight: "400"
}));

const DocumentDetails = ({
  user,
  documentDetails,
  setDocumentDetails,
  isEdit,
  setIsEdit,
  handleSubmitDocumentDetails
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const allowedFileTypesRegex = /\.(pdf)$/i; // Regex for PDF files

  const handleChange = event => {
    const { name, value } = event.target;
    setDocumentDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const checkFileExtension = selectedFile => {
    if (selectedFile) {
      const fileName = selectedFile.name;
      if (allowedFileTypesRegex.test(fileName)) {
        return true;
      } else {
        toast.error("Please upload a PDF file");
        return false;
      }
    }
  };

  const handleFileChange = (event, fieldName) => {
    const selectedFile = event.target.files[0];

    if (checkFileExtension(selectedFile)) {
      setDocumentDetails(prevDetails => ({
        ...prevDetails,
        [fieldName]: selectedFile
      }));
      toast.success("File uploaded successfully");
    }
  };

  const handleDrop = (e, fieldName) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleFileChange({ target: { files: [droppedFile] } }, fieldName);
  };

  const handleDownloadClick = async fileKey => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/task/file-download/presigned`, { fileKey });
      const downloadUrl = response.data.url;
      const fileName = fileKey.split("/").pop();

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file");
    }
  };

  const renderFileUploadSection = (label, fieldName, fieldValue) => {
    const inputRef = useRef(null);

    return (
      <Box width="100%" display="flex" flexDirection="column">
        <Typography variant="subtitle1">{label}</Typography>
        <Box
          width="100%"
          display="flex"
          padding="5px"
          alignItems="center"
          border="1px dashed #AFAFAF"
          borderRadius="5px"
          onClick={!fieldValue ? () => inputRef.current.click() : undefined}
          onDragOver={!fieldValue ? e => e.preventDefault() : undefined}
          onDrop={!fieldValue ? e => handleDrop(e, fieldName) : undefined}
          sx={{ cursor: fieldValue ? "default" : "pointer" }}
        >
          <img src={FileUpload} width="64px" alt="Upload icon" />
          <Box display="flex" flexDirection="column" gap="5px" width="100%">
            {fieldValue ? (
              <>
                {typeof fieldValue === "string" ? (
                  <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" fontWeight={500}>
                      File uploaded: {fieldValue?.split("/").pop()}
                    </Typography>
                    <Download
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() => handleDownloadClick(fieldValue)}
                    />
                  </Box>
                ) : (
                  `File selected: ${fieldValue.name}`
                )}
              </>
            ) : (
              <>
                <Typography variant="h6" fontWeight={500}>
                  Drop your file here, or browse
                </Typography>
                <Typography variant="subtitle2" fontWeight={400}>
                  PDF (Max 800x400px - 2Mb)
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <input
          ref={inputRef}
          type="file"
          onChange={e => handleFileChange(e, fieldName)}
          name={fieldName}
          accept=".pdf"
          style={{ display: "none" }}
        />

        {fieldValue && (
          <Button
            variant="contained"
            sx={{
              "bgcolor": "secondary.main",
              "height": "24px",
              "display": "flex",
              "gap": "5px",
              "padding": "15px",
              "mt": 1,
              "&:hover": { bgcolor: "secondary.light" }
            }}
            onClick={() => inputRef.current.click()}
          >
            Change File
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
    >
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Document Details
      </Typography>
      <Typography variant="h5" fontSize="15px" fontWeight={500}>
        10th Standard Details
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Board</Typography>
          <FormControl>
            <Select
              labelId="tenthBoardName"
              id="religion"
              value={documentDetails.tenthBoardName}
              onChange={handleChange}
              name="tenthBoardName"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(BoardList).map((board, index) => (
                <MenuItem value={board} key={index}>
                  {board}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Percentage / CGPA</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Percentage / CGPA"
              value={documentDetails.tenthBoardMarks}
              onChange={handleChange}
              name="tenthBoardMarks"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        {renderFileUploadSection("Upload Certificate", "tenthBoardCertUrl", documentDetails.tenthBoardCertUrl)}
        <Box width="100%" display="flex" flexDirection="column" />
      </Box>
      <Typography variant="h5" fontSize="15px" fontWeight={500} mt="10px">
        11th Standard Details
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Board</Typography>
          <FormControl>
            <Select
              labelId="eleventhtBoardName"
              id="eleventhtBoardName"
              value={documentDetails.eleventhtBoardName}
              onChange={handleChange}
              name="eleventhtBoardName"
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "15px",
                height: "40px"
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.values(BoardList).map((board, index) => (
                <MenuItem value={board} key={index}>
                  {board}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column">
          <Typography variant="subtitle1">Percentage / CGPA</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Percentage / CGPA"
              value={documentDetails.eleventhtBoardMarks}
              onChange={handleChange}
              name="eleventhtBoardMarks"
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        {renderFileUploadSection("Upload Certificate", "eleventhtBoardCertUrl", documentDetails.eleventhtBoardCertUrl)}
        <Box width="100%" display="flex" flexDirection="column" />
      </Box>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="1.5rem">
        {isEdit ? (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "lightgreen",
              padding: "5px 10px"
            }}
            onClick={handleSubmitDocumentDetails}
          >
            Save Details
          </Button>
        ) : (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "#3267FF",
              color: "#fff",
              padding: "5px 10px"
            }}
            onClick={() => setIsEdit(true)}
          >
            Edit Details
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DocumentDetails;
