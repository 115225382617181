import React from "react";
import { Box, Typography } from "@mui/material";
import SectionAnalysisGraph from "./Graphs/SectionAnalysisGraph";

const SectionAnalysis = ({ sectionAnalysisData, subjectAlignment, syllabusData }) => {
  return (
    <Box display="flex" flexDirection="column" gap="0.75rem" width="100%" height="100%" position="relative">
      <Typography variant="h3" fontWeight="medium" fontSize="24px">
        Section Analysis
      </Typography>
      <SectionAnalysisGraph
        sectionAnalysisData={sectionAnalysisData}
        subjectAlignment={subjectAlignment}
        syllabusData={syllabusData}
      />
    </Box>
  );
};

export default SectionAnalysis;
