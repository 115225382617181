import {
  Box,
  Chip,
  CircularProgress,
  InputBase,
  MenuItem,
  Select,
  Typography,
  alpha,
  styled,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { CaretDown, CheckCircle, CursorClick, MagnifyingGlass } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import BreadcrumbsComp from "../../../components/Student/ViewBatchStudent/Breadcrumbs";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";
import { useFetchStudentsOfFacultyQuery } from "../../../redux/api/facultySlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const Search = styled("div")(({ theme }) => ({
  "position": "relative",
  "borderRadius": "2rem",
  "border": "1px solid #C7D7FF",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03)
  },
  "width": "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "color": "inherit",
  "width": "100%",
  "fontSize": theme.typography.pxToRem(15),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "width": "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

const StudentAnalysis = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();

  const [selectedValue, setSelectedValue] = useState("all");
  const [studentAnalysis, setStudentAnalysis] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);

  const { data: studentsOfFaculty, isSuccess: isGetStudentsSuccess } = useFetchStudentsOfFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const { data: batchesData, isSuccess: isGetBatchesSuccess } = useGetBatchesByFacultyQuery({
    school_id: user.school_id,
    faculty_id: user._id
  });

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleSearchFieldChange = event => {
    setChecked(event.target.checked);
  };

  const handleSearch = event => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (isGetStudentsSuccess && isGetBatchesSuccess) {
      let filteredStudents = studentsOfFaculty?.students || [];

      if (selectedValue !== "all") {
        const selectedBatch = batchesData?.batches.find(batch => batch._id === selectedValue);
        filteredStudents = filteredStudents.filter(student => selectedBatch?.students.includes(student.student_id));
      }

      if (searchQuery) {
        filteredStudents = filteredStudents.filter(student =>
          checked
            ? student.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
            : student.student_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      setStudentAnalysis(filteredStudents);
    }
  }, [studentsOfFaculty, selectedValue, searchQuery, checked]);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Student", link: "#" }
  ];

  return (
    <Box width="100%" p="2rem" bgcolor="rgb(250,250,251,1)" height="91vh">
      <div style={{ padding: "1rem 0", marginTop: "-2rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Helmet>
        <title>Insights | Manage Student</title>
        <meta name="description" content="Manage your Student with the help of ScaleYou insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
        <meta property="og:title" content="Insights | Dashboard" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Manage your Student with the help of ScaleYou insights." />
        <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg" />
        <meta property="og:url" content="http://localhost:3000/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box width="100%" display="flex" flexDirection="column" gap="2rem">
        <Box
          width="100%"
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          justifyContent="space-between"
          alignItems={isNonMobile ? "center" : "start"}
          gap="1rem"
        >
          {/* HEADLINE CODE */}

          <Box>
            <Typography variant="h2" fontWeight={600}>
              Test History
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.light}>
              View and analyze student performance
            </Typography>
          </Box>
          <Box display="flex" width={isNonMobile ? "25%" : "100%"} alignItems="center">
            <Typography variant="h6" width="40%">
              Select Batch to filter:
            </Typography>
            <Select
              id="Batch"
              name="Batch"
              size="small"
              displayEmpty
              value={selectedValue}
              onChange={handleChange} // event handled here on change
              placeholder="Batch"
              sx={{
                "width": "60%",
                "height": isNonMobile ? "40px" : "30px",
                "& .MuiSelect-icon": {
                  color: "black" // Adjust icon color as needed
                },
                "& .MuiSelect-root": {
                  color: "black" // Adjust text color as needed
                }
              }}
            >
              <MenuItem value="all" defaultChecked>
                All batches
              </MenuItem>
              {isGetBatchesSuccess ? (
                batchesData?.batches?.map(batch => (
                  <MenuItem key={batch._id} value={batch._id}>
                    {batch.batch_name}
                  </MenuItem>
                ))
              ) : (
                <>Loading...</>
              )}
            </Select>
          </Box>
        </Box>
        {/* dropdown ended here */}
        <Box
          width="100%"
          display="flex"
          flexDirection={isNonMobile ? "row" : "column"}
          justifyContent="flex-end"
          alignItems={isNonMobile ? "center" : "start"}
          gap="1rem"
        >
          <Box display="flex" gap="1rem">
            <Box display="flex" gap="5px" alignItems="center">
              <Typography variant="h6" fontWeight={400}>
                Search with:
              </Typography>
              <Typography variant="h6">Student Name</Typography>
              {/* <MaterialUISwitch checked={checked} onChange={handleSearchFieldChange} /> */}
              <Typography variant="h6">Exam Name</Typography>
            </Box>
            <Search>
              <SearchIconWrapper>
                <MagnifyingGlass color="#3267FF" weight="bold" size={20} />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} onChange={handleSearch} />
            </Search>
          </Box>
        </Box>

        {/* Table Headers */}
        <Box
          sx={{
            maxHeight: "80vh", // Set a maximum height for the scrollable container
            overflowY: "auto", // Enable vertical scrolling
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling if needed
            maxWidth: "100%"
          }}
        >
          {!isGetStudentsSuccess && (
            <Box width={"100%"} height={"100%"} display={"flex"} justifyContent="center" sx={{ overflowY: "hidden" }}>
              <CircularProgress
                sx={{
                  color: "#006bd6"
                }}
              />
            </Box>
          )}
          {isGetStudentsSuccess &&
            (studentAnalysis.length ? (
              <table
                style={{ width: "100%", textAlign: "center", borderCollapse: "seperate", borderSpacing: "0 1rem" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "5%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Sl. No
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                    <th
                      style={{
                        width: "15%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Roll Number
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                    <th
                      style={{
                        width: "15%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Name
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                    <th
                      style={{
                        width: "15%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Exam Name
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                    <th
                      style={{
                        width: "20%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Date
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                    <th
                      style={{
                        width: "10%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Overall Score
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                    <th
                      style={{
                        width: "95%",
                        padding: isNonMobile ? "0.5rem" : "1rem",
                        gap: "8px"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          Analysis
                        </Typography>
                        <CaretDown weight="fill" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studentAnalysis.length &&
                    studentAnalysis.map((student, index) => (
                      <tr
                        key={student._id}
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "1rem",
                          borderSpacing: "1rem",
                          cursor: "pointer", // Ensure the cursor is always a pointer
                          transition: "box-shadow 0.3s ease" // Smooth transition for the shadow
                        }}
                        onClick={() => navigate(`/analyse-student/${student._id}`)}
                        onMouseEnter={e => {
                          e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                        }}
                        onMouseLeave={e => {
                          e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                        }}
                      >
                        <td
                          style={{
                            width: "5%",
                            gap: "8px",
                            height: "4rem",
                            borderLeft: "5px solid #3A974C",
                            borderRadius: "0.5rem"
                          }}
                        >
                          {index + 1}
                        </td>
                        <td style={{ width: "15%", gap: "8px", height: "4rem" }}> {student.roll_no}</td>
                        <td style={{ width: "15%", gap: "8px", height: "4rem" }}> {student.student_name}</td>
                        <td style={{ width: "15%", gap: "8px", height: "4rem" }}> {student.exam_name}</td>
                        <td style={{ width: "15%", gap: "8px", height: "4rem" }}> {student.exam_date}</td>
                        <td style={{ width: "10%", gap: "8px", height: "4rem" }}> {student.student_score}</td>
                        <td style={{ width: "85%", gap: "8px", height: "4rem" }}>
                          <Box display="flex" gap="2rem" width="60%" margin="auto" alignItems="center">
                            <CheckCircle size={28} weight="fill" color="#3A974C" /> Analysis Done
                          </Box>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <Typography variant="h5" width="100%" textAlign="center" py="0.5rem" px="0.6rem">
                No Students in this batch
              </Typography>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default StudentAnalysis;
