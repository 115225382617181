import { Box, Typography } from "@mui/material";
import React from "react";
import NeedPracticeScale from "../../../Assets/StudentAnalysis/Need-Practice.png";
import OverdueScale from "../../../Assets/StudentAnalysis/overdue.png";
import PrettyGoodScale from "../../../Assets/StudentAnalysis/Pretty-good.png";
import StillStrongScale from "../../../Assets/StudentAnalysis/Still-Strong.png";

const StrengthAnalysis = ({ ChapterDetails }) => {
  const findScaleImage = strength => {
    if (strength <= 25) {
      return OverdueScale;
    } else if (strength <= 50) {
      return NeedPracticeScale;
    } else if (strength <= 75) {
      return PrettyGoodScale;
    } else if (strength <= 100) {
      return StillStrongScale;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#fff"
      width="100%"
      height="100%"
      mt="1rem"
      p="1rem 1rem"
      borderRadius="0.5rem"
      alignItems="start"
      justifyContent="start"
    >
      <Typography variant="h3" fontWeight="medium">
        Strength Analysis
      </Typography>
      <Box display="flex" justifyContent="space-between" gap={1}>
        <Box width="70%">
          <div className="w-full max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <Box
              sx={{
                maxHeight: "calc(5 * 4rem)", // Adjust according to your row height
                overflowY: "auto"
              }}
            >
              <table className="w-full">
                <thead className="sticky top-0 bg-[#eaeaea]">
                  <tr className="border-b">
                    <th className="text-left py-3 px-4 font-semibold text-gray-600">Chapter Name</th>
                    <th className="text-left py-3 px-4 font-semibold text-gray-600">Strength</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(ChapterDetails).map((chapterName, index) => {
                    const chapterData = Object.values(ChapterDetails)[index];
                    const strength = chapterData?.total > 0 ? (chapterData.correct * 100) / chapterData.total : 0; // Prevent division by zero

                    return (
                      <tr key={chapterName} className="bg-white">
                        <td className="py-3 px-4 text-gray-800">{chapterName}</td>
                        <td className="py-3 px-4">
                          <img src={findScaleImage(strength)} alt="Scale" width="80" height="24" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="#fff"
          border="1px solid #E6EDFF"
          width="35%"
          height="100%"
          p="1rem 1rem"
          gap={2}
          borderRadius="0.5rem"
          flexWrap="wrap"
        >
          <Typography>How Strength Analysis Works?</Typography>
          <Typography>
            ScaleYou’s algorithms determine the strength of each chapter by analyzing its overall performance up to the
            present.
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={2} alignItems="center">
              <img src={StillStrongScale} alt="Still-Stong" width="80" height="24" />
              <Typography fontWeight="medium">Still Strong</Typography>
            </Box>
            <Box display="flex" gap={2} alignItems="center">
              <img src={PrettyGoodScale} alt="Pretty-Good" width="80" height="24" />
              <Typography fontWeight="medium">Pretty Good</Typography>
            </Box>
            <Box display="flex" gap={2} alignItems="center">
              <img src={NeedPracticeScale} alt="Need-Practice" width="80" height="24" />
              <Typography fontWeight="medium">Need Practice</Typography>
            </Box>
            <Box display="flex" gap={2} alignItems="center">
              <img src={OverdueScale} alt="Overdue" width="80" height="24" />
              <Typography fontWeight="medium">Overdue</Typography>
            </Box>
          </Box>
          <Typography>
            Want to improve your strengths? <span className="underline">Ask ScaleYou AI</span> for tips, tricks, or a
            personalized timetable to ace in each subject.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StrengthAnalysis;
