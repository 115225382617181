import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const userApiSlice = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/user`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["User"],
  endpoints: builder => ({
    fetchProfileData: builder.query({
      query: user => ({
        url: "/fetch-user-data",
        method: "POST",
        body: { userData: user }
      }),
      providesTags: ["User"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error("Failed to fetch user data");
        }
      }
    }),
    updateUserData: builder.mutation({
      query: userInfo => ({
        url: "/update-user",
        method: "POST",
        body: userInfo
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updateUser" });
          await queryFulfilled;
          toast.success("Fields updated", { id: "updateUser" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updateUser" });
        }
      }
    }),
    updateStudentDetails: builder.mutation({
      query: ({ userId, studentDetails }) => ({
        url: `/${userId}/student-details`,
        method: "PATCH",
        body: studentDetails
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updateStudentDetails" });
          await queryFulfilled;
          toast.success("Student details updated", { id: "updateStudentDetails" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updateStudentDetails" });
        }
      }
    }),
    updateContactDetails: builder.mutation({
      query: ({ userId, contactDetails }) => ({
        url: `/${userId}/contact-details`,
        method: "PATCH",
        body: contactDetails
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updateContactDetails" });
          await queryFulfilled;
          toast.success("Contact details updated", { id: "updateContactDetails" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updateContactDetails" });
        }
      }
    }),
    updatePersonalDetails: builder.mutation({
      query: ({ userId, personalDetails }) => ({
        url: `/${userId}/personal-details`,
        method: "PATCH",
        body: personalDetails
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updatePersonalDetails" });
          await queryFulfilled;
          toast.success("Personal details updated", { id: "updatePersonalDetails" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updatePersonalDetails" });
        }
      }
    }),
    updateMoreDetails: builder.mutation({
      query: formData => ({
        url: `/${formData.get("userId")}/more-details`, // Extract userId from FormData
        method: "POST",
        body: formData
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updateMoreDetails" });
          await queryFulfilled;
          toast.success("More details updated", { id: "updateMoreDetails" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updateMoreDetails" });
        }
      }
    }),
    updateDocumentDetails: builder.mutation({
      query: ({ userId, documentDetails }) => ({
        url: `/${userId}/document-details`,
        method: "PATCH",
        body: documentDetails
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Loading...", { id: "updateDocumentDetails" });
          await queryFulfilled;
          toast.success("Document details updated", { id: "updateDocumentDetails" });
        } catch ({ error }) {
          console.log(error);
          const message = error.data?.message || "Internal server error";
          toast.error(message, { id: "updateDocumentDetails" });
        }
      }
    })
  })
});

export const {
  useFetchProfileDataQuery,
  useUpdateUserDataMutation,
  useUpdateStudentDetailsMutation,
  useUpdateContactDetailsMutation,
  useUpdatePersonalDetailsMutation,
  useUpdateMoreDetailsMutation,
  useUpdateDocumentDetailsMutation
} = userApiSlice;
