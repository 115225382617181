import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const performanceApiSlice = createApi({
  reducerPath: "performanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/performance`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Performance"],
  endpoints: build => ({
    getStudentPerformanceInRange: build.query({
      query: ({ student_id, school_id, exam_for, startDate, endDate }) => ({
        url: "/getStudentPerformanceInRange",
        method: "POST",
        body: { student_id, school_id, exam_for, startDate, endDate }
      }),
      providesTags: ["Performance"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Issue", { id: "createIssue" });
          await queryFulfilled;
          toast.success("Issue Created Successfully", { id: "createIssue" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    }),
    getLatestExamPerformance: build.query({
      query: ({ student_id, school_id, exam_for }) => ({
        url: "/getLatestExamPerformance",
        method: "POST",
        body: { student_id, school_id, exam_for }
      }),
      providesTags: ["Performance"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getSchoolStudentStats: build.query({
      query: ({ student_id, school_id }) => ({
        url: "/getSchoolStudentStats",
        method: "POST",
        body: { student_id, school_id }
      }),
      providesTags: ["Performance"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getSchoolFacultyStats: build.query({
      query: ({ faculty_id, school_id }) => ({
        url: "/getSchoolFacultyStats",
        method: "POST",
        body: { faculty_id, school_id }
      }),
      providesTags: ["Performance"],
      async onQueryFulfilled(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getStudentPerformanceInRangeByBatch: build.query({
      query: ({ student_id, school_id, batch_name, startDate, endDate }) => ({
        url: "/getStudentPerformanceInRangeByBatch",
        method: "POST",
        body: { student_id, school_id, batch_name, startDate, endDate }
      }),
      providesTags: ["Performance"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    }),
    getStudentPerformanceInRangeByBatchForFaculty: build.query({
      query: ({ faculty_id, school_id, batch_name, startDate, endDate }) => ({
        url: "/getStudentPerformanceInRangeByBatchForFaculty",
        method: "POST",
        body: { faculty_id, school_id, batch_name, startDate, endDate }
      }),
      providesTags: ["Performance"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    })
  })
});

export const {
  useGetLatestExamPerformanceQuery,
  useGetSchoolStudentStatsQuery,
  useGetSchoolFacultyStatsQuery,
  useGetStudentPerformanceInRangeQuery,
  useGetStudentPerformanceInRangeByBatchQuery,
  useGetStudentPerformanceInRangeByBatchForFacultyQuery
} = performanceApiSlice;
