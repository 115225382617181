import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputBase,
  Tooltip,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
  useGridApiRef
} from "@mui/x-data-grid";
import { MagnifyingGlass, Trash } from "@phosphor-icons/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeleteFromBatchMutation } from "../../../redux/api/batchSlice";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";
import { MaterialUISwitch } from "../../ui/styledComponents";

const Search = styled("div")(({ theme }) => ({
  "position": "relative",
  "borderRadius": "2rem",
  "border": "1px solid #C7D7FF",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03)
  },
  "width": "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "color": "inherit",
  "width": "100%",
  "fontSize": theme.typography.pxToRem(15),
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "width": "12ch",
      "&:focus": {
        width: "20ch"
      }
    }
  }
}));

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const IndividualBatchTable = ({ userType }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const { data: students } = useGetStudentsQuery(user.school_id);
  const { data: faculties } = useGetFacultiesQuery(user.school_id);

  const { batch: individualBatch } = useSelector(state => state.batch);
  const { batchUserDetails } = useSelector(state => state.batch);

  const [deleteFromBatch] = useDeleteFromBatchMutation();

  const [data, setData] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState("");

  const [remainingFaculties, setRemainingFaculties] = useState([]);
  const [remainingStudents, setRemainingStudents] = useState([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const handleOpenDeleteDialog = userId => {
    console.log("Deleting user with ID:", userId);
    setDeleteUserId(userId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteUserId(null);
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    console.log("Deleting user with ID:", deleteUserId);
    if (deleteUserId) {
      deleteFromBatch({ batch_id: individualBatch?._id, user_id: deleteUserId });
      handleCloseDeleteDialog();
    }
  };

  useEffect(() => {
    if (batchUserDetails) {
      console.log("batchUserDetails", batchUserDetails);
      if (batchUserDetails.length > 0) {
        let indBatchWithSrNo = batchUserDetails?.map((batch, index) => {
          return { sr_no: index + 1, name: batch.name, roll_no: batch.roll_no, email: batch.email, id: batch.id };
        });

        indBatchWithSrNo =
          userType === "students"
            ? indBatchWithSrNo.filter(batch => batch.roll_no !== "Faculty")
            : indBatchWithSrNo.filter(batch => batch.roll_no === "Faculty");

        setData(indBatchWithSrNo);
      }
    }
  }, [batchUserDetails, userType]);

  useEffect(() => {
    if (data && batchUserDetails) {
      if (data.length > 0 && batchUserDetails?.length) {
        const newFac =
          faculties &&
          faculties?.faculties?.filter(faculty => {
            if (batchUserDetails) {
              if (batchUserDetails?.some(batch => faculty._id !== batch.id)) {
                return faculty;
              }
            }
            return null; // If the batch doesn't have a matching faculty, return null or filter it out.
          });

        const newStu =
          students &&
          students?.students?.filter(student => {
            if (batchUserDetails) {
              if (batchUserDetails?.some(batch => student._id !== batch.id)) {
                return student;
              }
            }
            return null; // If the batch doesn't have a matching student, return null or filter it out.
          });
        setRemainingFaculties(newFac);
        setRemainingStudents(newStu);
      } else {
        setRemainingFaculties(faculties?.faculties);
        setRemainingStudents(students?.students);
      }
    }
  }, [data, faculties]);

  const getRowId = row => row.id;

  // Define a function to get the class for each row
  const getRowClassName = params => {
    return "row-color";
  };

  const columns = [
    { field: "id", headerName: "Id", flex: 0.3, headerClassName: "datagrid-header-color", hide: true },
    { field: "sr_no", headerName: "Sr. No.", flex: 0.3, headerClassName: "datagrid-header-color", hide: true },
    {
      field: "name",
      headerName: userType === "students" ? "Student Name" : "Faculty Name",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "roll_no",
      headerName: "Roll Number",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      headerClassName: "datagrid-header-color"
    },
    {
      field: "actions",
      headerName: "Remove",
      headerClassName: "datagrid-header-color",
      flex: 0.4,
      align: "center",
      headerAlign: "center",
      renderCell: params => (
        <Box display="flex" gap="5px">
          <Tooltip title="Delete">
            <Button
              onClick={() => handleOpenDeleteDialog(params.row.id)}
              sx={{ backgroundColor: "rgba(0,0,0,0.05)", padding: "5px", borderRadius: "5px" }}
            >
              <Trash size={22} weight="bold" color={theme.palette.alerts.error} />
            </Button>
          </Tooltip>
        </Box>
      )
    }
  ];

  const styledColumns = React.useMemo(
    () => [{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerClassName: "datagrid-header-color", flex: 0.15 }, ...columns],
    [columns]
  );

  const [checked, setChecked] = useState(false);

  const handleSearchFieldChange = event => {
    setChecked(event.target.checked);
  };

  const handleSearch = event => {
    if (batchUserDetails) {
      if (batchUserDetails.length > 0) {
        let indBatchWithSrNo = batchUserDetails?.map((batch, index) => {
          return { ...batch, sr_no: index + 1 };
        });

        indBatchWithSrNo =
          userType === "students"
            ? indBatchWithSrNo.filter(batch => batch.roll_no !== "Faculty")
            : indBatchWithSrNo.filter(batch => batch.roll_no === "Faculty");

        setData(indBatchWithSrNo);
      }
    }
    setData(prevData =>
      !checked
        ? prevData.filter(item => item.name.toLowerCase().includes(event.target.value.toLowerCase()))
        : prevData.filter(item => item.email.toLowerCase().includes(event.target.value.toLowerCase()))
    );
  };

  return (
    <>
      <Box borderRadius="1rem" bgcolor="#fff" border="1px solid #E6EDFF" width="100%">
        <Box
          width="100%"
          display="flex"
          alignItems={isNonMobile ? "center" : "start"}
          p="1rem"
          justifyContent="space-between"
          flexDirection={isNonMobile ? "row" : "column"}
          gap="15px"
        >
          <Typography variant="h4" fontWeight={400}>
            Manage {userType === "students" ? "Students" : "Faculties"}
          </Typography>
          <Box display="flex" gap="1rem" flexDirection={isNonMobile ? "row" : "column"}>
            <Box display="flex" gap="5px" alignItems="center">
              <Typography variant="h6" fontWeight={400}>
                Search with:
              </Typography>
              <Typography variant="h6">Name</Typography>
              <MaterialUISwitch checked={checked} onChange={handleSearchFieldChange} />
              <Typography variant="h6">Email</Typography>
            </Box>
            <Search>
              <SearchIconWrapper>
                <MagnifyingGlass color="#3267FF" weight="bold" size={20} />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} onChange={handleSearch} />
            </Search>
          </Box>
        </Box>
        <div className="datagrid-container">
          <Box
            sx={{
              "& .datagrid-header-color": {
                fontSize: "1rem"
              }
            }}
          >
            <DataGrid
              className="custom-datagrid"
              autoHeight
              rows={data}
              disableRowSelectionOnClick
              apiRef={apiRef}
              columns={styledColumns}
              getRowId={getRowId}
              getRowClassName={getRowClassName} // Add this line
              checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                },
                columns: {
                  columnVisibilityModel: {
                    id: false
                  }
                }
              }}
              pageSizeOptions={[10, 20, 50]}
              sx={!isNonMobile ? { width: "750px" } : {}}
            />
          </Box>
        </div>
        <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this user?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="error">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default IndividualBatchTable;
