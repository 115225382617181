import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, useMediaQuery } from "@mui/material/";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useGetStudentRanksQuery } from "../../../../redux/api/analysisSlice";

const StudentsRankPage = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const location = useLocation();
  const params = useParams();
  const { user } = useSelector(state => state.auth);

  const {
    data: studentRanks,
    isLoading,
    isError
  } = useGetStudentRanksQuery({
    school_id: user.school_id,
    exam_id: params.examId
  });

  const examAnalysis = location.state?.examAnalysis.examAnalysis;
  const examStats = [
    { label: "Exam Type", stat: examAnalysis?.exam_for },
    { label: "Exam Name", stat: examAnalysis?.exam_name },
    {
      label: "Date",
      year: examAnalysis?.exam_date?.split("-")[0],
      month: examAnalysis?.exam_date?.split("-")[1],
      day: examAnalysis?.exam_date?.split("-")[2]
    },
    { label: "Average Score", stat: examAnalysis?.average_score },
    { label: "Highest Score", stat: examAnalysis?.highest_score }
  ];

  return (
    <Box
      padding={isNonMobile ? "1rem 2rem" : "1rem"}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="36px"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" flexDirection="column" bgcolor="#fafafb">
        <Typography fontWeight="medium" fontSize="28px" textAlign="center">
          LeaderBoard
        </Typography>
        <Typography color="#4F4E69" fontSize="13px" fontWeight="medium" textAlign="center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        p="1.5rem"
        bgcolor="#ffffff"
        alignItems="center"
        width="100%"
        height="100%"
        gap="1.5rem"
      >
        <Box
          display="flex"
          flexWrap="wrap"
          gap="1rem"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          px="109px"
        >
          {examStats.map((stat, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
              p="1rem"
              textAlign="left"
              bgcolor="rgb(250,250,251,1)"
              borderRadius="8px"
              border="1px solid #e6edff"
            >
              <Box component="span" fontSize="13px" fontWeight="normal" color="#949596" textAlign="left">
                {stat.label}
              </Box>
              <Box component="span" fontSize="28px" fontWeight="medium" textAlign="left">
                {stat.stat || `${stat.day}/${stat.month}/${stat.year}`}
              </Box>
            </Box>
          ))}
        </Box>

        <Box width="100%" px="109px">
          <table className="w-full border-collapse bg-white">
            <thead>
              <tr>
                <th className="w-[10%] p-4 text-left text-sm font-normal">
                  <div className="flex items-center gap-1">
                    Rank
                    <ArrowDropDownIcon sx={{ height: "16px", width: "16px" }} />
                  </div>
                </th>
                <th className="w-[10%] p-4 text-left text-sm font-normal">
                  <div className="flex items-center gap-1">
                    Student ID
                    <ArrowDropDownIcon sx={{ height: "16px", width: "16px" }} />
                  </div>
                </th>
                <th className="w-[20%] p-4 text-left text-sm font-normal">
                  <div className="flex items-center gap-1">
                    Student Name
                    <ArrowDropDownIcon sx={{ height: "16px", width: "16px" }} />
                  </div>
                </th>
                <th className="w-[20%] p-4 text-left text-sm font-normal">
                  <div className="flex items-center gap-1">
                    Overall Score
                    <ArrowDropDownIcon sx={{ height: "16px", width: "16px" }} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {studentRanks?.studentRanks?.map((student, index) => {
                // Assign colors to top 3 ranks
                let bgColor = "transparent";
                if (index === 0) {
                  bgColor = "#F2BA16"; // 1st rank
                } else if (index === 1) {
                  bgColor = "#B1AFAA"; // 2nd rank
                } else if (index === 2) {
                  bgColor = "#C89268"; // 3rd rank
                }

                return (
                  <tr key={student._id} className={"border-gray-200"} style={{ marginBottom: "1rem" }}>
                    <td colSpan={4}>
                      <Box
                        display="flex"
                        alignItems="center"
                        bgcolor={bgColor}
                        borderRadius="0.5rem"
                        gap="2rem"
                        width="100%"
                      >
                        <Typography className="p-4 text-sm" style={{ width: "14%" }}>
                          #{index + 1}
                        </Typography>
                        <Typography className="p-4 text-sm" style={{ width: "18%" }}>
                          #{student.roll_no}
                        </Typography>
                        <Typography className="p-4 text-sm" style={{ width: "29%" }}>
                          {student.student_name}
                        </Typography>
                        <Typography className="p-4 text-sm" style={{ width: "20%" }}>
                          {student.student_score}
                        </Typography>
                      </Box>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default StudentsRankPage;
