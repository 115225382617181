import { Box, styled, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { man } from "../../../Assets";
import ContactDetails from "../../../components/Profile/Common/ContactDetails";
import Settings from "../../../components/Profile/Common/Settings";
import DocumentDetails from "../../../components/Profile/Student/DocumentDetails";
import MoreDetails from "../../../components/Profile/Student/MoreDetails";
import PersonalDetails from "../../../components/Profile/Student/PersonalDetails";
import StudentDetails from "../../../components/Profile/Student/StudentDetails";
import {
  useFetchProfileDataQuery,
  useUpdateContactDetailsMutation,
  useUpdateMoreDetailsMutation,
  useUpdatePersonalDetailsMutation,
  useUpdateStudentDetailsMutation
} from "../../../redux/api/userSlice";

const StyledTabs = styled(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "minHeight": "15px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#635ee7"
  }
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
  "textTransform": "none",
  "fontWeight": theme.typography.fontWeightRegular,
  "fontSize": theme.typography.pxToRem(15),
  "color": "rgba(0, 0, 0, 0.4)",
  "minHeight": "15px !important",
  "&.Mui-selected": {
    color: "#635ee7"
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)"
  }
}));

const scrollableTabsStyle = {
  "maxWidth": "90vw",
  "overflow": "auto",
  "& .MuiTabs-scroller": {
    overflow: "auto !important"
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "flex-start"
  }
};

const a11yProps = index => {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
};

const batchTabs = [
  {
    name: "Student Details"
  },
  {
    name: "Contact Details"
  },
  {
    name: "Personal Details"
  },
  {
    name: "More Details"
  },
  {
    name: "Document"
  },
  {
    name: "Settings"
  }
];

const Profile = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (isEditingContactDetails || isEditingStudentDetails) {
      toast.error("Please save the changes before moving to another tab");
      return;
    }
    setValue(newValue);
  };

  const { user } = useSelector(state => state.auth);
  const { data: completeData, error, loading } = useFetchProfileDataQuery(user);
  const [updateStudentDetails] = useUpdateStudentDetailsMutation();
  const [updateContactDetails] = useUpdateContactDetailsMutation();
  const [updatePersonalDetails] = useUpdatePersonalDetailsMutation();
  const [updateMoreDetails] = useUpdateMoreDetailsMutation();

  const [studentDetails, setStudentDetails] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    fatherName: "",
    motherName: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    state: "",
    gender: "",
    dateOfBirth: "",
    bloodGroup: "",
    schoolName: "",
    memberSince: { day: 0, month: 0, year: 0 }
  });
  const [isEditingStudentDetails, setIsEditingStudentDetails] = useState(false);

  const [contactDetails, setContactDetails] = useState({
    email: "",
    phone: ""
  });
  const [isEditingContactDetails, setIsEditingContactDetails] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({
    fatherNumber: "",
    motherNumber: "",
    emergencyName: "",
    emergencyNumber: "",
    emergencyAddressLine1: "",
    emergencyAddressLine2: "",
    emergencyPincode: "",
    emergencyState: ""
  });
  const [isEditingPersonalDetails, setIsEditingPersonalDetails] = useState(false);

  const [moreDetails, setMoreDetails] = useState({
    religion: "",
    category: "",
    subCaste: "",
    caste: "",
    familyIncome: "",
    casteCertificateUrl: "",
    incomeCertificateUrl: "",
    aadharNumber: "",
    aadharUrl: "",
    physicallyChallenged: ""
  });
  const [isEditingMoreDetails, setIsEditingMoreDetails] = useState(false);

  const [documentDetails, setDocumentDetails] = useState({
    tenthBoardCertUrl: "",
    tenthBoardMarks: "",
    tenthBoardName: "",
    eleventhtBoardCertUrl: "",
    eleventhtBoardMarks: "",
    eleventhtBoardName: ""
  });
  const [isEditingDocumentDetails, setIsEditingDocumentDetails] = useState(false);

  useEffect(() => {
    const user = completeData?.user || undefined;

    const date = new Date(user.created_at);

    // Get day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();
    if (user) {
      setStudentDetails({
        firstName: user?.name || "",
        middleName: user?.middle_name || "",
        lastName: user?.last_name || "",
        fatherName: user.student_details?.father_name || "",
        motherName: user.student_details?.mother_name || "",
        addressLine1: user?.address_field_line1 || "",
        addressLine2: user?.address_field_line2 || "",
        pincode: user?.pincode || "",
        state: user?.state || "",
        gender: user?.student_details?.gender || "",
        dateOfBirth: user.student_details?.date_of_birth || "",
        bloodGroup: user.student_details?.blood_group || "",
        schoolName: user?.school_name || "",
        memberSince: { day, month, year }
      });

      setContactDetails({
        email: user?.email || "",
        phone: user?.phone_no || ""
      });

      setPersonalDetails({
        fatherNumber: user.personal_details?.father_mobile_no || "",
        motherNumber: user.personal_details?.mother_mobile_no || "",
        emergencyName: user.personal_details?.emergency_contact || "",
        emergencyNumber: user.personal_details?.emergency_contact_no || "",
        emergencyAddressLine1: user.personal_details?.emergency_contact_address_line1,
        emergencyAddressLine2: user.personal_details?.emergency_contact_address_line2 || "",
        emergencyPincode: user.personal_details?.emergency_contact_pincode || "",
        emergencyState: user.personal_details?.emergency_contact_state || ""
      });

      setMoreDetails({
        religion: user.caste_details?.religion || "",
        category: user.caste_details?.category || "",
        subCaste: user.caste_details?.sub_caste || "",
        caste: user.caste_details?.caste || "",
        familyIncome: user.caste_details?.family_income || "",
        casteCertificateUrl: user.caste_details?.caste_certificate_url || "",
        incomeCertificateUrl: user.caste_details?.income_certificate_url || "",
        aadharNumber: user.caste_details?.aadhar_card_no || "",
        aadharUrl: user.caste_details?.aadhar_card_url || "",
        physicallyChallenged: user.caste_details?.physically_challenged || ""
      });

      setDocumentDetails({
        tenthBoardCertUrl: user.essential_document_details?.tenth_board_cert_url || "",
        tenthBoardMarks: user.essential_document_details?.tenth_board_perctange_cgpa || "",
        tenthBoardName: user.essential_document_details?.tenth_board_name || "",
        eleventhtBoardCertUrl: user.essential_document_details?.eleventh_board_cert_url || "",
        eleventhtBoardMarks: user.essential_document_details?.eleventh_board_perctange_cgpa || "",
        eleventhtBoardName: user.essential_document_details?.eleventh_board_name || ""
      });
    }
  }, [completeData]);

  const handleSubmitStudentDetails = () => {
    const data = {
      userId: user._id,
      studentDetails
    };

    updateStudentDetails(data);

    setIsEditingStudentDetails(false);
  };

  const handleSubmitContactDetails = () => {
    const data = {
      userId: user._id,
      contactDetails: {
        phone_no: contactDetails.phone
      }
    };

    updateContactDetails(data);

    setIsEditingContactDetails(false);
  };

  const handleSubmitPersonalDetails = () => {
    const data = {
      userId: user._id,
      personalDetails
    };

    updatePersonalDetails(data);
    setIsEditingPersonalDetails(false);
  };

  const handleSubmitMoreDetails = async () => {
    const formData = new FormData();

    formData.append("userId", user._id);

    // Loop through each field in moreDetails and append them individually
    Object.keys(moreDetails).forEach(key => {
      if (moreDetails[key]) {
        formData.append(key, moreDetails[key]);
      }
    });

    const token = localStorage.getItem("token");

    try {
      toast.loading("Updating details", { id: "updateMoreDetails" });
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/${user._id}/more-details`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        }
      });
      toast.success("Details updated successfully", { id: "updateMoreDetails" });
    } catch (error) {
      toast.error("Failed to update details", { id: "updateMoreDetails" });
      console.error("Error updating more details:", error);
    }

    setIsEditingMoreDetails(false);
  };

  const handleSubmitDocumentDetails = async () => {
    const formData = new FormData();

    formData.append("userId", user._id);

    // Loop through each field in documentDetails and append them individually
    Object.keys(documentDetails).forEach(key => {
      if (documentDetails[key]) {
        formData.append(key, documentDetails[key]);
      }
    });

    const token = localStorage.getItem("token");

    try {
      toast.loading("Updating document details", { id: "updateDocumentDetails" });
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/${user._id}/document-details`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
          }
        }
      );
      toast.success("Document details updated successfully", { id: "updateDocumentDetails" });
    } catch (error) {
      toast.error("Failed to update document details", { id: "updateDocumentDetails" });
      console.error("Error updating document details:", error);
    }

    setIsEditingDocumentDetails(false);
  };

  const getTab = value => {
    switch (value) {
      case 0:
        return (
          <StudentDetails
            user={user}
            studentDetails={studentDetails}
            setStudentDetails={setStudentDetails}
            isEdit={isEditingStudentDetails}
            setIsEdit={setIsEditingStudentDetails}
            handleSubmitStudentDetails={handleSubmitStudentDetails}
          />
        );
      case 1:
        return (
          <ContactDetails
            user={user}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            isEdit={isEditingContactDetails}
            setIsEdit={setIsEditingContactDetails}
            handleSubmitContactDetails={handleSubmitContactDetails}
          />
        );
      case 2:
        return (
          <PersonalDetails
            user={user}
            personalDetails={personalDetails}
            setPersonalDetails={setPersonalDetails}
            isEdit={isEditingPersonalDetails}
            setIsEdit={setIsEditingPersonalDetails}
            handleSubmitPersonalDetails={handleSubmitPersonalDetails}
          />
        );
      case 3:
        return (
          <MoreDetails
            user={user}
            moreDetails={moreDetails}
            setMoreDetails={setMoreDetails}
            isEdit={isEditingMoreDetails}
            setIsEdit={setIsEditingMoreDetails}
            handleSubmitMoreDetails={handleSubmitMoreDetails}
          />
        );
      case 4:
        return (
          <DocumentDetails
            user={user}
            documentDetails={documentDetails}
            setDocumentDetails={setDocumentDetails}
            isEdit={isEditingDocumentDetails}
            setIsEdit={setIsEditingDocumentDetails}
            handleSubmitDocumentDetails={handleSubmitDocumentDetails}
          />
        );
      default:
        return <Settings user={user} />;
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="1rem"
      alignItems="center"
      padding="2rem 1rem"
      bgcolor="rgb(250,250,251,1)"
    >
      <Box height="300px" display="flex" gap="10px" alignItems="center" flexDirection="column">
        <img
          src={man}
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            opacity: "0px",
            marginBottom: "0.5rem"
          }}
        />
        <Typography variant="h3" fontWeight={500}>
          {studentDetails?.firstName} {studentDetails?.middleName} {studentDetails?.lastName}
        </Typography>
        <Typography variant="h5" color="rgba(0,0,0,0.4)">
          {studentDetails?.schoolName}
        </Typography>
        <Box display="flex" gap="10px" justifyContent="center" alignItems="center">
          <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.4167 16.8466C11.0289 16.7355 8.6139 17.3072 6.50716 18.5616C4.8566 19.5444 0.528926 21.5511 3.16477 24.0624C4.45236 25.289 5.8864 26.1664 7.68935 26.1664H14"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.0833 8.08398C18.0833 10.9835 15.7328 13.334 12.8333 13.334C9.93384 13.334 7.58333 10.9835 7.58333 8.08398C7.58333 5.18449 9.93384 2.83398 12.8333 2.83398C15.7328 2.83398 18.0833 5.18449 18.0833 8.08398Z"
              stroke="#101828"
              strokeWidth="2"
            />
            <path
              d="M21 24.667V26.167M21 24.667C19.6504 24.667 18.4614 24.0041 17.764 22.9975M21 24.667C22.3496 24.667 23.5386 24.0041 24.236 22.9975M17.764 22.9975L16.3338 23.917M17.764 22.9975C17.3516 22.4022 17.1111 21.6866 17.1111 20.917C17.1111 20.1473 17.3515 19.4319 17.7639 18.8367M24.236 22.9975L25.6662 23.917M24.236 22.9975C24.6484 22.4022 24.8888 21.6866 24.8888 20.917C24.8888 20.1473 24.6485 19.4319 24.2361 18.8367M21 17.167C22.3497 17.167 23.5388 17.83 24.2361 18.8367M21 17.167C19.6503 17.167 18.4612 17.83 17.7639 18.8367M21 17.167V15.667M24.2361 18.8367L25.6667 17.917M17.7639 18.8367L16.3333 17.917"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          <Typography variant="h5" fontSize="20px" fontWeight={500}>
            Member since {studentDetails.memberSince.day}/{studentDetails.memberSince.month}/
            {studentDetails.memberSince.year}
          </Typography>
        </Box>
      </Box>
      <Box height="100%" width="100%" display="flex" flexDirection="column" gap="15px" alignItems="center">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant={!isNonMobile ? "scrollable" : "standard"}
          scrollButtons={!isNonMobile ? "auto" : false}
          sx={!isNonMobile ? scrollableTabsStyle : { borderBottom: "1px solid rgba(0,0,0,0.2)", maxWidth: "70%" }}
        >
          {batchTabs.map((batch, index) => (
            <StyledTab
              label={batch.name}
              key={index}
              {...a11yProps(index)}
              sx={!isNonMobile ? { minWidth: "auto" } : {}}
            />
          ))}
        </StyledTabs>
        <Box width="100%" height="max-content">
          {getTab(value)}
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
