import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCreateAnnouncementMutation } from "../../../redux/api/announcementSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const CreateAnnouncementByBatch = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { batchId } = useParams();
  const {
    auth: { user }
  } = useSelector(state => state);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Batches", link: "/manage-batch" },
    { name: "Create-New", link: "#" }
  ];

  const [announcementText, setAnnouncementText] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");

  const discardHandler = () => {
    setAnnouncementText("");
    setAnnouncementDescription("");
    // setCurrentSelection("");
  };

  const [createAnnouncement, { isLoading }] = useCreateAnnouncementMutation();
  const submitHandler = () => {
    if (announcementText.trim()) {
      const obj = {
        topic: announcementText.trim(),
        description: announcementDescription.trim(),
        authorId: user._id,
        author: user._id,
        batchId: [batchId],
        schoolId: user.school_id
      };
      createAnnouncement(obj);
      discardHandler();
    }
  };

  return (
    <Box minHeight={"100%"}>
      <div style={{ padding: "1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box padding={"1rem"}>
        <Typography fontWeight={600} fontSize={"1rem"}>
          New Announcement
        </Typography>
        <Typography>Make an announcement for batches</Typography>
      </Box>
      <Box padding={"2rem"}>
        <Box
          border={"0px solid lightgrey"}
          borderRadius={"0.5rem"}
          sx={{ boxShadow: 1 }}
          maxWidth={"700px"}
          marginX={"auto"}
          overflow={"hidden"}
        >
          <Grid
            container
            width={"100%"}
            sx={{
              "--Grid-borderWidth": "1px",
              "borderRadius": "0.5rem",
              "borderTop": "var(--Grid-borderWidth) solid",
              "borderLeft": "var(--Grid-borderWidth) solid",
              "borderColor": "divider",
              "display": "flex",
              "flexDirection": "column"
            }}
          >
            <Box padding={"1rem 1rem"} borderBottom={"1px solid lightgrey"}>
              <Typography fontWeight={600} fontSize={"1rem"}>
                Create an announcement
              </Typography>
            </Box>
            <Box
              padding={"1rem"}
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                borderBottom: "1px solid lightgrey"
              }}
            >
              <Box>
                <TextField
                  id="outlined-textarea"
                  label="Announcement"
                  placeholder="📢Announcement Title..."
                  multiline
                  fullWidth
                  value={announcementText}
                  onChange={e => setAnnouncementText(e.target.value)}
                />
              </Box>
              <Box>
                <TextField
                  id="outlined-textarea"
                  // label="Description"
                  placeholder="Text (optional)"
                  multiline
                  fullWidth
                  minRows={4}
                  value={announcementDescription}
                  onChange={e => setAnnouncementDescription(e.target.value)}
                />
              </Box>
            </Box>
            <Box sx={{ padding: "0.5rem 1rem", display: "flex", justifyContent: "space-between" }}>
              <Button type="button" onClick={discardHandler}>
                Discard
              </Button>
              <Button onClick={submitHandler} type="button " sx={{ backgroundColor: "rgb(0,122,255)", color: "white" }}>
                Post
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAnnouncementByBatch;
