// inbuilt modules
import React, { useState } from "react";

// external modules
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { Info, LockSimple } from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// internal modules
import toast from "react-hot-toast";
import { logo } from "../../Assets/index";
import { useSignupMutation } from "../../redux/api/authSlice";
import { toastError } from "../../utils/ToastHandler";
import CButton from "../ui/Button";

// functions and variables
const InputBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const SignupMobile = () => {
  // theme
  const theme = useTheme();

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signup, { isLoading }] = useSignupMutation();

  // useState
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    key: "",
    // role: "FACULTY",
    confirmPassword: ""
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  // functions and variables
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (formData.email.trim() === "") {
      return toastError("Please enter your email");
    }

    if (formData.key.trim() === "") {
      return toastError("Please enter your key");
    }

    if (formData.password.trim() === "") {
      return toastError("Please enter your password");
    }

    if (formData.confirmPassword.trim() !== formData.password) {
      return toastError("Passwords do not match");
    }

    const userData = {
      email: formData.email,
      password: formData.password,
      signupKey: +formData.key
      // role: formData.role
    };

    try {
      const response = await signup(userData).unwrap();
      toast.success("Signup successful");
      setFormData({
        email: "",
        password: "",
        key: "",
        // role: "FACULTY",
        confirmPassword: ""
      });
    } catch (error) {
      console.log(error);
    }
    // handleSignup(formData);
  };

  // render
  return (
    <Box
      width="100vw"
      padding="0.75rem"
      height="100dvh"
      display="flex"
      justifyContent="start"
      alignItems="start"
      flexDirection="column"
    >
      <Box width="100%" height="100%" display="flex" justifyContent="start" alignItems="start" flexDirection="column">
        <Link to={"/"}>
          <img src={logo} width="80%" />
        </Link>

        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width="90%">
            <Box display="flex" justifyContent="center" alignItems="center" gap=".5rem">
              <Link to={"/"}>
                <Box
                  color={theme.palette.primary.light}
                  sx={{ cursor: "pointer " }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ChevronLeftIcon fontSize="large" />
                </Box>
              </Link>
              <Box width="100%" display="flex" justifyContent="start" alignItems="center" gap=".5rem">
                <Typography variant="h2" fontWeight={600}>
                  Sign Up
                </Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
              gap=".5rem"
              marginTop="1rem"
            >
              <Typography variant="h6" fontWeight={500}>
                Welcome to Scaleyou’s exam analysis software, please put your login credentials below to start using the
                software
              </Typography>
            </Box>

            <FormControl fullWidth>
              <Box mt="3rem" display="flex" flexDirection="column" gap="0.75rem">
                {/* <InputBox>
                  <Select
                    id="role"
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    size="medium"
                    placeholder="Select"
                    required
                    sx={{
                      "width": "100%",
                      "& .MuiSelect-icon": {
                        color: theme.palette.secondary.main
                      },
                      "& .MuiSelect-root": {
                        color: theme.palette.secondary.main
                      }
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="SUPERADMIN">SuperAdmin</MenuItem>
                    <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
                    <MenuItem value="FACULTY">Faculty</MenuItem>
                    <MenuItem value="STUDENT">Student</MenuItem>
                  </Select>
                </InputBox> */}
                <InputBox>
                  <TextField
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    size="medium"
                    required
                    placeholder="Enter email"
                    type="email"
                    sx={{ width: "100%", color: theme.palette.secondary.main }}
                    InputProps={{ inputProps: { style: { color: theme.palette.secondary.main } } }}
                  />
                </InputBox>
                <InputBox>
                  <TextField
                    id="key"
                    name="key"
                    value={formData.key}
                    onChange={handleChange}
                    size="medium"
                    required
                    placeholder="Enter signup OTP"
                    type="text"
                    sx={{ width: "100%", color: theme.palette.secondary.main }}
                    InputProps={{
                      inputProps: { style: { color: theme.palette.secondary.main } },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title="Please check your registered email id or Contact your Institute’s administrator for OTP"
                            placement="top"
                          >
                            <Link>
                              <Info color={theme.palette.secondary.main} />
                            </Link>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </InputBox>
                <InputBox>
                  <TextField
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    size="medium"
                    required
                    placeholder="Enter password"
                    type={passwordVisible ? "text" : "password"}
                    sx={{ width: "100%", color: theme.palette.secondary.main }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockSimple color={theme.palette.secondary.main} weight="fill" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Checkbox
                            checked={passwordVisible}
                            onChange={() => setPasswordVisible(!passwordVisible)}
                            sx={{
                              "color": theme.palette.secondary.main,
                              "p": 0,
                              "&.Mui-checked": {
                                color: theme.palette.secondary.main
                              }
                            }}
                          />
                        </InputAdornment>
                      ),
                      inputProps: { style: { color: theme.palette.secondary.main } }
                    }}
                  />
                </InputBox>
                <InputBox>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    size="medium"
                    required
                    placeholder="Confirm password"
                    type={passwordVisible ? "text" : "password"}
                    sx={{ width: "100%", color: theme.palette.secondary.main }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockSimple color={theme.palette.secondary.main} weight="fill" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Checkbox
                            checked={passwordVisible}
                            onChange={() => setPasswordVisible(!passwordVisible)}
                            sx={{
                              "color": theme.palette.secondary.main,
                              "p": 0,
                              "&.Mui-checked": {
                                color: theme.palette.secondary.main
                              }
                            }}
                          />
                        </InputAdornment>
                      ),
                      inputProps: { style: { color: theme.palette.secondary.main } }
                    }}
                  />
                </InputBox>
              </Box>
              <Box display="flex" mt="1rem" justifyContent="flex-end">
                <CButton
                  buttonText="Sign Up"
                  variant="primary"
                  onClickFun={handleSubmit}
                  sx={{ width: "100%", borderRadius: "2rem" }}
                />
              </Box>
            </FormControl>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="end"
            padding="1rem"
            gap="4px"
            marginBottom=".5rem"
          >
            <Typography>Already have an account ?</Typography>
            <Link to="/login" color="#fff" variant="h5" fontWeight={500} sx={{ height: "0" }}>
              Login
            </Link>
          </Box>

          <Box display="flex" justifyContent="end" height="50%" flexDirection="column" alignItems="center">
            <Typography variant="h6" textAlign="center">
              For sales and queries visit <br />
              <Link to="/https://www.scaleyou-insights.com/" target="_blank">
                www.scaleyou-insights.com
              </Link>
            </Typography>
            <Typography variant="h6" textAlign="center">
              © 2023 Scaleyou. Powered and secured by Scaleyou
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// render
export default SignupMobile;
