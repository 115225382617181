import React from "react";
import { Box } from "@mui/material";

const SuperInsightsForOverallanalysis = ({ superInsightsData }) => {
  console.log("studentSuperInsights: ", superInsightsData);
  // Get the two most recent exams
  const recentExams = superInsightsData.slice(-2); // Get the last two elements in the array
  let message = "";

  // Edge Case 2: If no exams have been taken
  if (superInsightsData.length === 0) {
    message = "Hey! No data to analyze, come back after you've tackled an exam, and we'll have something to work with!";
  } else if (superInsightsData.length === 1) {
    // Edge Case 3: If only one exam is conducted or taken
    const studentPercentage = parseFloat(superInsightsData[0].student_percentage);
    const classAverage = parseFloat(superInsightsData[0].class_average);
    const diff = studentPercentage - classAverage;
    message = `Hey! You have ${diff > 0 ? "been doing great" : "to work more on your exams"} as your score is ${
      diff > 0 ? "more" : "less"
    } by ${Math.abs(diff.toFixed(2))}% with respect to the class.`;
  } else if (recentExams.length === 2) {
    // Edge Case 1: Compare the last two exams
    const lastExam = recentExams[1];
    const secondLastExam = recentExams[0];

    const lastStudentPercentage = parseFloat(lastExam.student_percentage);
    const lastClassAverage = parseFloat(lastExam.class_average);

    const secondLastStudentPercentage = parseFloat(secondLastExam.student_percentage);
    const secondLastClassAverage = parseFloat(secondLastExam.class_average);

    const studentDiff = lastStudentPercentage - secondLastStudentPercentage; // Change in student percentage
    const classDiff = lastClassAverage - secondLastClassAverage; // Change in class average

    message = `Hey! You have ${studentDiff > 0 ? "been doing great" : "to work more on your exams"} as your score has ${
      studentDiff > 0 ? "increased" : "decreased"
    } by ${Math.abs(studentDiff.toFixed(2))}% with respect to the class.`;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      bgcolor="#ffffff"
      border={"1px solid #E6EDFF"}
      borderRadius={"0.5rem"}
      p="0.5rem 0.5rem"
    >
      <Box color="#3267ff" display={"flex"} alignItems={"top"}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="12px">
        <h4 className="m-0 font-semibold">Super Insights</h4>
        <p className="text-[#4F4E69] m-0">{message}</p>
      </Box>
    </Box>
  );
};

export default SuperInsightsForOverallanalysis;
