import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ChapterAnalysisGraph from "./GraphsComponent/ChapterAnalysisGraph";
import { useGetEntireSyllabusQuery } from "../../../redux/api/syllabusSlice";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.2rem",
    color: "#fff"
  }
}));

const ChapterAnalysis = ({ chapterAnalysisData, sectionAnalysisData, syllabusData, subjectAlignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const [chapterNames, setChapterNames] = useState([]);
  const [chapterDetails, setChapterDetails] = useState([]);
  const [sectionNames, setSectionNames] = useState([]);
  const [sectionAlignment, setSectionAlignment] = useState();
  const [sectionsToDisplay, setSectionsToDisplay] = useState([]);
  const [visibleSections, setVisibleSections] = useState([0, 1]); // Keep track of the visible section range
  const [iscompareWithToppers, setIsCompareWithToppers] = useState(false);

  const handleScrollLeft = () => {
    if (visibleSections[0] > 0) {
      const newVisibleSections = [visibleSections[0] - 1, visibleSections[1] - 1];
      setVisibleSections(newVisibleSections);

      // Use the sectionName array to set the new subjectAlignment
      setSectionAlignment(sectionNames[newVisibleSections[0]]);
    }
  };

  const handleScrollRight = () => {
    if (visibleSections[1] < sectionNames.length - 1) {
      const newVisibleSections = [visibleSections[0] + 1, visibleSections[1] + 1];
      setVisibleSections(newVisibleSections);

      // Use the sectionName array to set the new subjectAlignment
      setSectionAlignment(sectionNames[newVisibleSections[0]]);
    }
  };

  const onComparisonChange = event => {
    setIsCompareWithToppers(event.target.checked);
  };

  useEffect(() => {
    if (chapterAnalysisData) {
      setChapterNames(Object.keys(chapterAnalysisData));
      setChapterDetails(Object.values(chapterAnalysisData));
    }
    if (sectionAnalysisData) {
      setSectionNames(Object.keys(sectionAnalysisData));
    }
  }, [chapterAnalysisData, sectionAnalysisData]);

  useEffect(() => {
    if (syllabusData && subjectAlignment) {
      const sections = Object.keys(syllabusData[subjectAlignment] || {});
      const filteredSections = sections.filter(section => section in sectionAnalysisData);
      setSectionsToDisplay(filteredSections);

      // Set initial section alignment if there are sections to display
      if (filteredSections.length > 0) {
        setSectionAlignment(filteredSections[0]);
      }
    }
  }, [syllabusData, subjectAlignment, sectionAnalysisData]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSectionAlignment(newAlignment);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        bgcolor="#FAFAFB"
        border="1px solid #E6EDFF"
        width="100%"
        height="100%"
        mt="1rem"
        p="1rem 1rem"
        borderRadius="0.5rem"
        justifyContent="center"
        alignItems="center"
      >
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            gap="0.75rem"
            width="100%"
            justifyContent="center" // Ensure the wrapper is centered
          >
            {sectionsToDisplay.length > 2 && (
              <IconButton onClick={handleScrollLeft} disabled={visibleSections[0] === 0}>
                <ArrowBackIos />
              </IconButton>
            )}
            <Box
              display="flex"
              overflow="hidden"
              sx={{
                width: isNonMobile ? "50%" : "80%",
                maxWidth: "600px",
                justifyContent: "center" // Center the ToggleButtonGroup horizontally
              }}
            >
              <ToggleButtonGroup
                sx={{
                  bgcolor: "#E9E5E5",
                  width: "100%",
                  height: "10%",
                  borderRadius: "0.5rem",
                  display: "flex",
                  justifyContent: "center" // Center the buttons within the group
                }}
                value={sectionAlignment}
                exclusive
                onChange={handleToggle}
              >
                {sectionsToDisplay.length ? (
                  sectionsToDisplay.slice(visibleSections[0], visibleSections[1] + 1).map((section, sectionIndex) => (
                    <StyledToggleButton value={section} key={sectionIndex} sx={{ width: "100%" }}>
                      <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
                        {section}
                      </Typography>
                    </StyledToggleButton>
                  ))
                ) : (
                  <Typography justifySelf="center" width="100%" bgcolor="#fff" textAlign="center">
                    No Sections To Display
                  </Typography>
                )}
              </ToggleButtonGroup>
            </Box>
            {sectionsToDisplay.length > 2 && (
              <IconButton onClick={handleScrollRight} disabled={visibleSections[1] === sectionsToDisplay.length - 1}>
                <ArrowForwardIos />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box display="flex" alignSelf="start" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="start">
            <Typography variant="h3" fontWeight="medium">
              Chapter Analysis
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  onChange={onComparisonChange}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#3267ff"
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#3267ff"
                    },
                    "& .Mui-disabled": {
                      backgroundColor: "#eaecf0"
                    }
                  }}
                />
              }
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: "semi-bold"
                }
              }}
              label="Compare With Topper"
            />
          </Box>

          <Box display="flex" alignSelf="center" justifySelf="center" width="100%">
            <ChapterAnalysisGraph
              subjectAlignment={subjectAlignment}
              sectionAlignment={sectionAlignment}
              syllabusData={syllabusData}
              chapterAnalysisData={chapterAnalysisData}
              setSectionAlignment={setSectionAlignment}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" p="1rem" borderRadius="0.5rem" gap={1} bgcolor="#FAFAFB" border="1px solid #E6EDFF">
        <Box>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
              stroke="#3267FF"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Box display="flex" flexDirection="column">
          <h4 className="m-0 font-semibold">Super Insights</h4>
          <p className="text-[#4F4E69] m-0">
            Want to improve your strengths? Ask{" "}
            <span color="#4F4E69" style={{ fontWeight: "bold" }}>
              ScaleYou AI
            </span>{" "}
            for tips, tricks, or a personalized timetable to ace in each subject.
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default ChapterAnalysis;
